import { DirectiveBinding, h, render, VNode, watch, WatchHandle } from 'vue';
import HelpElement from '@/components/Help/HelpElement.vue';
import { HelpPointKey } from '@/shared/composables/useHelp';
import { helpModeEnabled } from '@/shared/composables/useHelp';
import router from '@/router/router';

type HelpTargetElement = HTMLElement & {__helpContainer__: HTMLDivElement, __unwatch__: WatchHandle};

export const helpElDirective = {
  mounted(el: HelpTargetElement, binding: DirectiveBinding<HelpPointKey | ''>, vnode: VNode) {
    const unwatch = watch(
      helpModeEnabled,
      () => {
        if (!helpModeEnabled.value && el.classList.contains('sc-help')) {
          el.classList.remove('sc-help');
          el.__helpContainer__.remove();
        }

        if (helpModeEnabled.value && !el.classList.contains('sc-help')) {
          if (!binding.value) {
            return;
          }
          el.classList.add('sc-help');
          const container = document.createElement('div');
          el.__helpContainer__ = container;
          container.classList.add('help-wrapper');
          document.body.appendChild(container);
          const { x, y, height, width } = el.getBoundingClientRect();
          const elementProps = {
            id: binding.value,
            width,
            height,
            top: y,
            left: x,
          };
          const node = h(HelpElement, elementProps);

          render(node, container);
        }
      }
    );
    el.__unwatch__ = unwatch;
    router.beforeEach(() => {
      if (el.classList.contains('sc-help')) {
        el.classList.remove('sc-help');
        el.__helpContainer__.remove();
      }
    });
  },
  beforeUnmount(el: HelpTargetElement) {
    if (el.classList.contains('sc-help')) {
      el.classList.remove('sc-help');
      el.__helpContainer__.remove();
    }
    el.__unwatch__?.();
  },
};