import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { computed, ref, watch } from 'vue';
import { COLORS } from '@/shared/constants';
import { useUIStore } from '@/shared/composables/useUIStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScIcon',
  props: {
    id: {},
    width: { default: 24 },
    height: { default: 24 },
    color: { default: COLORS.GREY }
  },
  setup(__props: any) {

const props = __props;

const { uiState } = useUIStore();

const svgContent = ref<string>('');

const variableStyle = computed<string>(() => {
  const height = props.height === 'auto' ? 'auto' : `${props.height}px`;
  const width =  props.width === 'auto' ? 'auto' : `${props.width}px`;
  const color = props.color;

  return `--height: ${height}; --width: ${width}; --color: ${color};`;
});

const loadSVG = async () => {
  if (!props.id) {
    return;
  }
  const iconId = props.id;
  const svgCache = uiState.value.svgCache;
  const pendingRequests = uiState.value.pendingRequests;

  if (svgCache.has(iconId)) {
    svgContent.value = svgCache.get(iconId);
    return;
  }

  if (pendingRequests.has(iconId)) {
    svgContent.value = await pendingRequests.get(iconId);
    return;
  }

  try {
    const fetchPromise = fetch(`/icons/${props.id}.svg`)
      .then(response => response.text())
      .then(svg => {
        svgCache.set(iconId, svg);
        pendingRequests.delete(iconId);
        return svg;
      });

    pendingRequests.set(iconId, fetchPromise);
    svgContent.value = await fetchPromise;
  } catch (error) {
    console.error(`Failed to load ${iconId}.svg`, error);
    pendingRequests.delete(iconId);
  }
};

watch(() => props.id, loadSVG, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    innerHTML: svgContent.value,
    style: _normalizeStyle(variableStyle.value),
    class: "icon"
  }, null, 12, _hoisted_1))
}
}

})