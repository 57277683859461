import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sc-range-block d-flex align-center justify-space-between" }
const _hoisted_2 = {
  key: 0,
  class: "regular-14 position-static d-flex align-center justify-start flex-row slider-label"
}
const _hoisted_3 = { class: "sc-range-item" }
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]

import { computed, onMounted } from 'vue';

interface Props {
  steps?: number[];
  min?: number;
  max?: number;
  label?: string;
  sliderInputOnly?: boolean;
  infinityAt?: string | number;
  noOverlap?: boolean;
  disabled?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ScRange',
  props: /*@__PURE__*/_mergeModels({
    steps: {},
    min: {},
    max: {},
    label: {},
    sliderInputOnly: { type: Boolean, default: false },
    infinityAt: {},
    noOverlap: { type: Boolean, default: false },
    disabled: { type: Boolean }
  }, {
    "modelValue": {
  required: true,
  default: [],
},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const model = _useModel<number[]>(__props, 'modelValue');

const steps = computed(() => {
  if (props.steps) {
    return props.steps;
  }

  const min = props.min as number;
  const max = props.max as number;
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
});

const ticks = computed(() => {
  return steps.value.reduce<Record<number, string>>((acc, item, index) => {
    acc[index] = item.toString();
    return acc;
  }, {});
});

const sliderModel = computed({
  get: () => [
    steps.value.indexOf(model.value[0]),
    steps.value.indexOf(model.value[1]),
  ],
  set: (value: number[]) => {
    if(props.noOverlap){
      let [left, right] = value;

      if (left >= right) {
        left = steps.value.indexOf(model.value[0]);
      }
      if (right <= left) {
        right = steps.value.indexOf(model.value[1]);
      }
      model.value = [steps.value[left], steps.value[right]];
    } else {
      model.value = [steps.value[value[0]], steps.value[value[1]]];
    }
  },
});

const displayValRight = computed<number | string>(() => {
  const valRightSlide = ticks.value[sliderModel.value[1]];
  return String(props.infinityAt) === valRightSlide || valRightSlide === 'Infinity' ? '∞' : steps.value[sliderModel.value[1]];
});

const displayValueLeft = computed<number | string>(() => {
  const valLeftSlide = ticks.value[sliderModel.value[0]];
  return String(props.infinityAt) === valLeftSlide || valLeftSlide === 'Infinity' ? '∞' : steps.value[sliderModel.value[0]];
});

onMounted(() => {
  if (!model.value || model.value.length === 0) {
    model.value = [steps.value[0], steps.value[steps.value.length - 1]];
  }
});

return (_ctx: any,_cache: any) => {
  const _component_v_range_slider = _resolveComponent("v-range-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_range_slider, {
        modelValue: sliderModel.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((sliderModel).value = $event)),
        max: steps.value.length - 1,
        min: 0,
        step: 1,
        ticks: ticks.value,
        class: "sc-range",
        "hide-details": "",
        strict: "",
        disabled: _ctx.disabled
      }, _createSlots({ _: 2 }, [
        (!_ctx.sliderInputOnly)
          ? {
              name: "prepend",
              fn: _withCtx(() => [
                _createElementVNode("input", {
                  value: displayValueLeft.value,
                  disabled: "",
                  class: "slider-input regular-14 d-flex align-center justify-center flex-row text-center rounded-lg"
                }, null, 8, _hoisted_4)
              ]),
              key: "0"
            }
          : undefined,
        (!_ctx.sliderInputOnly)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _createElementVNode("input", {
                  value: displayValRight.value,
                  disabled: "",
                  class: "slider-input regular-14 d-flex align-center justify-center flex-row text-center rounded-lg"
                }, null, 8, _hoisted_5)
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["modelValue", "max", "ticks", "disabled"])
    ])
  ]))
}
}

})