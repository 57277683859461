<template>
  <div>
    <label class="sc-textarea__label regular-18">{{ label }}</label>
    <div class="sc-textarea" :class="[{ 'active-border': isActive }]">
      <textarea
        v-model="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        class="text-textarea"
        :class="[
          size === 'small'
            ? 'regular-14 sc-textarea__small'
            : 'regular-16 sc-textarea_medium',
            {'opacity-70 cursor-default': disabled}
        ]"
        @blur="onBlur"
        @focus="onFocus"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ScInputProps } from './ScTextarea.types';
import { ref } from 'vue';

const props = withDefaults(defineProps<ScInputProps>(), {
  placeholder: '',
  size: 'medium',
});

const modelValue = defineModel('modelValue', { required: true });

const isActive = ref(false);

const onFocus = (): void => {
  isActive.value = true;
};

const onBlur = (): void => {
  isActive.value = false;
};
</script>

<style scoped>
.sc-textarea {
  width: 330px;
  height: 96px;
  transition: 0.2s ease-in;
  border-radius: 8px;
  border: 1px solid var(--form-stroke);
  background: var(--input-field);
}

.sc-textarea__label {
  margin-bottom: 8px;
  color: var(--text-primary);
  display: block;
}

.sc-textarea_medium {
  padding: 11px 16px;
}

.sc-textarea__small {
  padding: 5px 12px;
}

.sc-textarea:hover {
  background: var(--tree-field);
  border-color: var(--form-stroke);
  color: var(--button-loading);
}

.active-border {
  border-color: var(--primary);
}

.text-textarea {
  width: 100%;
  height: 100%;
  outline: none;
  resize: none;
  color: var(--color-white);
}

.text-textarea::placeholder {
  color: var(--button-loading);
}
</style>
