export default {
  selectedOpening: null,
  selectedMistake: null,
  openingsTree: null,
  parsedFen: null,
  mistakesOpenings: [],
  //фильтры
  sideOptionsFilter: 2,
  studiedOptionsFilter: 1,
  watchListOptionsFilter: 1,
  openingOptionsFilter: 1,
  sortByOptionsFilter: 1,
  favOptionsFilter: 1,
  positionStatusFilter:1,
  listedFilter: 1,
  openSequense: 3,
  compareFilters: {},
  deviation: 40,
  hmRange: [],
  deltaRange: [0.3, Infinity],
  unfinishedExercises: [],
  childrenLoadingProgress: {},
  currentSide: 'white',
  exercisesList: [],
  mistakes: null,
  heroChainParams: null,
  exerciseTree: null,
  autoLoading: false,
  autoLoadingProgress: null,
  appToast: null
};
