import axios from '@/plugins/NewAxiosWrapper';
import { Methods } from '@/store/enums';
import {
  ActivatePromocodeRequest,
  RegisterUserRequest,
  ResendConfirmationRequest,
  SuccessStatus,
} from '@/store/auth/types';

export default {
  async registerUser({}, params: RegisterUserRequest) {
    const data = {
      mtd: Methods.USER_REGISTRATION,
      ...params,
    };

    return await axios.request<RegisterUserRequest, SuccessStatus>(data);
  },

  async resendConfirmation({}, params: ResendConfirmationRequest) {
    const data = {
      mtd: Methods.USER_SEND_MAIL_AGAIN,
      ...params,
    };

    return await axios.request<ResendConfirmationRequest, SuccessStatus>(data);
  },

  async activatePromocode({}, params: ActivatePromocodeRequest) {
    const data = {
      mtd: Methods.USER_PROMO_ACTIVATE,
      ...params,
    };

    return await axios.request<ActivatePromocodeRequest, SuccessStatus>(data);
  },
};
