import AxiosWrapper from '@/plugins/NewAxiosWrapper';
import type { GetUserPreferencesResponce } from '@/store/ui/types';
import type { SuccessMessage, UsersSetPreferencesRequest } from '@/store/types';
import type { Commit, Dispatch } from 'vuex';
import { Methods } from '../enums';

export default {
  async getSettingsAndPreferences({ commit }: { commit: Commit}) {

    const resp = await AxiosWrapper.request<{}, GetUserPreferencesResponce>({ mtd: Methods.USERS_GET_INFO});

      if (resp) {
        const { ...prefs } = resp;

        commit('SET_DATA_BY_KEY', {
            key: 'userSettings',
            value: resp,
          });

          commit('SET_DATA_BY_KEY', {
            key: 'userPreferences',
            value: prefs,
          });
      };
  },
  async setUserPreferences({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, params: any) {

    delete params.user_id
    delete params.displayed_name
    delete params.use_short_notation

    const data = {
      ...params,
      mtd: Methods.USERS_SET_PREFERENCES,
    };

    const resp = await AxiosWrapper.request<UsersSetPreferencesRequest, SuccessMessage>(data);

      if (resp?.message === 'OK') {
        dispatch('getSettingsAndPreferences')
      };
  },
  // TODO: temporary method, delete after store is refactored
  async getHelp() {
    await AxiosWrapper.request({ mtd: Methods.HELP });
  },
};
