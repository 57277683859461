<template>
  <div
    :class="variantClass"
    class="sc-card v-theme--chess"
    :style="elevationStyle"
  >
    <div
      class="sc-card__title semibold-16 pb-3"
      v-if="title"
    >
      {{ title }}
    </div>
    <span v-if="separator" class="sc-card--separator" />

    <template v-if="slot.title">
        <slot name="title" />
    </template>

    <template v-if="slot.default">
        <slot></slot>
    </template>

    <template v-if="slot.actions">
      <span v-if="separator" class="sc-card--separator" />
      <div class="sc-card__actions">
        <slot name="actions"></slot>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { ScCardProps } from './ScCard.types';

const props = withDefaults(defineProps<ScCardProps>(), {
  elevation: 0,
  rounded: 'lg',
  border: false,
  separator: false,
  backgroundColor: 'bg-light'
});

const slot = useSlots();

const variantClass = computed<string>(() => {
  const variant =  props.variant ? `sc-card--${props.variant}` : '';
  const rounded =  props.rounded ? `sc-card--rounded-${props.rounded}` : '';
  const border = props.border ? 'sc-card--border' : ''
  return `${variant} ${rounded} ${border}`.trim()
});

const elevationStyle = computed<{ boxShadow?: string; background?: string }>(() => {
  const style: { boxShadow?: string; background?: string } = {};

  if (props.elevation !== undefined) {
    style.boxShadow = getBoxShadow(props.elevation);
  }

  if (props.backgroundColor) {
    style.background = `var(--${props.backgroundColor})`;
  }

  return style;
});

const getBoxShadow = (elevation: number): string => {
  const shadows = [
    'none', // elevation 0
    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)', // elevation 1
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)', // elevation 2
    '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)', // elevation 3
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)', // elevation 4
  ];

  return shadows[elevation] || shadows[0];
};
</script>

<style scoped>
.sc-card {
  padding: 16px 12px;
  min-height: 48px;
  display: block;
  position: relative;
  padding: 16px 12px;
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}

.sc-card__title {
  display: block;
  flex: none;
  min-width: 0;
  overflow-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;
  word-wrap: break-word;
}

.sc-card__actions {
  align-items: center;
  display: flex;
  flex: none;
  gap: 8px;
}

.sc-card--flat {
  box-shadow: none;
}

.sc-card--outlined {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  box-shadow: none;
}

.sc-card--tonal {
  background: rgba(var(--v-theme-primary), 0.08);
}

.sc-card--text {
  background: transparent;
  box-shadow: none;
}

.sc-card--border{
  border: 1px solid var(--form-stroke);
}

.sc-card--rounded-lg{
  border-radius: 16px;
}

.sc-card--rounded-md{
  border-radius: 8px;
}

.sc-card--separator{
  display: block;
  height: 1px;
  width: 100%;
  background: var(--form-stroke);
}
</style>
