import mistakeSorter from '@/helpers/mistakeSortHelper';

const sortFn = (key, asc) => (a, b) => {
  const aVal = a?.[key];
  const bVal = b?.[key];
  if (asc) {
    return aVal > bVal ? 1 : -1;
  }
  return aVal > bVal ? -1 : 1;
};

export default {
  getOpeningsVariants(state, getters, rootState) {
    return (t) => {
      const base = t
        ? { id: 1, text: t('common.all') }
        : { id: 1, text: 'all' };
      if (!state.mistakesOpenings) return [base];
      return [
        base,
        ...state.mistakesOpenings
          .reduce((acc, { eco_opening }) => {
            if (acc.findIndex((item) => item.text === eco_opening) > -1)
              return acc;
            acc.push({ id: acc.length + 2, text: eco_opening });
            return acc;
          }, [])
          .sort(sortFn('text', true)),
      ];
    };
  },
  getMistakesNotLoaded(state) {
    const mistakes = state.mistakes
    return mistakes === null
  },
  getMistakesOpenings(state, getters) {
    const {
      sideOptionsFilter,
      studiedOptionsFilter,
      watchListOptionsFilter,
      openingOptionsFilter,
      sortByOptionsFilter,
      mistakes,
      hmRange,
      deltaRange,
      positionStatusFilter,
      listedFilter,
    } = state;

    const { getOpeningsVariants } = getters;

    if (!mistakes) return [];
    const currentVariant = getOpeningsVariants().find(
      ({ id }) => id === openingOptionsFilter,
    );

    return mistakeSorter({
      array: mistakes.list,
      currentVariant,
      sideOptionsFilter,
      studiedOptionsFilter,
      watchListOptionsFilter,
      sortByOptionsFilter,
      hmRange,
      deltaRange,
      positionStatusFilter,
      listedFilter,
    });
  },
  getMistakesMinMax(state) {
    const mistakesOpenings = state.mistakesOpenings;

    if (!mistakesOpenings || mistakesOpenings.length === 0) return [];

    const reduced = mistakesOpenings.reduce(
      (acc, item) => {
        const hm = item.hm % 2 === 0 ? item.hm - 1 : item.hm;

        if (hm < acc[0]) {
          acc[0] = hm;
        }

        if (hm > acc[1]) {
          acc[1] = hm;
        }

        return acc;
      },
      [Infinity, -Infinity],
    );

    reduced[0] = (reduced[0] + 1) / 2;
    reduced[1] = (reduced[1] + 1) / 2;

    return reduced;
  },
};
