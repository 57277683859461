<template>
  <div class="wrapper">
    <div class="label regular-14" v-if="label">{{ label }}</div>
    <div class="d-flex ga-1"
    >
      <ScButton
        v-for="option in options"
        :key="'so' + option.id"
        class="button"
        :class="{'button-inactive' :modelValue !== option.id}"
        :label="$t(option.text)"
        :color="color"
        :disabled="isSubmitButtonDisabled"
        size="small"
        noHover
        @click="handleClick(option.id)"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ButtonSelector',
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    isSubmitButtonDisabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "button-secondary"
    },
  },
  emits: ['update:modelValue', 'update:filtersChanged'],
  methods: {
    handleClick(value) {
      this.updateModel(value);
      this.$emit('update:filtersChanged'); // Emit the change event here
    },
    updateModel(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: static;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}

.label {
  position: static;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--text-secondary);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
}

.button-inactive{
  background-color: var(--input-field) !important;
}
</style>
