<template>
  <div class="radio-wrapper">
    <input
      type="radio"
      :id="radioId"
      :value="value"
      v-model="modelValue"
      :disabled="disabled"
      class="custom-radio"
      :class="{'opacity-70 cursor-default':disabled}"
    />
    <label v-if="label" :for="radioId" class="custom-radio-label">
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { ScRadioProps } from '@/components/Common/ScRadio/ScRadio.types';
import { computed } from 'vue';

const props = withDefaults(defineProps<ScRadioProps>(), {
  value: 'default',
  color: 'primary'
});

const modelValue = defineModel('modelValue', { required: true });

const radioId = computed<string>(() => `radio-${props.value}`);

const checkboxColor = computed(()=>{
  return `var(--text-${props.color})`
})
</script>

<style scoped>
.radio-wrapper {
  display: flex;
  align-items: center;
  width: max-content;
}

.custom-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid var(--form-stroke);
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.custom-radio:checked {
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-radio:checked::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-radio-label {
  font-family: 'Source Sans 3';
  color: v-bind(checkboxColor);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.disabled{
  opacity: 0.7;
  cursor: default;
}
</style>
