// @ts-nocheck

import { createApp } from 'vue/dist/vue.esm-bundler.js';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from '@/router/router';
import store from '@/store';
import './styles/variables.css';
import './styles/common.css';

// import messages from "@/i18n/index.js";
import { i18n } from '@/i18n/index.js';

import components from '@/components/Common/components.ts';

import PortalVue from 'portal-vue';

import VueYandexMetrika from 'vue3-yandex-metrika';
import VueGtag from 'vue-gtag';

import { helpElDirective } from './directives/helpDirective';
import VClickOutside from '@/directives/VClickOutside';
import 'vue-chessground/chessboard.css';

let worker;

try {
  worker = new Worker('stockfish/stockfish.js');
  worker?.postMessage('uci').catch((e) => {});
  worker?.postMessage('ucinewgame').catch((e) => {});
  worker
    ?.postMessage('setoption name Use NNUE value true')
    .catch((e) => console.log(e));
  worker
    ?.postMessage('setoption name Hash value 64')
    .catch((e) => console.log(e));
  worker
    ?.postMessage('setoption name MultiPV value 1')
    .catch((e) => console.log(e));
  worker
    ?.postMessage('setoption name Threads value 2')
    .catch((e) => console.log(e));
  worker
    ?.postMessage('setoption name UCI_AnalyseMode value true')
    .catch((e) => console.log(e));
} catch {
  console.log('no worker allowed');
}

// worker.onmessage = function(event) {
//   console.log(event.data)
// }

// options = new Map([["Threads", "1"], ["Hash", "16"], ["MultiPV", "1"], ["UCI_Variant", "chess"]]),
// this.setOption("UCI_AnalyseMode", "true"),
// this.setOption("Analysis Contempt", "Off"),
// this.setOption("UCI_Chess960", "true"),

// t.emit({
//   fen: t.currentFen,
//   maxDepth: r ? 99 : this.opts.defaultDepth,
//   depth: ((l = s.pvs[0]) === null || l === void 0 ? void 0 : l.depth) || 0,
//   knps: s.nodes / Math.max(s.time, 1),
//   nodes: s.nodes,
//   cp: (c = s.pvs[0]) === null || c === void 0 ? void 0 : c.cp,
//   mate: (d = s.pvs[0]) === null || d === void 0 ? void 0 : d.mate,
//   millis: s.time,
//   pvs: s.pvs
// })

loadFonts();

const deepFind = ({ array, value, key = 'id' }) => {
  if (!array) return null;
  const fields = Object.keys(array);
  if (fields.includes(key) && array[key] === value) {
    return array;
  } else {
    let result;
    for (const field of fields) {
      if (typeof array[field] === 'object' && field !== 'parentObject') {
        if (Array.isArray(array[field])) {
          for (const item of array[field]) {
            if (typeof item === 'object') {
              if (
                (result = deepFind({
                  array: item,
                  value,
                  key,
                })) !== null
              )
                return result;
            }
          }
        } else {
          if (
            (result = deepFind({
              array: array[field],
              value,
              key,
            })) !== null
          )
            return result;
        }
      }
    }
  }
  return null;
};

const vueApp = createApp(App);
vueApp.use(PortalVue);

components.forEach((component) =>
  vueApp.component(component.name, component.component),
);

vueApp.mixin({
  methods: {
    $cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    $parseArray(str) {
      if (!str) return [];
      if (Array.isArray(str)) return str;
      const replaced = str.replace('{', '[').replace('}', ']');

      return JSON.parse(replaced);
    },
    $getMoveQualityFill(moveCount, deviation = 30, waste) {
      if (!moveCount) return;
      const redPercent = waste > deviation ? moveCount : 0;

      return 100 * (redPercent / moveCount);
    },
  },
  computed: {
    $stockfish() {
      return worker;
    },
    $deepFind() {
      return deepFind;
    },
    $getMoveText() {
      const { lang_pieces } = this.$store?.state?.ui?.userPreferences;

      return (item) => {
        if (lang_pieces === undefined) {
          return '';
        }
        const move_lang = item?.move_lang || '';
        if (!move_lang) {
          return 'Root';
        }
        if (move_lang && move_lang[lang_pieces]) {
          return move_lang[lang_pieces];
        }
        return move_lang[0] || '';
      };
    },
  },
});

vueApp.directive('sc-help', helpElDirective);

const siteUrl = document.location.origin;

vueApp.directive('VClickOutside', VClickOutside);

vueApp
  .use(vuetify)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueYandexMetrika, {
    id: 98821652,
    scriptSrc: `${siteUrl}/ymetrika.js`,
    router: router,
    debug: true,
    env: process.env.NODE_ENV,
    options: {
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  })
  .use(VueGtag, {
    config: { id: 'G-6ZPVG2P8EL' },
  })
  .mount('#app');
