import { useModel as _useModel, useCssVars as _useCssVars, unref as _unref, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelRadio as _vModelRadio, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "radio-wrapper" }
const _hoisted_2 = ["id", "value", "disabled"]
const _hoisted_3 = ["for"]

import { ScRadioProps } from '@/components/Common/ScRadio/ScRadio.types';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScRadio',
  props: /*@__PURE__*/_mergeModels({
    label: {},
    value: { default: 'default' },
    disabled: { type: Boolean },
    color: { default: 'primary' }
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

_useCssVars(_ctx => ({
  "71cc0b00": (checkboxColor.value)
}))

const props = __props;

const modelValue = _useModel(__props, 'modelValue');

const radioId = computed<string>(() => `radio-${props.value}`);

const checkboxColor = computed(()=>{
  return `var(--text-${props.color})`
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "radio",
      id: radioId.value,
      value: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modelValue).value = $event)),
      disabled: _ctx.disabled,
      class: _normalizeClass(["custom-radio", {'opacity-70 cursor-default':_ctx.disabled}])
    }, null, 10, _hoisted_2), [
      [_vModelRadio, modelValue.value]
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: radioId.value,
          class: "custom-radio-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}
}

})