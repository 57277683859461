<template>
  <div
    class="help-element"
    :style="elementStyle"
    @mouseenter="toggleTooltip"
    @mouseleave="toggleTooltip"
    @click="toggleHelp"
  >
    <HelpCard v-bind="params" v-if="isTooltipShown"/>
  </div>
</template>

<script setup lang="ts">
import { type HelpPointKey, HelpPointParams, useHelp } from '@/shared/composables/useHelp';
import { computed, CSSProperties, ref } from 'vue';
import HelpCard from './HelpCard.vue';
import { HELP_COLOR } from '@/shared/constants';

const props = withDefaults(defineProps<{
  id: HelpPointKey;
  width?: number | string;
  height?: number | string;
  top: number;
  left: number;
  handleClick?: () => void;
}>(),
{
  width: '100%',
  height: 'auto',
});

const isTooltipShown = ref<boolean>(false);

const { helpPointsMap, toggleHelp } = useHelp();

const params = computed<HelpPointParams>(() => helpPointsMap.value[props.id]);

const elementStyle = computed<CSSProperties>(() => ({
  width: params.value?.customWidth ? params.value?.customWidth + 'px' : props.width + 'px',
  height: params.value?.customHeight? params.value?.customHeight + 'px' : props.height + 'px',
  top: props.top + 'px',
  left: props.left + 'px',
  zIndex: isTooltipShown.value ? 10000 : 9000,
}));

const toggleTooltip = (): void => {
  isTooltipShown.value =!isTooltipShown.value;
};
</script>

<style scoped>
.help-element {
  content: '';
  position: absolute;
  top: 4px;
  right: 4px;
  left: 4px;
  bottom: 4px;
  border: 1px solid yellow;
  border-color: v-bind(HELP_COLOR);
  border-radius: 8px;
}
</style>