<template>
  <v-btn
    class="sc-button text-none"
    :class="[
      { 'custom-disabled': disabled && !textOnly },
      { 'custom-disabled-text': disabled && textOnly },
      { 'no-hover': noHover },
    ]"
    :style="styleWidthPadding"
    :disabled="disabled"
    :variant="buttonVariant"
    :color="color"
  >
    <div
      v-if="!iconOnly && prependIcon && !loading"
      class="sc-button__prepend"
      :class="[
        { 'mr-2': !iconOnly && size !== 'small' },
        { 'mr-icon-small': !iconOnly && size !== 'medium' },
      ]"
    >
      <ScIcon
        v-if="prependIcon && !iconOnly"
        :id="prependIcon"
        color="currentColor"
        :width="iconSize"
        :height="iconSize"
      />
    </div>

    <span
      v-if="label && !iconOnly"
      class="sc-button__text"
      :class="size === 'small' ? 'semibold-14' : 'semibold-16'"
    >
      {{ label }}
    </span>

    <slot v-if="!label" />

    <div
      v-if="loading || appendIcon"
      class="sc-button__append"
      :class="[
        { 'ml-2': !iconOnly && size !== 'small' },
        { 'ml-icon-small': !iconOnly && size !== 'medium' },
      ]"
    >
      <!-- <ScLoader v-if="loading" /> -->

      <ScIcon
        v-if="appendIcon || icon"
        :id="icon ? icon : appendIcon"
        color="currentColor"
        :width="iconSize"
        :height="iconSize"
      />
    </div>

    <v-tooltip v-if="tooltip" activator="parent" location="bottom">{{
      tooltip
    }}</v-tooltip>
  </v-btn>
</template>

<script setup lang="ts">
import { computed, CSSProperties } from 'vue';
import { ScButtonProps } from './ScButton.types';
// import ScLoader from '@/components/Common/ScLoader';
import ScIcon from '@/components/Common/ScIcon.vue';

const props = withDefaults(defineProps<ScButtonProps>(), {
  color: 'primary',
  size: 'medium',
});

const buttonVariant = computed<string>(() => {
  return props.iconOnly
    ? props.iconOnlyTertiary
      ? 'text'
      : 'flat'
    : props.textOnly
    ? 'plain'
    : 'flat';
});

const buttonMinWidth = computed<string>(() => {
  if (props.iconOnly) {
    if (props.size && props.size === 'small') {
      return '36px';
    }
    return '48px';
  }
  return 'auto';
});

const iconSize = computed(() => {
  if (props.iconOnly && props.size === 'medium') {
    return 24;
  }
  if (props.textOnly && props.size === 'small') {
    return 16;
  }
  return 20;
});

const styleWidthPadding = computed<CSSProperties>(() => {
  if (props.textOnly) {
    return {
      width: props.fullWidth ? '100%' : props.width ? props.width : '',
    };
  }

  const basePadding = props.size === 'small' ? '6px' : '12px';
  const horizontalPadding = props.size === 'small' ? '12px' : '24px';

  if (props.rounded) {
    return {
      width: '32px',
      height: '32px',
      padding: basePadding,
      borderRadius: '50%',
    };
  }

  return {
    width: props.fullWidth ? '100%' : props.width ? props.width : '',
    padding: props.iconOnly
      ? basePadding
      : `${basePadding} ${horizontalPadding}`,
  };
});

const buttonStyles = computed(() => {
  let color = '';
  let hoverBg = '';
  let hoverText = '';

  if (props.textOnly) {
    hoverText = `rgb(var(--v-theme-primary-hover))`;

    switch (props.color) {
      case 'button-secondary':
        color = `rgb(var(--v-theme-text-primary))`;
        break;
      case 'primary':
        color = `rgb(var(--v-theme-primary))`;
        break;
      case 'white':
        color = `rgb(var(--v-theme-white))`;
        break;
      case 'secondary':
        color = `rgb(var(--v-theme-secondary))`;
        break;
    }
  } else {
    switch (props.color) {
      case 'primary':
        hoverBg = props.iconOnlyTertiary
          ? `rgb(var(--v-theme-button-hover))`
          : `rgb(var(--v-theme-primary-hover))`;
        break;
      case 'button-secondary':
        hoverBg = `rgb(var(--v-theme-button-hover))`;
        color = `rgb(var(--v-theme-on-button-secondary))`;
        break;
      case 'input-field':
        hoverBg = `rgb(var(--v-theme-tree-field))`;
        color = `rgb(var(--v-theme-text-primary))`;
        break;
      case 'violet':
        hoverBg = `rgb(var(--v-theme-violet-hover))`;
        color = `rgb(var(--v-theme-white))`;
        break;
    }
  }

  return { color, hoverBg, hoverText };
});
</script>

<style scoped>
.sc-button {
  font-family: 'Source Sans 3';
  height: auto;
  width: fit-content;
  transition: 0.2s ease-in;
  color: v-bind(buttonStyles.color) !important;
  border-radius: 8px;
  flex-shrink: 1 !important;
}

.sc-button:deep(.v-btn__overlay) {
  background: transparent !important;
}

.v-btn--variant-plain {
  opacity: 1 !important;
}
.sc-button__text {
  letter-spacing: 0;
}

.sc-button:not(.no-hover):hover {
  background-color: v-bind(buttonStyles.hoverBg) !important;
  color: v-bind(buttonStyles.hoverText) !important;
  transition: 0.2s ease-in;
}

.v-btn--size-default {
  padding: 0;
  min-width: v-bind(buttonMinWidth);
}

.ml-icon-small {
  margin-left: 6px;
}

.mr-icon-small {
  margin-right: 6px;
}

.custom-disabled {
  background-color: var(--primary-disabled) !important;
  color: var(--text-secondary) !important;
  cursor: default;
  opacity: 0.4;
}

.custom-disabled-text {
  color: var(--text-secondary) !important;
  cursor: default;
  opacity: 0.4;
}
</style>
