import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sc-card__title semibold-16 pb-3"
}
const _hoisted_2 = {
  key: 1,
  class: "sc-card--separator"
}
const _hoisted_3 = {
  key: 0,
  class: "sc-card--separator"
}
const _hoisted_4 = { class: "sc-card__actions" }

import { computed, useSlots } from 'vue';
import { ScCardProps } from './ScCard.types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScCard',
  props: {
    title: {},
    variant: {},
    elevation: { default: 0 },
    rounded: { default: 'lg' },
    border: { type: Boolean, default: false },
    backgroundColor: { default: 'bg-light' },
    separator: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const slot = useSlots();

const variantClass = computed<string>(() => {
  const variant =  props.variant ? `sc-card--${props.variant}` : '';
  const rounded =  props.rounded ? `sc-card--rounded-${props.rounded}` : '';
  const border = props.border ? 'sc-card--border' : ''
  return `${variant} ${rounded} ${border}`.trim()
});

const elevationStyle = computed<{ boxShadow?: string; background?: string }>(() => {
  const style: { boxShadow?: string; background?: string } = {};

  if (props.elevation !== undefined) {
    style.boxShadow = getBoxShadow(props.elevation);
  }

  if (props.backgroundColor) {
    style.background = `var(--${props.backgroundColor})`;
  }

  return style;
});

const getBoxShadow = (elevation: number): string => {
  const shadows = [
    'none', // elevation 0
    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)', // elevation 1
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)', // elevation 2
    '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)', // elevation 3
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)', // elevation 4
  ];

  return shadows[elevation] || shadows[0];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([variantClass.value, "sc-card v-theme--chess"]),
    style: _normalizeStyle(elevationStyle.value)
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.separator)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true),
    (_unref(slot).title)
      ? _renderSlot(_ctx.$slots, "title", { key: 2 })
      : _createCommentVNode("", true),
    (_unref(slot).default)
      ? _renderSlot(_ctx.$slots, "default", { key: 3 })
      : _createCommentVNode("", true),
    (_unref(slot).actions)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
          (_ctx.separator)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "actions")
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 6))
}
}

})