import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sc-textarea__label regular-18" }
const _hoisted_2 = ["placeholder", "disabled"]

import { ScInputProps } from './ScTextarea.types';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScTextarea',
  props: /*@__PURE__*/_mergeModels({
    errorText: {},
    clearable: { type: Boolean },
    label: {},
    placeholder: { default: '' },
    isError: { type: Boolean },
    isValid: { type: Boolean },
    size: { default: 'medium' },
    prependIcon: {},
    appendIcon: {},
    disabled: { type: Boolean },
    width: {},
    fullWidth: { type: Boolean }
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const modelValue = _useModel(__props, 'modelValue');

const isActive = ref(false);

const onFocus = (): void => {
  isActive.value = true;
};

const onBlur = (): void => {
  isActive.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["sc-textarea", [{ 'active-border': isActive.value }]])
    }, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modelValue).value = $event)),
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        class: _normalizeClass(["text-textarea", [
          _ctx.size === 'small'
            ? 'regular-14 sc-textarea__small'
            : 'regular-16 sc-textarea_medium',
            {'opacity-70 cursor-default': _ctx.disabled}
        ]]),
        onBlur: onBlur,
        onFocus: onFocus
      }, null, 42, _hoisted_2), [
        [_vModelText, modelValue.value]
      ])
    ], 2)
  ]))
}
}

})