import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "sc-number" }
const _hoisted_2 = {
  key: 0,
  class: "semibold-14 mb-2"
}
const _hoisted_3 = { class: "d-flex align-center ga-1" }
const _hoisted_4 = { style: {"width":"32px"} }
const _hoisted_5 = ["step", "disabled"]

import ScIcon from '@/components/Common/ScIcon.vue';
enum Direction {
  INCR = +1,
  DECR = -1,
}

interface InputProps {
  modelValue: number;
  label?: string;
  step?: number;
  positiveOnly?: boolean;
  max?: number;
  disabled?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ScNumberInput',
  props: /*@__PURE__*/_mergeModels({
    modelValue: {},
    label: {},
    step: { default: 1 },
    positiveOnly: { type: Boolean, default: true },
    max: {},
    disabled: { type: Boolean }
  }, {
    "modelValue": { required: true},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {


const props = __props;

const modelValue = _useModel<number>(__props, "modelValue");

const handleButtonClick = (direction: number): void => {
  const tempValue = modelValue.value + (props.step * direction);
  if (props.positiveOnly && tempValue < 0) {
    modelValue.value = 0;
    return;
  }
  if (props.max && tempValue > props.max) {
    modelValue.value = props.max;
    return;
  }
  modelValue.value = tempValue;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(ScIcon, {
        id: "line",
        width: 28,
        height: 28,
        class: _normalizeClass(["sc-input-button cursor-pointer", {'opacity-70 cursor-default':_ctx.disabled}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleButtonClick(Direction.DECR)))
      }, null, 8, ["class"]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "number",
          class: "regular-14 rounded-lg sc-input",
          step: _ctx.step,
          disabled: _ctx.disabled,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((modelValue).value = $event))
        }, null, 8, _hoisted_5), [
          [_vModelText, modelValue.value]
        ])
      ]),
      _createVNode(ScIcon, {
        id: "plus",
        width: 28,
        height: 28,
        class: "sc-input-button cursor-pointer",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (handleButtonClick(Direction.INCR)))
      })
    ])
  ]))
}
}

})