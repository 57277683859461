<template>
  <div class="sc-range-block d-flex align-center justify-space-between">
    <label class="regular-14 position-static d-flex align-center justify-start flex-row slider-label" v-if="label">{{ label }}</label>
    <div class="sc-range-item">
      <v-range-slider
        v-model="sliderModel"
        :max="steps.length - 1"
        :min="0"
        :step="1"
        :ticks="ticks"
        class="sc-range"
        hide-details
        strict
        :disabled="disabled"
      >
        <template v-slot:prepend v-if="!sliderInputOnly">
          <input
            :value="displayValueLeft"
            disabled
            class="slider-input regular-14 d-flex align-center justify-center flex-row text-center rounded-lg"
          />
        </template>
        <template v-slot:append v-if="!sliderInputOnly">
          <input
            :value="displayValRight"
            disabled
            class="slider-input regular-14 d-flex align-center justify-center flex-row text-center rounded-lg"
          />
        </template>
      </v-range-slider>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';

interface Props {
  steps?: number[];
  min?: number;
  max?: number;
  label?: string;
  sliderInputOnly?: boolean;
  infinityAt?: string | number;
  noOverlap?: boolean;
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  sliderInputOnly: false,
  noOverlap: false
});

const model = defineModel<number[]>('modelValue', {
  required: true,
  default: [],
});

const steps = computed(() => {
  if (props.steps) {
    return props.steps;
  }

  const min = props.min as number;
  const max = props.max as number;
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
});

const ticks = computed(() => {
  return steps.value.reduce<Record<number, string>>((acc, item, index) => {
    acc[index] = item.toString();
    return acc;
  }, {});
});

const sliderModel = computed({
  get: () => [
    steps.value.indexOf(model.value[0]),
    steps.value.indexOf(model.value[1]),
  ],
  set: (value: number[]) => {
    if(props.noOverlap){
      let [left, right] = value;

      if (left >= right) {
        left = steps.value.indexOf(model.value[0]);
      }
      if (right <= left) {
        right = steps.value.indexOf(model.value[1]);
      }
      model.value = [steps.value[left], steps.value[right]];
    } else {
      model.value = [steps.value[value[0]], steps.value[value[1]]];
    }
  },
});

const displayValRight = computed<number | string>(() => {
  const valRightSlide = ticks.value[sliderModel.value[1]];
  return String(props.infinityAt) === valRightSlide || valRightSlide === 'Infinity' ? '∞' : steps.value[sliderModel.value[1]];
});

const displayValueLeft = computed<number | string>(() => {
  const valLeftSlide = ticks.value[sliderModel.value[0]];
  return String(props.infinityAt) === valLeftSlide || valLeftSlide === 'Infinity' ? '∞' : steps.value[sliderModel.value[0]];
});

onMounted(() => {
  if (!model.value || model.value.length === 0) {
    model.value = [steps.value[0], steps.value[steps.value.length - 1]];
  }
});
</script>

<style scoped>
.sc-range-block {
  gap: 2px;
}

.sc-range-item {
  width: 210px;
}

.slider-label {
  color: var(--text-secondary);
}

.slider-input {
  width: 40px;
  height: 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--button-loading);
  box-sizing: border-box;
  border: 1px solid var(--form-stroke);
  background: var(--input-field);
  -moz-appearance: textfield;
}

.slider-input::-webkit-inner-spin-button,
.slider-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:deep(.v-slider-track__background--opacity) {
  opacity: 1;
}

:deep(.v-slider__container) {
  border-radius: 8px;
  background: var(--primary-disabled);
  width: calc(100% - 8px) !important;
}

:deep(.v-input--horizontal) {
  margin-inline: 0 !important;
}

:deep(.v-slider-track__fill) {
  border-radius: 0;
  height: 28px !important;
  margin: 0;
  cursor: pointer;
  background: var(--form-stroke);
}

:deep(.v-slider-thumb) {
  --v-slider-thumb-size: 8px !important;
  width: 8px !important;
  height: 28px !important;
  border-radius: 8px !important;
  background: var(--primary);
  cursor: pointer;
  box-shadow: none;
}

:deep(.v-input__control) {
  justify-content: center;
  border-radius: 8px;
  background: var(--primary-disabled);
}

:deep(.v-slider-track) {
  width: calc(100% - 9px) !important;
}

:deep(.v-input__prepend) {
  margin-right: 4px !important;
}

:deep(.v-input__append) {
  margin-left: 4px !important;
}

:deep(.v-slider.v-input--horizontal > .v-input__control) {
  min-height: 28px;
}

:deep(.v-slider.v-input--horizontal .v-slider-track__background) {
  height: 0;
}

:deep(.v-slider-thumb__surface) {
  display: none !important;
}

:deep(.v-slider-thumb__ripple) {
  display: none !important;
}
</style>
