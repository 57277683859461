export enum Methods {
  CHANGE_PASSWORD = "changepassword",
  CHECK_EMAIL = "checkemail",
  CONFIRM_EMAIL = "confirmemail",
  FEEDBACK = "feedback",
  FEN_TO_POS_ID = "fentoposid",
  HELP = "help",
  LOAD_GAMES = "loadgames",
  MAIN_PAGE = "mainpage",
  RANDOM_FEN = "randomfen",
  REGISTER = "register",
  REGISTER_BY_LINK = "registerbylink",
  REQUEST_REG_EMAIL = "requestregemail",
  SIGN_IN = "signin",
  SIGN_OUT = "signout",
  SIGN_OUT_ALL = "signoutall",
  WHO_IS = "whois",
  ANALYZES_IMPORT = "analyzes/import",
  HEROES_ADD = "heroes/add",
  HEROES_CHANGE_INFO = "heroes/changeinfo",
  HEROES_DELETE = "heroes/delete",
  HEROES_HELP_CT = "heroes/helpct",
  HEROES_LIST = "heroes/list",
  HEROES_RELOCATE = "heroes/relocate",
  HEROES_RENAME = "heroes/rename",
  HEROES_SET_ICON = "heroes/seticon",
  LEADING_ABORT = "leading/abort",
  LEADING_CONTINUE = "leading/continue",
  LEADING_NEW = "leading/new",
  LEADING_PASS = "leading/pass",
  LEADING_RESTART = "leading/restart",
  NICKNAMES_ADD = "nicknames/add",
  NICKNAMES_DISABLE = "nicknames/disable",
  NICKNAMES_ENABLE = "nicknames/enable",
  NICKNAMES_LIST = "nicknames/list",
  NICKNAMES_REASSIGN = "nicknames/reassign",
  NICKNAMES_UNZIP = "nicknames/unzip",
  NICKNAMES_ZIP = "nicknames/zip",
  NICKNAMES_DELETE = "nicknames/delete",
  OPENINGS_ADD_MOVE_LABEL = "openings/addmovelabel",
  OPENINGS_ADD_POS_LABEL = "openings/addposlabel",
  OPENINGS_DEL_MOVE_LABEL = "openings/delmovelabel",
  OPENINGS_DEL_MOVE_LABELS = "openings/delmovelabels",
  OPENINGS_DEL_POS_LABEL = "openings/delposlabel",
  OPENINGS_DEL_POS_LABELS = "openings/delposlabels",
  OPENINGS_HERO_CHAIN = "openings/herochain",
  OPENINGS_HERO_NODE = "openings/heronode",
  OPENINGS_HERO_VARIANTS = "openings/herovariants",
  OPENINGS_SET_MOVE_STATUS = "openings/setmovestatus",
  OPENINGS_UPDATE_MOVE_LABEL = "openings/updatemovelabel",
  OPENINGS_UPDATE_POS_LABEL = "openings/updateposlabel",
  SERVICE_CHANGE_ROLES = "service/changeroles",
  SERVICE_HISTORY = "service/history",
  SERVICE_REGISTER = "service/register",
  SERVICE_SET_PASSWORD = "service/setpassword",
  TASK_DONE = "task/done",
  TASK_GET = "task/get",
  TASK_STATUS = "task/status",
  TRAINING_ABORT_L = "training/abortl",
  TRAINING_ABORT_T = "training/abortt",
  TRAINING_ACTIVE = "training/active",
  TRAINING_ADD_TO_LIST = "training/addtolist",
  TRAINING_COMPLETE_L = "training/completel",
  TRAINING_COMPLETE_T = "training/completet",
  TRAINING_CREATE = "training/create",
  TRAINING_CREATE_LOAD = "training/createload",
  TRAINING_DELETE = "training/delete",
  TRAINING_DELETE_ALL = "training/deleteall",
  TRAINING_DEL_FROM_LIST = "training/delfromlist",
  TRAINING_GET_LIST = "training/getlist",
  TRAINING_INCOMPLETE = "training/incomplete",
  TRAINING_LIST = "training/list",
  TRAINING_LOAD = "training/load",
  TRAINING_START_L = "training/startl",
  TRAINING_START_T = "training/startt",
  TRAINING_UPDATE = "training/update",
  USER_GAMES_LOAD_HEADER = "usergames/loadheader",
  USER_GAMES_LOAD_MOVES = "usergames/loadmoves",
  USER_GAMES_LOAD_PGN = "usergames/loadpgn",
  USER_GAMES_MISTAKES = "usergames/mistakes",
  USER_GAMES_MISTAKES_OPENING = "usergames/mistakesopening",
  USER_GAMES_SUMMARY = "usergames/summary",
  USER_GAMES_UNCALCULATED = "usergames/uncalculated",
  USERS_BALANCE = "users/balance",
  USERS_GET_INFO = "users/getinfo",
  USERS_GET_PREFERENCES = "users/getpreferences",
  USERS_GET_SETTINGS = "users/getsettings",
  USERS_PAYMENT_NOTICE = "users/paymentnotice",
  USERS_SET_PREFERENCES = "users/setpreferences",
  USERS_SET_SETTINGS = "users/setsettings",

  USER_LOGIN = "user_login",
  USER_LOGOUT = "logout",
  USER_REGISTRATION = "user_registration",
  USER_SEND_MAIL_AGAIN = "user_send_mail_again",
  USER_PROMO_ACTIVATE = "user_promo_activate"
}
