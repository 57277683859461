export enum RouteNames {
  EMAIL = "Email",
  CARDS = "Cards",
  ABOUT = "About",
  LOGIN = "Login",
  CHANGE_PASSWORD = "ChangePassword",
  DEVELOP_PAGE = "DevelopPage",
  EXERCISE_PAGE = "ExercisePage",
  PRE_ANALYSIS = "PreAnalysis",
  ANALYSIS = "Analysis",
  NOT_FOUND = 'NotFound',
  REGISTER = 'Register',
  RECOVERY = 'Recovery',
  WAITING_FOR_CONFIRMATION = 'WaitingForConfirmation',
  PROMOCODE_FORM = 'PromocodeForm',
  PROMOCODE_SUCCESS = 'PromocodeSuccess',
  REGISTRATION_SUCCESS = 'RegistrationSuccess',
  AFTER_LOGIN = 'AfterLogin',
  UI_COMPONENTS = 'UiComponents'
};
