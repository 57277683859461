<template>
  <v-app class="bg-darkGrey">
    <router-view></router-view>
    <portal to="app-bar-portal-language">
            <v-menu class="regular-14">
              <template v-slot:activator="{ props }">
                <div class="app-bar-language-button d-flex align-center pointer" v-bind="props">
                  <div class="ml-2 semibold-14 app-bar-language__desktop">{{ currentLocale?.name }}</div>
                  <ScIcon
                    class="app-bar-language__desktop"
                    id="expandSmall"
                    :width="20"
                    :height="20"
                  />
                  <ScIcon class="app-bar-language__mobile" id="world" />
                </div>
              </template>
              <v-list density="compact" class="regular-14">
                <v-list-item
                  v-for="item in localeItems"
                  :key="item.id"
                  @click="selectLanguage(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
    </portal>
  </v-app>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import { useUIStore } from './shared/composables/useUIStore';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouteQueryParams } from '@/router/useRouteQueryParams';
import ScIcon from '@/components/Common/ScIcon.vue';

interface LocaleItem {
  title: string;
  id: string;
  name: string;
};

const localeItems: LocaleItem[] = [
  { title: 'en', id: '1', name: 'English' },
  { title: 'ru', id: '4', name: 'Русский' },
];

const currentLocale = ref<LocaleItem>();

const store = useStore();
const userPreferences = computed(() => store.state.ui.userPreferences);
const isAuthenticated = computed(() => store.state.isAuthenticated);

const { uiDispatch, uiCommit } = useUIStore();
const { synchronizeQueryParams } = useRouteQueryParams();
synchronizeQueryParams();

const token = localStorage.getItem('token');

  if (token) {
    store.commit('SET_DATA_BY_KEY', {
      key: 'isAuthenticated',
      value: true,
    });
  }

const { locale } = useI18n();

const selectLanguage = (item: LocaleItem): void => {
  currentLocale.value = item;
  locale.value = item.title;
  if (!isAuthenticated.value) {
      uiCommit('SET_DATA_BY_KEY', {
        key: 'userPreferences',
        value: { lang_interface: Number(item.id) },
      });
      localStorage.setItem('savedLocale', JSON.stringify(item));
    } else {
      localStorage.setItem('savedLocale', JSON.stringify(item));
      uiDispatch('setUserPreferences', {
        ...userPreferences.value,
        lang_interface: Number(item.id),
      });
    }
};

const savedLocale = localStorage.getItem('savedLocale');

if (savedLocale) {
  try {
    const locale = JSON.parse(savedLocale);
    selectLanguage(locale);
  } catch (error) {
    console.log(error);
  }
} else {
  currentLocale.value = localeItems[0];
}

watch(userPreferences, (val) => {
  if (val && val.lang_interface !== undefined) {
    const targ = localeItems.find(
      (i) => Number(i.id) === val.lang_interface,
    );
    if (targ) {
      currentLocale.value = targ;
      locale.value = targ.title;
      localStorage.setItem('savedLocale', JSON.stringify(targ));
    }
  }
},
  { immediate: true },
);

watch(currentLocale, (val) => {
  document.documentElement.setAttribute('lang', val?.title ?? 'en');
});

const setScreenSizes = (): void => {
  store.commit('SET_DATA_BY_KEY', {
        key: 'sizes',
        value: {
        vh:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
        vw:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      },
  });
};

onMounted(() => {
  setScreenSizes();
  window.addEventListener('resize', setScreenSizes);
});
</script>

<style scoped>
@media (max-width: 959px) {
  .app-bar-language-button {
    position: absolute;
    z-index: 9000;
    right: 23px;
    top: 23px;
  }
}
@media (max-width: 767px) {
  .app-bar-language__desktop {
    display: none;
  }
}

@media (min-width: 768px) {
 .app-bar-language__mobile {
    display: none;
 }
}



</style>
