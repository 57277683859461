import { Component, computed, defineAsyncComponent, ref } from 'vue';
import { i18n } from '@/i18n';
import router from '@/router/router';

export type HelpPointKey = 
| 'demonstrationMode'
| 'gameStart'
| 'transitionBetweenPositions'
| 'criticalPositions'
| 'fenFormat'
| 'treeViewAlternatives'
| 'directTraining'
| 'positionVariants'
| 'trainingPlanFavorites'
| 'addToLists'
| 'filterBlock'
| 'positionNode'
| 'moveEdge'
| 'positionNodeExercise'
| 'moveEdgeExercise';

export type HelpPointPosition = 'left' | 'right' | 'under';

export type HelpAlign = 'bottom' | 'middle';

export interface HelpCardParams {
  title: string;
  content: string | Component;
  position: HelpPointPosition;
  align?: HelpAlign;
}

export interface HelpPointParams extends HelpCardParams {
  customWidth?: number;
  customHeight?: number;
}

export const helpModeEnabled = ref<boolean>(false);

export const useHelp = () => {
  const { t } = i18n.global;

  router.beforeEach(() => {
    helpModeEnabled.value = false;
  });

  const toggleHelp = () => helpModeEnabled.value = !helpModeEnabled.value;

  const helpPointsMap = computed<Record<HelpPointKey, HelpPointParams>>(() => ({
    // Pre-analysis
    gameStart: { title: t('help.preAnalysis.gameStartTitle'), content: t('help.preAnalysis.gameStart'), position: 'right' },
    transitionBetweenPositions: { title: t('help.preAnalysis.transitionBetweenPositionsTitle'), content: t('help.preAnalysis.transitionBetweenPositions'), position: 'right' },
    demonstrationMode: {
      title: t('help.preAnalysis.demonstrationModeTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/DemonstrationMode.vue')),
      position: 'right',
    },
    criticalPositions: {
      title: t('help.preAnalysis.criticalPositionsTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/CriticalPositions.vue')),
      position: 'right'
    },
    fenFormat: { title: t('help.preAnalysis.fenFormatTitle'), content: t('help.preAnalysis.fenFormat'), position: 'left' },
    treeViewAlternatives: { title: t('help.preAnalysis.treeViewAlternativesTitle'), content: `${t('help.preAnalysis.treeViewAlternatives')} ${t('help.preAnalysis.navigateAnalysis')}`, position: 'left' },
    directTraining: { title: t('help.preAnalysis.directTrainingTitle'), content: `${t('help.preAnalysis.directTraining')} ${t('help.preAnalysis.navigateTraining')}`, position: 'left' },
    positionVariants: { title: t('help.preAnalysis.positionVariantsTitle'), content: `${t('help.preAnalysis.positionVariants')}`, position: 'left' },
    trainingPlanFavorites: { title: t('help.preAnalysis.trainingPlanFavoritesTitle'), content: t('help.preAnalysis.trainingPlanFavorites'), position: 'right' },
    addToLists: { title: t('help.preAnalysis.addToListsTitle'), content: `${t('help.preAnalysis.addToLists')}`, position: 'left' },
    filterBlock: {
      title: t('help.preAnalysis.filterBlock.filterBlockTitle'),
      content: `
          <p style="color: #ffffff">${t('help.preAnalysis.filterBlock.chooseOptions')}</p>
          <ul>
              <li>${t('help.preAnalysis.filterBlock.colorPlayed')}</li>
              <li>${t('help.preAnalysis.filterBlock.moveRange')}</li>
              <li>${t('help.preAnalysis.filterBlock.errorSeverity')}</li>
              <li>${t('help.preAnalysis.filterBlock.openingName')}</li>
              <li>${t('help.preAnalysis.filterBlock.displayResultsFor')}
              <q>${t('help.preAnalysis.filterBlock.allCriticalPositions')}</q>,
              <q>${t('help.preAnalysis.filterBlock.favorites')}</q>,
              <q>${t('help.preAnalysis.filterBlock.trainingPlan')}</q></li>
          </ul>`,
      position: 'right'
    },
    // Main page
    accountName: {
      title: t('help.mainPage.accountNameTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/AccountName.vue')),
      position: 'left'
    },
    addNewPlayer: {
      title: t('help.mainPage.addNewPlayerTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/AddNewPlayer.vue')),
      position: 'right'
    },
    analyzedUser: {
      title: t('help.mainPage.analyzedUserTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/AnalyzedUser.vue')),
      position: 'right'
    },
    gameAdditionMenu: {
      title: t('help.mainPage.gameAdditionMenuTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/GameAdditionMenu.vue')),
      position: 'left'
    },
    nicknameActivation: {
      title: t('help.mainPage.nicknameActivationTitle'),
      content: `${t('help.mainPage.nicknameActivation')}`,
      position: 'under',
      customHeight: 112,
    },
    analyzedNicknamesList: {
      title: t('help.mainPage.analyzedNicknamesListTitle'),
      content: `${t('help.mainPage.analyzedNicknamesList')}`,
      position: 'under',
      customHeight: 112,
    },
    gameSource: {
      title: t('help.mainPage.gameSourceTitle'),
      content: `${t('help.mainPage.gameSource')}`,
      position: 'under',
      customHeight: 112,
    },
    timeControlsMenu: {
      title: t('help.mainPage.timeControlsMenuTitle'),
      content: `${t('help.mainPage.timeControlsMenu')}`,
      position: 'under',
      customHeight: 112,
      customWidth: 631,
    },
    planOptions: {
      title: t('help.mainPage.planOptionsTitle'),
      content: ``,
      position: 'right',
      align: 'bottom',
    },
    criticalPositionsOverview: {
      title: t('help.mainPage.criticalPositionsOverviewTitle'),
      content: `${t('help.mainPage.criticalPositionsOverview')}`,
      position: 'left',
      align: 'bottom',
    },
    navigation: {
      title: t('help.mainPage.navigationTitle'),
      content: `<p class="white-text">${t('help.mainPage.navigation')}</p>
      <ul><li>${t('help.mainPage.navigation1')}</li>
        <li>${t('help.mainPage.navigation2')}</li>
        <li>${t('help.mainPage.navigation3')}</li>
        <li>${t('help.mainPage.navigation4')}</li>
      </ul>`,
      position: 'right'
    },
    feedbackForm: {
      title: t('help.mainPage.feedbackFormTitle'),
      content: `${t('help.mainPage.feedbackForm')}`,
      position: 'left'
    },
    // AnalysisPage
    controlGroupMenu: {
      title: t('help.analysisPage.controlGroupMenuTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/ControlGroupMenu.vue')),
      position: 'right',
      align: 'bottom',
    },
    positionSelection: {
      title: t('help.analysisPage.positionSelectionTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/PositionSelection.vue')),
      position: 'right',
      align: 'bottom',
    },
    positionNode: {
      title: t('help.analysisPage.positionNodeTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/PositionNode.vue')),
      position: 'right',
    },
    moveEdge: {
      title: t('help.analysisPage.moveEdgeTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/MoveEdge.vue')),
      position: 'right',
    },
    branchCorrectionTool: {
      title: t('help.analysisPage.branchCorrectionToolTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/BranchCorrectionTool.vue')),
      position: 'right',
    },
    nextMovesTool: {
      title: t('help.analysisPage.nextMovesToolTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/NextMovesTool.vue')),
      position: 'right',
    },
    massExpandMovesTool: {
      title: t('help.analysisPage.massExpandMovesToolTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/MassExpandMovesTool.vue')),
      position: 'right',
    },
    moveNumbering: {
      title: t('help.analysisPage.moveNumberingTitle'),
      content: `<p class="white-text">${t('help.analysisPage.moveNumbering')}</p>${t('help.analysisPage.moveNumbering1')}<br/>${t('help.analysisPage.moveNumbering2')}`,
      position: 'right',
    },
    variationFilter: {
      title: t('help.analysisPage.variationFilterTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/VariationFilter.vue')),
      position: 'right',
    },
    trainingPositions: {
      title: t('help.analysisPage.trainingPositionsTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/TrainingPositions.vue')),
      position: 'right',
    },
    statisticsBlock: {
      title: t('help.analysisPage.statisticsBlockTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/StatisticsBlock.vue')),
      position: 'left',
      align: 'middle',
    },
    positionVariations: {
      title: t('help.analysisPage.positionVariationsTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/PositionVariations.vue')),
      position: 'left',
      align: 'middle',
    },
    addRemovePosition: {
      title: t('help.analysisPage.addRemovePositionTitle'),
      content: '',
      position: 'left',
      align: 'middle',
    },
    bookmarkFunction: {
      title: t('help.analysisPage.bookmarkFunctionTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/BookmarkFunction.vue')),
      position: 'left',
      align: 'middle',
    },
    hideBoardStats: {
      title: t('help.analysisPage.hideBoardStatsTitle'),
      content: `${t('help.analysisPage.hideBoardStats')}`,
      position: 'left',
    },
    criticalPositionsPage: {
      title: t('help.analysisPage.criticalPositionsPageTitle'),
      content: '',
      position: 'right',
    },
    treeScale: {
      title: t('help.analysisPage.treeScaleTitle'),
      content: `${t('help.analysisPage.treeScale')}`,
      position: 'left',
    },
    stockfishAnalysis: {
      title: t('help.analysisPage.stockfishAnalysisTitle'),
      content: `${t('help.analysisPage.stockfishAnalysis')}`,
      position: 'left',
      align: 'middle',
    },
    elementsDisplaySettings: {
      title: t('help.analysisPage.elementsDisplaySettingsTitle'),
      content: `${t('help.analysisPage.elementsDisplaySettings')}`,
      position: 'left',
    },
    trainingPage: {
      title: t('help.analysisPage.trainingPageTitle'),
      content: `${t('help.analysisPage.trainingPage')}`,
      position: 'left',
    },
    // exercisePage
    positionNodeExercise: {
      title: t('help.exercisePage.positionNodeExerciseTitle'),
      content: defineAsyncComponent(() => import('@/components/Help/PositionNodeExercise.vue')),
      position: 'right',
    },
    moveEdgeExercise: {
      title: t('help.exercisePage.moveEdgeExerciseTitle'),
      content: `<p class="white-text">${t('help.exercisePage.moveEdgeExercise')}</p>`,
      position: 'right',
    },
    trainingMovesCount: {
      title: t('help.exercisePage.trainingMovesCountTitle'),
      content: '',
      position: 'right',
    },
    branchSelectionInfo: {
      title: t('help.exercisePage.branchSelectionInfoTitle'),
      content: '',
      position: 'right',
    },
    trainingSettings: {
      title: t('help.exercisePage.trainingSettingsTitle'),
      content: `<p class="white-text">${t('help.exercisePage.trainingSettings')}</p>
      <ul>
        <li>${t('help.exercisePage.trainingSettings1')}</li>
        <li>${t('help.exercisePage.trainingSettings2')}</li>
        <li>${t('help.exercisePage.trainingSettings3')}</li>
      </ul>
      `,
      position: 'right',
    },
    modeSelection: {
      title: t('help.exercisePage.modeSelectionTitle'),
      content: `
        <ul>
          <li>${t('help.exercisePage.modeSelection')}</li>
          <li>${t('help.exercisePage.modeSelection1')}</li>
        </ul>`,
      position: 'left',
    },
    startTrainingButton: {
      title: t('help.exercisePage.startTrainingButtonTitle'),
      content: '',
      position: 'left',
    },
    currentStateWindow: {
      title: t('help.exercisePage.currentStateWindowTitle'),
      content: '',
      position: 'left',
    }
  }));

  return {
    helpPointsMap,
    toggleHelp,
  };
}