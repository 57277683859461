import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 1,
  class: "help-card__content px-3"
}

import { HelpCardParams } from '@/shared/composables/useHelp';
import { computed, CSSProperties, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpCard',
  props: {
    title: {},
    content: {},
    position: {},
    align: {}
  },
  setup(__props: any) {

const props = __props;

const helpCardRef = ref<HTMLDivElement | null>(null);

const isContentHTML = computed<boolean>(() => typeof props.content === 'string');

const verticalAligning = computed<CSSProperties>(() => {
  if (props.align === 'bottom') {
    return { bottom: 0 };
  }
  if (props.align === 'middle') {
    if (helpCardRef.value) {
      return { top: `-${helpCardRef.value.clientHeight / 2}px` };
    }
    return { top: '-200px' };
  }
  return { top: '-1px' };
});

const style = computed<CSSProperties>(() => ({
  ...verticalAligning.value,
  left: props.position === 'right' ? 'calc(100% + 12px)' : 'unset',
  right: props.position === 'left' ? 'calc(100% + 12px)' : 'unset',
  top: props.position === 'under' ? 'calc(100% + 12px)' : 'unset',
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "help-card pa-3",
    style: _normalizeStyle(style.value),
    ref_key: "helpCardRef",
    ref: helpCardRef
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["help-card__title px-3 py-2 semibold-16", { 'mb-3 help-card__title_underlined': _ctx.content }])
    }, _toDisplayString(_ctx.title), 3),
    (isContentHTML.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.content,
          class: "help-card__content regular-16 px-3"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.content)))
        ]))
  ], 4))
}
}

})