import cloneDeep from 'lodash/cloneDeep';
import deepFind from '@/helpers/deepFind';
import orch from '@/plugins/requestOrchestrator';
import AxiosWrapper, { RequestData } from '@/plugins/NewAxiosWrapper';
import type { Commit, Dispatch } from 'vuex';
import type {
  ModifyPosLabelRequest,
  FenToPosIdRequest,
  OpeningsHeroChainRequest,
  OpeningsHeroNodeRequest,
  OpeningsHeroVariantsRequest,
  TrainingCreateRequest,
  TrainingDeleteRequest,
  TrainingGetListRequest,
  UserGamesMistakesOpeningRequest,
  UserGamesMistakesRequest,
  SuccessMessage,
} from '@/store/types';
import { Scenario, ScenarioType } from '@/shared/types/enums';
import { TreeItem } from '@/components/SvgTree/SvgTree.types';
import {
  HeroNodeResponse,
  HeroVariant,
  MistakesResponse,
  TrainingsList,
} from '@/store/data/types';
import { Methods } from '@/store/enums';

let mistakesTimeout: number | null = null;

export default {
  async updateCompareFilters(
    {
      dispatch,
      commit,
      state,
    }: { dispatch: Dispatch; commit: Commit; state: any },
    { filters, heroid }: { filters: any; heroid: any },
  ) {
    let clone;
    if (state.selectedMistake) {
      clone = cloneDeep(state.selectedMistake);
    }

    commit('SET_DATA_BY_KEY', { key: 'compareFilters', value: filters });
    commit('SET_DATA_BY_KEY', { key: 'openingsTree', value: null });
    commit('SET_DATA_BY_KEY', { key: 'selectedOpening', value: null });

    if (clone) {
      const { hm, position_id } = clone;

      dispatch('getOpeningsHeroNode', {
        posid: position_id,
        heroid,
        hm,
        herowhite: state.selectedMistake?.herowhite || 1,
      });
    }
  },
  async modifyPositionLabel(
    { dispatch }: { dispatch: Dispatch },
    {
      opening,
      scenario,
      heroid,
      herocats,
    }: {
      opening: any;
      scenario: ScenarioType;
      heroid: string | number;
      herocats: any;
    },
  ) {
    const data: RequestData<ModifyPosLabelRequest> = {
      heroid: Number(heroid),
      posid: opening.position_id,
      mtd: Methods.OPENINGS_ADD_POS_LABEL,
    };
    switch (scenario) {
      case Scenario.TO_WATCH_LIST:
        data.labid = 2;
        break;
      case Scenario.TO_IGNORE:
        data.mtd = Methods.OPENINGS_UPDATE_POS_LABEL;
        data.dellabid = 2;
        data.addlabid = 3;
        break;
      case Scenario.TO_DEFAULT:
        data.mtd = Methods.OPENINGS_DEL_POS_LABEL;
        data.labid = 3;
        break;
      case Scenario.TO_STUDIED:
        data.labid = 1;
        break;
      default:
        break;
    }

    const resp = await AxiosWrapper.request<
      ModifyPosLabelRequest,
      SuccessMessage
    >(data);
    if (resp?.message !== 'OK') {
      return;
    }

    dispatch('getMistakes', { heroid, herocats });
  },
  async switchPositionListingState(
    { dispatch }: { dispatch: Dispatch },
    {
      opening,
      heroid,
      herocats,
      label,
      type,
    }: {
      opening: any;
      heroid: string | number;
      herocats: any;
      label: number;
      type: 0 | 1;
    },
  ) {
    const mtd = type
      ? Methods.OPENINGS_DEL_POS_LABEL
      : Methods.OPENINGS_ADD_POS_LABEL;

    const data = {
      mtd,
      heroid: Number(heroid),
      posid: opening.position_id,
      labid: label,
    };

    const resp = await AxiosWrapper.request(data);

    if (resp) {
      dispatch('getMistakes', { heroid, herocats });
    }
  },
  async getOpenings(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    {
      fen,
      heroid,
      position_id,
      hm,
      herocats,
      herowhite,
    }: {
      fen: string;
      heroid: string | number;
      position_id: number;
      hm: any;
      herocats: number[];
      herowhite: 0 | 1;
    },
  ) {
    if (fen) {
      const data = {
        fen,
        mtd: Methods.FEN_TO_POS_ID,
      };
      const signal = orch.register({
        fen,
        mtd: Methods.FEN_TO_POS_ID,
      }).signal;

      const resp = await AxiosWrapper.request<FenToPosIdRequest, any>(
        data,
        signal,
      );

      orch.kill({
        fen,
        mtd: Methods.FEN_TO_POS_ID,
      });

      commit('SET_DATA_BY_KEY', { key: 'selectedOpening', value: null });

      if (resp) {
        commit('SET_DATA_BY_KEY', {
          key: 'parsedFen',
          value: resp,
        });

        dispatch('getOpeningsHeroNode', {
          posid: resp?.posid,
          heroid,
          herowhite: resp?.herowhite,
          herocats,
          hm: resp?.hm,
        });
      }
    }

    if (position_id) {
      dispatch('getOpeningsHeroNode', {
        posid: position_id,
        heroid,
        hm,
        herocats,
        herowhite,
      });
    }
  },
  async getOpeningsHeroNode(
    { commit, state }: { commit: Commit; state: any },
    {
      heroid = 31,
      herocats = '1,2,3,4',
      hm = 40,
      posid = 10,
      datefrom = '1970-01-01 00:00:00',
      dateto = '2030-12-31 23:59:59',
      herowhite = 1,
    }: {
      heroid: number;
      herocats: string;
      hm: number;
      posid: number;
      datefrom: string;
      dateto: string;
      herowhite: 0 | 1;
    },
  ) {
    const data = {
      mtd: Methods.OPENINGS_HERO_NODE,
      heroid: Number(heroid),
      posid: Number(posid),
      hm: Number(hm || state.parsedFen.hm),
      herowhite,
      datefrom,
      dateto,
      herocats: Array.isArray(herocats)
        ? herocats
        : herocats.split(',').map((i) => Number(i)),
    };

    const signal = orch.register(data).signal;

    const resp = await AxiosWrapper.request<
      OpeningsHeroNodeRequest,
      HeroNodeResponse
    >(data, signal);

    orch.kill(data);

    if (resp) {
      const obj = {
        ...resp,
        children: [],
        id: 'gggfh37900',
        nodethis: 'gggfh37900',
        visibleChildren: 0,
        hm: Number(hm || state.parsedFen.hm),
        posid: Number(posid || state.parsedFen.posid),
      };

      commit('SET_DATA_BY_KEY', { key: 'openingsTree', value: obj });
      commit('SET_DATA_BY_KEY', { key: 'selectedOpening', value: obj });
      commit('SET_DATA_BY_KEY', { key: 'openSequense', value: 3 });

      return resp;
    }
  },
  async getMistakes(
    {
      commit,
      state,
      dispatch,
    }: { commit: Commit; state: any; rootState: any; dispatch: Dispatch },
    params: any,
  ) {
    if (mistakesTimeout) {
      clearTimeout(mistakesTimeout);
      mistakesTimeout = null;
    }

    const selectedMistake = state.selectedMistake;

    const paramsToRequest = {
      heroid: 31,
      herocats: '1,2,3,4',
      fen: '',
      datefrom: '1970-01-01 00:00:00',
      dateto: '2030-12-31 23:59:59',
      filters: '0,0,0,0',
      sort: 0,
      ...params,
    };

    if (!paramsToRequest.fen) {
      delete paramsToRequest.fen;
    }

    const data = {
      mtd: Methods.USER_GAMES_MISTAKES,
      heroid: Number(paramsToRequest.heroid),
      datefrom: paramsToRequest.datefrom,
      dateto: paramsToRequest.dateto,
      herocats: paramsToRequest.herocats
        ?.split(',')
        .map((i: string) => Number(i)),
      fen: paramsToRequest.fen,
    };

    if (!data.heroid) {
      return;
    }

    const signal = orch.register(data).signal;

    const resp = await AxiosWrapper.request<
      UserGamesMistakesRequest,
      MistakesResponse
    >(data, signal);

    if (resp && !resp.message) {
      orch.kill(data);
      commit('SET_DATA_BY_KEY', {
        key: 'mistakesOpenings',
        value: resp?.list,
      });
      commit('SET_DATA_BY_KEY', {
        key: 'mistakes',
        value: resp,
      });

      if (selectedMistake || params.position_id) {
        const occurance = resp.list.find(
          (mistake: any) =>
            mistake.position_id === selectedMistake?.position_id ||
            params?.position_id == mistake.position_id,
        );

        if (occurance) {
          commit('SET_DATA_BY_KEY', {
            key: 'selectedMistake',
            value: occurance,
          });
        }
      } else {
        if (resp.list && resp.list[0]) {
          commit('SET_DATA_BY_KEY', {
            key: 'selectedMistake',
            value: resp.list[0],
          });
        }
      }

      commit('SET_DATA_BY_KEY', { key: 'openingOptionsFilter', value: 1 });

      if (mistakesTimeout) {
        clearTimeout(mistakesTimeout);
        mistakesTimeout = null;
      }
    } else {
      // запуск повторного запроса через таймаут
      if (resp) {
        commit('SET_DATA_BY_KEY', {
          key: 'appToast',
          value: 'Идёт расчёт ошибок. Подождите',
        });

        mistakesTimeout = setTimeout(
          () => dispatch('getMistakes', params),
          10000,
        );
      } else {
        orch.kill(data);
      }
    }
  },
  async getMistakesOpening(
    { commit, state }: { commit: Commit; state: any },
    params: any,
  ) {
    console.log('getMistakesOpening deprecated!');

    const selectedMistake = state.selectedMistake;

    const paramsToRequest = {
      heroid: 31,
      herocats: '1,2,3,4',
      fen: '',
      datefrom: '1970-01-01 00:00:00',
      dateto: '2030-12-31 23:59:59',
      filters: '0,0,0,0',
      sort: 0,
      ...params,
    };

    if (!paramsToRequest.fen) {
      delete paramsToRequest.fen;
    }

    const data = {
      mtd: Methods.USER_GAMES_MISTAKES_OPENING,
      heroid: Number(paramsToRequest.heroid),
      datefrom: paramsToRequest.datefrom,
      dateto: paramsToRequest.dateto,
      herocats: paramsToRequest.herocats
        ?.split(',')
        .map((i: string) => Number(i)),
      fen: paramsToRequest.fen,
    };

    const signal = orch.register(data).signal;

    const resp = await AxiosWrapper.request<
      UserGamesMistakesOpeningRequest,
      any
    >(data, signal);

    orch.kill(data);

    if (resp) {
      commit('SET_DATA_BY_KEY', {
        key: 'mistakesOpenings',
        value: resp,
      });

      if (selectedMistake) {
        const occurance = resp.find(
          (mistake: any) =>
            mistake.fen === selectedMistake.fen &&
            mistake.prevmove === selectedMistake.prevmove &&
            mistake.position_id === selectedMistake.position_id,
        );
        if (occurance) {
          commit('SET_DATA_BY_KEY', {
            key: 'selectedMistake',
            value: occurance,
          });
        }
      }

      commit('SET_DATA_BY_KEY', { key: 'openingOptionsFilter', value: 1 });
    }
  },
  async loadChildren(
    {
      state,
      dispatch,
      commit,
    }: { state: any; commit: Commit; dispatch: Dispatch },
    {
      heroid,
      herocats,
      posid,
      nodeid,
      count,
    }: {
      heroid: number;
      herocats: string;
      posid: number;
      nodeid: number;
      count: number;
    },
  ) {
    // if (state.childrenLoadingProgress[nodeid]) {
    //   return;
    // } else {
    //   const clone = cloneDeep(state.childrenLoadingProgress);
    //   clone[nodeid] = true;
    //   commit('SET_DATA_BY_KEY', {
    //     key: 'childrenLoadingProgress',
    //     value: clone,
    //   });
    // }

    const clone = cloneDeep(state.openingsTree);

    if (!clone) {
      return;
    }

    let target;
    if (nodeid === clone.nodethis) {
      target = clone;
    } else {
      target = deepFind({
        array: clone.children,
        value: nodeid,
        key: 'nodethis',
      });
    }

    if (!target) {
      return clone;
    }
    if (target.children?.length === 0) {
      const isRoot = target.nodethis === clone.nodethis;

      if (isRoot && state.exerciseTree) {
        const rootHm = target.hm;

        const tree = state.exerciseTree;

        let total = 0;
        let loaded = 0;
        let maxHm = 0;

        const iterateTree = (item: TreeItem) => {
          total++;
          if (item.children) {
            for (let i = 0; i < item.children.length; i++) {
              iterateTree(item.children[i]);
            }
          }

          if (item.hm > maxHm) {
            maxHm = item.hm;
          }
        };

        iterateTree(tree);

        const getPercent = (num: number) => {
          const calc = Math.floor((num / total) * 100);
          return calc;
        };

        const updateChildren = async (item: any) => {
          const { status, default_training, iscrown } = item;

          if (
            (status === 0 || (status === 2 && default_training === 1)) &&
            iscrown === 0 &&
            item.hm <= maxHm
          ) {
            item.autoOpened = true;
            if (item.children.length === 0) {
              const loadedNestedChildren = await dispatch('getHeroVariants', {
                posid: Number(item.pos_to_id),
                hm_child: item.hm_child,
                hm: Number(item.hm),
                heroid: Number(heroid),
                herocats: Array.isArray(herocats)
                  ? herocats
                  : herocats.split(',').map((i) => Number(i)),
                depthfull: 1,
                depthfilter: 0,
                herowhite: clone.hm % 2 === 0 ? 0 : 1,
                nodeid: item.nodethis,
              });
              loaded++;
              commit('SET_DATA_BY_KEY', {
                key: 'autoLoadingProgress',
                value: getPercent(loaded),
              });
              for (const child of loadedNestedChildren) {
                await updateChildren(child);
              }
              item.children = loadedNestedChildren;
            }
          }
        };

        const loadedChildren = await dispatch('getHeroVariants', {
          posid: Number(posid),
          hm_child: target.hm_child,
          hm: Number(target.hm),
          heroid: Number(heroid),
          herocats: Array.isArray(herocats)
            ? herocats
            : herocats.split(',').map((i) => Number(i)),
          //спросить у Андрея правильную комбинацию
          depthfull: 0,
          depthfilter: 1,
          herowhite: clone.hm % 2 === 0 ? 0 : 1,
          nodeid,
        });

        loaded++;

        commit('SET_DATA_BY_KEY', {
          key: 'autoLoadingProgress',
          value: getPercent(loaded),
        });
        for (const child of loadedChildren) {
          await updateChildren(child);
        }

        target.children = loadedChildren;
        target.autoOpened = true;
        commit('SET_DATA_BY_KEY', {
          key: 'autoLoadingProgress',
          value: null,
        });
        commit('SET_DATA_BY_KEY', { key: 'autoLoading', value: false });
      } else {
        target.children = await dispatch('getHeroVariants', {
          posid: Number(posid),
          hm_child: target.hm_child,
          hm: Number(target.hm),
          heroid: Number(heroid),
          herocats: Array.isArray(herocats)
            ? herocats
            : herocats.split(',').map((i) => Number(i)),
          depthfull: count ? 1 : 2,
          depthfilter: count || 0,
          herowhite: clone.hm % 2 === 0 ? 0 : 1,
          nodeid,
        });
      }
    }
    commit('SET_DATA_BY_KEY', { key: 'autoLoading', value: false });
    commit('SET_DATA_BY_KEY', {
      key: 'openingsTree',
      value: clone,
      freeze: true,
    });

    // const clone2 = cloneDeep(state.childrenLoadingProgress);
    // delete clone2[nodeid];
    // commit('SET_DATA_BY_KEY', {
    //   key: 'childrenLoadingProgress',
    //   value: clone2,
    // });

    return clone;
  },
  async getHeroChain({}, requestParams: any) {
    const data = {
      ...requestParams,
      mtd: Methods.OPENINGS_HERO_CHAIN,
    };
    const signal = orch.register(data).signal;
    const resp = await AxiosWrapper.request<OpeningsHeroChainRequest, any[]>(
      data,
      signal,
    );

    orch.kill(data);

    if (resp) {
      const mapped = resp?.map((i: any) => ({
        ...i,
        id: i.nodethis,
        visibleChildren: 0,
        lines: true,
      }));

      return mapped;
    }
    return [];
  },
  async getHeroVariants({}, requestParams: any) {
    const rootId = requestParams?.nodeid || 'gggfh37900';

    const defaultParams = {
      heroid: 31,
      herocats: [1, 2, 3, 4],
      hm: 40,
      posid: 10,
      datefrom: '1970-01-01 00:00:00',
      dateto: '2030-12-31 23:59:59',
      herowhite: 1, // брать на основе рутового hm
      depthfull: 2,
      depthfilter: 0,
    };

    const data = {
      // mtd: 'openings/herovariants',
      mtd: Methods.OPENINGS_HERO_VARIANTS,
      ...defaultParams,
      ...requestParams,
      nodeid: rootId,
    };

    const signal = orch.register(data).signal;

    const resp = await AxiosWrapper.request<
      OpeningsHeroVariantsRequest,
      HeroVariant[]
    >(data, signal);

    orch.kill(data, true);

    if (resp) {
      const mapped = resp?.map((i) => ({
        ...i,
        id: i.nodethis,
        visibleChildren: 0,
        lines: true,
      }));

      const reduceAndSortArray = (array: any[]) => {
        const groups = array.reduce((acc, item) => {
          if (!acc[item.nodeparent]) {
            acc[item.nodeparent] = [];
          }
          acc[item.nodeparent].push(item);
          return acc;
        }, {});

        const sortedArrays = Object.values(groups).map((group) => {
          // @ts-ignore
          const sorted = group.sort((a, b) => {
            const aVal = a.waste;
            const bVal = b.waste;

            return aVal > bVal ? 1 : -1;
          });
          return sorted;
        });
        return sortedArrays.flat();
      };
      if (!mapped) return [];
      const reducedAndSorted = reduceAndSortArray(mapped);

      const result = [];

      for (const child of reducedAndSorted) {
        const { nodeparent } = child;

        if (nodeparent == rootId) {
          result.push({ ...child });
        } else {
          const parent = deepFind({
            array: result,
            value: nodeparent,
            key: 'nodethis',
          });

          if (parent) {
            console.log('p1');
            parent.children.push({ ...child });
          } else {
            const parent = deepFind({
              array: reducedAndSorted,
              value: nodeparent,
              key: 'nodethis',
            });
            if (parent) {
              parent.children.push({ ...child });
            }
          }
        }
      }
      return result;
    }
    return [];
  },
  async addToExercises(
    { dispatch }: { dispatch: Dispatch },
    requestParams: any,
  ) {
    const data = { mtd: Methods.TRAINING_CREATE, ...requestParams };
    const resp = await AxiosWrapper.request<TrainingCreateRequest, any>(data);

    if (resp && !resp.errorMessage) {
      dispatch('getExercisesList', { heroid: requestParams.heroid });
    } else if (resp.errorMessage) {
      throw new Error(resp.errorMessage);
    }
  },
  async removeFromExercises(
    { dispatch }: { dispatch: Dispatch },
    requestParams: any,
  ) {
    const data = { mtd: Methods.TRAINING_DELETE, ...requestParams };
    const resp = await AxiosWrapper.request<
      TrainingDeleteRequest,
      SuccessMessage
    >(data);

    if (resp) {
      dispatch('getExercisesList', { heroid: requestParams.heroid });
    }
  },
  async getExercisesList({ commit }: { commit: Commit }, requestParams: any) {
    const data = { mtd: Methods.TRAINING_GET_LIST, ...requestParams };
    const resp = await AxiosWrapper.request<
      TrainingGetListRequest,
      TrainingsList[]
    >(data);

    if (resp) {
      commit('SET_DATA_BY_KEY', { key: 'exercisesList', value: resp });
    }
  },
  setAutoLoading({ commit }: { commit: Commit }, val: any) {
    commit('SET_DATA_BY_KEY', { key: 'autoLoading', value: val });
  },
};
