<template>
  <div
    class="help-card pa-3"
    :style="style"
    ref="helpCardRef"
  >
    <div class="help-card__title px-3 py-2 semibold-16" :class="{ 'mb-3 help-card__title_underlined': content }">
      {{ title }}
    </div>
    <div
      v-if="isContentHTML"
      v-html="content"
      class="help-card__content regular-16 px-3"
    />
    <div
      v-else
      class="help-card__content px-3"
    >
      <component :is="content"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { HelpCardParams } from '@/shared/composables/useHelp';
import { computed, CSSProperties, ref } from 'vue';

const props = defineProps<HelpCardParams>();

const helpCardRef = ref<HTMLDivElement | null>(null);

const isContentHTML = computed<boolean>(() => typeof props.content === 'string');

const verticalAligning = computed<CSSProperties>(() => {
  if (props.align === 'bottom') {
    return { bottom: 0 };
  }
  if (props.align === 'middle') {
    if (helpCardRef.value) {
      return { top: `-${helpCardRef.value.clientHeight / 2}px` };
    }
    return { top: '-200px' };
  }
  return { top: '-1px' };
});

const style = computed<CSSProperties>(() => ({
  ...verticalAligning.value,
  left: props.position === 'right' ? 'calc(100% + 12px)' : 'unset',
  right: props.position === 'left' ? 'calc(100% + 12px)' : 'unset',
  top: props.position === 'under' ? 'calc(100% + 12px)' : 'unset',
}));
</script>

<style scoped>
.help-card {
  position: absolute;
  background: var(--bg-help);
  border-radius: 16px;
  border: 1px solid #c2c2c2;
  color: var(--text-secondary);
  min-width: 317px;
  z-index: 10000;
}
:deep(ul) {
  list-style-type: disc;
}
:deep(li) {
  margin-left: 30px;
}
:deep(p) {
  margin: 12px 0;
}
.help-card__title {
  color: var(--color-white);
}
.help-card__title_underlined {
  border-bottom: 1px solid var(--form-stroke);
}
:deep(.help-card__block) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
