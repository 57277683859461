import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-2 semibold-14 app-bar-language__desktop" }

import { computed, ref, watch, onMounted } from 'vue';
import { useUIStore } from './shared/composables/useUIStore';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouteQueryParams } from '@/router/useRouteQueryParams';
import ScIcon from '@/components/Common/ScIcon.vue';

interface LocaleItem {
  title: string;
  id: string;
  name: string;
}
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

;

const localeItems: LocaleItem[] = [
  { title: 'en', id: '1', name: 'English' },
  { title: 'ru', id: '4', name: 'Русский' },
];

const currentLocale = ref<LocaleItem>();

const store = useStore();
const userPreferences = computed(() => store.state.ui.userPreferences);
const isAuthenticated = computed(() => store.state.isAuthenticated);

const { uiDispatch, uiCommit } = useUIStore();
const { synchronizeQueryParams } = useRouteQueryParams();
synchronizeQueryParams();

const token = localStorage.getItem('token');

  if (token) {
    store.commit('SET_DATA_BY_KEY', {
      key: 'isAuthenticated',
      value: true,
    });
  }

const { locale } = useI18n();

const selectLanguage = (item: LocaleItem): void => {
  currentLocale.value = item;
  locale.value = item.title;
  if (!isAuthenticated.value) {
      uiCommit('SET_DATA_BY_KEY', {
        key: 'userPreferences',
        value: { lang_interface: Number(item.id) },
      });
      localStorage.setItem('savedLocale', JSON.stringify(item));
    } else {
      localStorage.setItem('savedLocale', JSON.stringify(item));
      uiDispatch('setUserPreferences', {
        ...userPreferences.value,
        lang_interface: Number(item.id),
      });
    }
};

const savedLocale = localStorage.getItem('savedLocale');

if (savedLocale) {
  try {
    const locale = JSON.parse(savedLocale);
    selectLanguage(locale);
  } catch (error) {
    console.log(error);
  }
} else {
  currentLocale.value = localeItems[0];
}

watch(userPreferences, (val) => {
  if (val && val.lang_interface !== undefined) {
    const targ = localeItems.find(
      (i) => Number(i.id) === val.lang_interface,
    );
    if (targ) {
      currentLocale.value = targ;
      locale.value = targ.title;
      localStorage.setItem('savedLocale', JSON.stringify(targ));
    }
  }
},
  { immediate: true },
);

watch(currentLocale, (val) => {
  document.documentElement.setAttribute('lang', val?.title ?? 'en');
});

const setScreenSizes = (): void => {
  store.commit('SET_DATA_BY_KEY', {
        key: 'sizes',
        value: {
        vh:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
        vw:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      },
  });
};

onMounted(() => {
  setScreenSizes();
  window.addEventListener('resize', setScreenSizes);
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_portal = _resolveComponent("portal")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "bg-darkGrey" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(_component_portal, { to: "app-bar-portal-language" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_menu, { class: "regular-14" }, {
            activator: _withCtx(({ props }) => [
              _createElementVNode("div", _mergeProps({ class: "app-bar-language-button d-flex align-center pointer" }, props), [
                _createElementVNode("div", _hoisted_1, _toDisplayString(currentLocale.value?.name), 1),
                _createVNode(ScIcon, {
                  class: "app-bar-language__desktop",
                  id: "expandSmall",
                  width: 20,
                  height: 20
                }),
                _createVNode(ScIcon, {
                  class: "app-bar-language__mobile",
                  id: "world"
                })
              ], 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list, {
                density: "compact",
                class: "regular-14"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(localeItems, (item) => {
                    return _createVNode(_component_v_list_item, {
                      key: item.id,
                      onClick: ($event: any) => (selectLanguage(item))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_content, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  }), 64))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})