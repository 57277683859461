import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

import { computed, CSSProperties } from 'vue';
import { ScButtonProps } from './ScButton.types';
// import ScLoader from '@/components/Common/ScLoader';
import ScIcon from '@/components/Common/ScIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScButton',
  props: {
    iconOnly: { type: Boolean },
    iconOnlyTertiary: { type: Boolean },
    tooltip: {},
    label: {},
    color: { default: 'primary' },
    icon: {},
    textOnly: { type: Boolean },
    loading: { type: Boolean },
    rounded: { type: Boolean },
    noHover: { type: Boolean },
    size: { default: 'medium' },
    prependIcon: {},
    appendIcon: {},
    disabled: { type: Boolean },
    width: {},
    fullWidth: { type: Boolean }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "4e1955f8": (buttonStyles.value.color),
  "362347f6": (buttonStyles.value.hoverBg),
  "3a79589e": (buttonStyles.value.hoverText),
  "b390f496": (buttonMinWidth.value)
}))

const props = __props;

const buttonVariant = computed<string>(() => {
  return props.iconOnly
    ? props.iconOnlyTertiary
      ? 'text'
      : 'flat'
    : props.textOnly
    ? 'plain'
    : 'flat';
});

const buttonMinWidth = computed<string>(() => {
  if (props.iconOnly) {
    if (props.size && props.size === 'small') {
      return '36px';
    }
    return '48px';
  }
  return 'auto';
});

const iconSize = computed(() => {
  if (props.iconOnly && props.size === 'medium') {
    return 24;
  }
  if (props.textOnly && props.size === 'small') {
    return 16;
  }
  return 20;
});

const styleWidthPadding = computed<CSSProperties>(() => {
  if (props.textOnly) {
    return {
      width: props.fullWidth ? '100%' : props.width ? props.width : '',
    };
  }

  const basePadding = props.size === 'small' ? '6px' : '12px';
  const horizontalPadding = props.size === 'small' ? '12px' : '24px';

  if (props.rounded) {
    return {
      width: '32px',
      height: '32px',
      padding: basePadding,
      borderRadius: '50%',
    };
  }

  return {
    width: props.fullWidth ? '100%' : props.width ? props.width : '',
    padding: props.iconOnly
      ? basePadding
      : `${basePadding} ${horizontalPadding}`,
  };
});

const buttonStyles = computed(() => {
  let color = '';
  let hoverBg = '';
  let hoverText = '';

  if (props.textOnly) {
    hoverText = `rgb(var(--v-theme-primary-hover))`;

    switch (props.color) {
      case 'button-secondary':
        color = `rgb(var(--v-theme-text-primary))`;
        break;
      case 'primary':
        color = `rgb(var(--v-theme-primary))`;
        break;
      case 'white':
        color = `rgb(var(--v-theme-white))`;
        break;
      case 'secondary':
        color = `rgb(var(--v-theme-secondary))`;
        break;
    }
  } else {
    switch (props.color) {
      case 'primary':
        hoverBg = props.iconOnlyTertiary
          ? `rgb(var(--v-theme-button-hover))`
          : `rgb(var(--v-theme-primary-hover))`;
        break;
      case 'button-secondary':
        hoverBg = `rgb(var(--v-theme-button-hover))`;
        color = `rgb(var(--v-theme-on-button-secondary))`;
        break;
      case 'input-field':
        hoverBg = `rgb(var(--v-theme-tree-field))`;
        color = `rgb(var(--v-theme-text-primary))`;
        break;
      case 'violet':
        hoverBg = `rgb(var(--v-theme-violet-hover))`;
        color = `rgb(var(--v-theme-white))`;
        break;
    }
  }

  return { color, hoverBg, hoverText };
});

return (_ctx: any,_cache: any) => {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    class: _normalizeClass(["sc-button text-none", [
      { 'custom-disabled': _ctx.disabled && !_ctx.textOnly },
      { 'custom-disabled-text': _ctx.disabled && _ctx.textOnly },
      { 'no-hover': _ctx.noHover },
    ]]),
    style: _normalizeStyle(styleWidthPadding.value),
    disabled: _ctx.disabled,
    variant: buttonVariant.value,
    color: _ctx.color
  }, {
    default: _withCtx(() => [
      (!_ctx.iconOnly && _ctx.prependIcon && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["sc-button__prepend", [
        { 'mr-2': !_ctx.iconOnly && _ctx.size !== 'small' },
        { 'mr-icon-small': !_ctx.iconOnly && _ctx.size !== 'medium' },
      ]])
          }, [
            (_ctx.prependIcon && !_ctx.iconOnly)
              ? (_openBlock(), _createBlock(ScIcon, {
                  key: 0,
                  id: _ctx.prependIcon,
                  color: "currentColor",
                  width: iconSize.value,
                  height: iconSize.value
                }, null, 8, ["id", "width", "height"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.label && !_ctx.iconOnly)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["sc-button__text", _ctx.size === 'small' ? 'semibold-14' : 'semibold-16'])
          }, _toDisplayString(_ctx.label), 3))
        : _createCommentVNode("", true),
      (!_ctx.label)
        ? _renderSlot(_ctx.$slots, "default", { key: 2 })
        : _createCommentVNode("", true),
      (_ctx.loading || _ctx.appendIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["sc-button__append", [
        { 'ml-2': !_ctx.iconOnly && _ctx.size !== 'small' },
        { 'ml-icon-small': !_ctx.iconOnly && _ctx.size !== 'medium' },
      ]])
          }, [
            (_ctx.appendIcon || _ctx.icon)
              ? (_openBlock(), _createBlock(ScIcon, {
                  key: 0,
                  id: _ctx.icon ? _ctx.icon : _ctx.appendIcon,
                  color: "currentColor",
                  width: iconSize.value,
                  height: iconSize.value
                }, null, 8, ["id", "width", "height"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.tooltip)
        ? (_openBlock(), _createBlock(_component_v_tooltip, {
            key: 4,
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "style", "disabled", "variant", "color"]))
}
}

})