import { createI18n } from 'vue-i18n';

export const L10n = {
  "en": {
    "langid": 1,
    "login": {
      "signin": "Sign in",
      "signup": "Register",
      "remember": "Remember me",
      "main": "Login to continue",
      "name": "Name",
      "email": "Email",
      "password": "Password",
      "forgotPassword": "Forgot password?",
      "accept": "Login",
      "payment": "Join membership",
      "confirmPassword": "Confirm password",
      "phone": "Phone",
      "longPassword": "The password length must be at least 8 characters",
      "passwordComplexity": "The password must match uppercase and lowercase letters, numbers and special characters",
      "passwordsMatch": "The passwords entered in both passwords fields should match",
      "confirmRegister": "Complete registration"
    },
    "pages": {
      "main": "Persons and sources of games",
      "mistakes": "Positions of concern overview",
      "mistakesGoTo": "To the positions overview",
      "tree": "Tree of opening theory",
      "training": "Training"
    },
    "accountMenu": {
      "settings": "Settings",
      "preferences": "Preferences",
      "logoutall": "Log out of all devices",
      "logout": "Log out"
    },
    "common": {
      "index": "#",
      "progress": "/",
      "like": "Like",
      "copied": "Copied!",
      "feedback": "Feedback",
      "aboutCompany": "About the company",
      "footer": "2024 Science64 LLP. All rights reserved.",
      "fieldRequired": "This field is required",
      "ok": "OK",
      "cancel": "Cancel",
      "apply": "Apply",
      "reset": "Reset",
      "dateFrom": "Start date",
      "dateTo": "End date",
      "all": "all",
      "allCapital": "All"
    },
    "card": {
      "addPerson": "Add person",
      "editPerson": "Edit person",
      "removePerson": "Remove the person",
      "heroname": "Person name",
      "description": "Description"
    },
    "cardSummary": {
      "accounts": "Accounts",
      "editAccount": "Edit account",
      "addAccount": "Add account",
      "sources": "Analyzed games",
      "editSource": "Edit source of games",
      "addSource": "Add source of games",
      "loadGames": "Load games",
      "uploadButton": "Upload",
      "name": "Name",
      "gamesLoaded": "Games loaded",
      "analysisEnabled": "Analyze",
      "noGames": "No data",
      "totalGames": "Total games",
      "analysisQuery": "Analyze",
      "debutAnalysis": "Opening analyzed",
      "mittelspielAnalysis": "Middlegame analyzed",
      "endspielAnalysis": "Endgame analyzed",
      "nicknameNotFound": "Nickname not found",
    },
    "playground": {
      "site": "Source",
      "pg1": "Correspondence games",
      "pg2": "Over the board",
      "pg3": "Lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "Classical",
      "rapid": "Rapid",
      "blitz": "Blitz",
      "bullet": "Bullet"
    },
    "opening1": {
      "colourgauge": "Colour gauge",
      "avgWaste": "Average downgrade",
      "showOnTree": "Show on the tree",
      "showHeader": "Status of displaying",
      "showYes": "Show",
      "showNo": "Hide",
      "showFilterHint": "Based on filter options",
      "setByDefault": "Discard changes",
      "excluded": "Excluded by filter options",
      "legend": "Legend",
      "selectAnother": "Select another position to explore",
      "toTree": "Explore on the tree",
      "toTraining": "Go to training",
      "tree": "Tree chart",
      "list": "Simple list",
      "openingsOpen": "Open Games",
      "openingsSemiOpen": "Semi-Open Games",
      "openingsClosed": "Closed Games",
      "openingsSemiClosed": "Semi-Closed Games",
      "openingsIndian": "Indian Defences",
      "openingsFlank": "Flank and irregular openings",
      "headerBeforeHeroname": "Opening research: ",
      "headerAfterHeroname": "",
      "weak": "POSITIONS OF CONCERN OVERVIEW",
      "filters": "Filters",
      "searchStart": "Start",
      "searchStartHint": "Initial position",
      "search": "Show this",
      "searchHint": "Show tree from position given by FEN",
      "searchAfter": "Mistakes after position",
      "searchAfterHint": "List of problematic positions in games after position given by FEN",
      "searchFieldPrompt": "Paste FEN here",
      "side": "Side",
      "sideWhite": "White",
      "sideBlack": "Black",
      "studied": "Studied",
      "studiedYes": "Yes",
      "studiedNo": "No",
      "setStudied": "Mark as studied",
      "watchlist": "Watchlist",
      "watchlistListed": "Listed",
      "moveNum": "Moves range",
      "moveNumFrom": "Min",
      "moveNumTo": "Max",
      "opening": "Opening",
      "sortBy": "Sort by",
      "sortByTotalShortage": "Total shortage at position",
      "sortByAverageShortage": "Average shortage at position",
      "sortByNumMistakes": "Number of mistakes made",
      "sortByMoveDesc": "Move number descending",
      "sortByMoveAsc": "Move number ascending",
      "compareWithBeforeHeroname": "Compare ",
      "compareWithAfterHeroname": " with: ",
      "mistakesNotFound": "Problematic positions not found",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "Most common ECO in games where this position has arose",
      "mistakesdEval": "ΔEval",
      "mistakesdEvalLong": "Downgrade",
      "mistakesdEvalLong2": "Eval downgrade",
      "mistakesdEvalHint": "Average shortage of evaluation in pawns value",
      "mistakesNum": "Num",
      "mistakesNumLong": "Number of mistakes",
      "mistakesNumHint": "Number of games (number of mistakes made)",
      "mistakesTotalShortage": "Total shortage",
      "mistakesTotalShortage2": "Shortage in total",
      "mistakesTotalShortageHint": "Total shortage due to mistakes",
      "mistakesStudiedStatus": "Position status",
      "mistakesStudiedStatusHint": "\"Studied\" state at position",
      "mistakesNotStudied": "Not studied",
      "mistakesNotStudiedHint": "",
      "mistakesStudied": "Studied",
      "mistakesStudiedHint": "",
      "mistakesReFail": "Fault again",
      "mistakesReFailSingle": "Fault again",
      "mistakesReFailHint": "Mistake was made again after marking as studied",
      "mistakesListFilterLabel": "Listed",
      "mistakesListFilterAll": "All positions",
      "mistakesListFilterRecommended": "Recommended",
      "mistakesListFilterStudyPlan": "Study plan",
      "mistakesListFilterFavorites": "Favorites",
      "inProgress": "In progress",
      "barMode": "Bars",
      "barModeResults": "game results",
      "barModeDecisions": "decisions",
      "displayMoves": "Displayed variations",
      "displayMovesBestOnly": "regular list",
      "displayMovesExtended": "extended list",
      "cTO": "Training offers",
      "tOIgnore": "don't show",
      "tOBest": "single best",
      "tOAll": "all",
      "cPractice": "Encountered in the games",
      "practiceIgnore": "don't show",
      "practiceHero": "hero's turn",
      "practiceAll": "all",
      "cEmphasis": "Other moves",
      "emphasisIgnore": "don't show",
      "hintemphasisIgnore": "Disable showing the additional moves",
      "emphasis3": "single best",
      "hintemphasis3": "Exactly one move from among the strongest",
      "emphasis2": "good",
      "hintemphasis2": "Quality moves",
      "emphasis1": "extended list",
      "hintemphasis1": "All theoretical moves",
      "flipBoard": "Flip board",
      "switchEngine": "Stockfish",
      "addToFavorites": "Add to favorites",
      "removeFromFavorites": "Remove from favorites",
      "addToStudyPlan": "Add to study plan",
      "removeFromStudyPlan": "Remove from study plan",
      "ignore": "Ignore",
      "prehistory": "Prehistory"
    },
    "opening2": {
      "tooSmallTree": "Task not created: current training tree is too small",
      "speed": "Speed control",
      "autoplayDelay": "Autoplay delay (sec.)",
      "arrowDelay": "Arrow delay (sec.)",
      "reps": "Number of reps",
      "leading": "Repeat moves",
      "testing": "Check of knowledge",
      "pageLearn": "Learn",
      "pageTest": "Test",
      "buttonStart": "Start",
      "buttonStartLearn": "Start learning",
      "buttonStartTraining": "Start testing",
      "buttonAbort": "Stop",
      "buttonReset": "Reset",
      "passingParams": "Params of training",
      "toStart": "Start again",
      "nextVariation": "Next variation",
      "labelCorrect": "Correct",
      "labelWrong": "Inaccurate",
      "buttonStartTest": "Start test",
      "learningMessages": {
        "beginTitle": "Let's start the practice.",
        "beginDescription": "Go through the suggested lines with guidance, adjusting the number of repetitions if needed.",
        "firstVariantTitle": "Moving to the first line.",
        "firstVariantDescription": "Make your move, following the hints.",
        "learnedVariantTitle": "Line has been fully learned!",
        "learnedVariantDescription": "Go to the next line  or press 'Repeat' to reinforce the current one.",
        "newVariantTitle": "Moving to a new line. ",
        "newVariantDescription": "You've well memorized the opening moves, so now they are being played automatically.",
        "noVariantsTitle": "You’ve successfully covered all the suggested lines!",
        "noVariantsDescription": "Go to Test mode to reinforce the result ."
      }
    },
    "compare": {
      "separator1": ", ",
      "separator2": ", ",
      "separator3": " ",
      "title": "Comparison selector",
      "aveloRange": "Rating range",
      "timeControl": "Time control",
      "rivals": "Rivals skill comparison",
      "pg1": "Correspondence games",
      "pg2": "Over the board",
      "pg2Short": "OTB",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "strong amateurs",
      "avelo2": "masters",
      "avelo3": "top",
      "tc2_2": "Classical",
      "tc2_3": "Rapid",
      "tc2_4": "Blitz",
      "tc3_3": "classical, rapid",
      "tc3_4": "blitz",
      "ed4": "rivals are equal",
      "ed345": "rivals are comparable",
      "ed23456": "any",
      "headered4": " (equal)",
      "headered345": " (comparable)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "Number of games",
      "numberOfGamesShort": "Games",
      "stat": "Statistics",
      "variations": "Variations",
      "resultsPerc": "Results, %",
      "decisonsPerc": "Decisions, %",
      "move": "Move",
      "eval": "Eval",
      "moveEval": "Move (eval)",
      "frequency": "Freq.",
      "numberOfMoveMade": "Num",
      "controlGroup": "Control group",
      "playerStatistics": "Player statistics"
    },
    "feedback": {
      "title": "Please describe your problem as detailed as possible",
      "description": "Description of the problem"
    },
    "settings": {
      "displayedName": "Displayed name"
    },
    "preferences": {
      "piecesDisplayedAs": "Pieces displayed as",
      "shortNotation": "Use short notation",
      "flipWhenBlack": "Flip board when black moves at initial position",
      "mistakesSensivity": "Mistakes sensitivity"
    },
    "moveMarking": {
      "lbHero": "Training",
      "hbydefault": "by default",
      "hchoice": "choice",
      "hignore": "ignore",
      "lbOpp": "Training",
      "obydefault": "by default",
      "ochoice": "consider",
      "oignore": "ignore"
    },
    "pieces": {
      "N": "N",
      "B": "B",
      "R": "R",
      "Q": "Q",
      "K": "K"
    },
    "loginPage": {
      "signin": "Log in", 
      "signup": "Sign Up",
      "enterEmail": "Enter your email",
      "enterPassword": "Enter your password",
      "confirmRegister": "Sign up",
      "dontHaveAccount": "Don’t have an account?",
      "logInViaGoogle": "Log in with Google"
    },
    "register": {
      "signup": "Sign Up",
      "signupGoogle": "Sign up with Google",
      "title": "Sign up",
      "enterEmail": "Email",
      "enterName": "Your name",
      "enterPhone": "Phone number (optional)",
      "enterPassword": "Create a password",
      "confirmPassword": "Confirm your password",
      "alreadyHaveAccout": "Already have an account?",
      "tip": "Password must be at least 8 characters long, include mixed case letters, numbers, and a special character",
      "agreement": "I give my consent to the processing of my personal data and agree to the ",
      "agreementErrorText": "Please accept the Terms and Conditions to proceed.",
      "privacy": "Privacy policy",
      "and": " and ",
      "terms": "Terms of use", 
      "confirmationTitle": "Almost done!",
      "confirmation": {
        "title": "Confirm your e-mail",
        "description": "An email with a confirmation link has been sent to {email}",
        "secondDescription": "Check your e-mail and follow instructions",
        "resendLinkButton": "Send the link again",
        "backButton": "Return to Home page"
      },
      "promocode": {
        "title": "Activate the promo code",
        "description": "Our service is under development, but you have already taken the first step. Enter the received promo code to get a discount and provide your batch details so that we can start analyzing immediately after launch",
        "profileLabel": "Your profile on the chess platform",
        "promocodePlaceholder": "Promo code",
        "usernamePlaceholder": "User name",
        "saveButton": "Save",
        "proceedButton": "Continue without promo code"
      },
      "successDiscount": {
        "title": "Promo code activated!",
        "description": "Great, you're now eligible for a free trial",
        "description2": "We will notify you by email as soon as the service is ready to launch",
        "description3": "Thank you for being with us!",
        "closeButton": "Close"
      },
      "successRegister": {
        "title": "Registration successful!",
        "description": "Your account has been registered"
      },
      "replacement": {
        "title": "Thank you for being with us!",
        "description": "Thank you for your interest in our chess service",
        "description2": "We will notify you by email as soon as the service is ready to launch"
      },
      "afterLogin": {
        "title": "The web-site is under development"
      },
      "confirmationText": "To complete your registration, please confirm your email address by clicking the link in the email sent to"
    },
    "registerConfirmation": {
      "title": "Almost done!",
      "text": "To complete your registration, please confirm your email address by clicking the link in the email sent to",
      "incorrectEmail": "Incorrect email?",
      "backToSign": "Back to Sign Up form",
      "noMail": "Didn’t receive confirmation email?",
      "resend": "Resend confirmation link",
      "problem": "Problem occured", 
      "expired": "This confirmation link has expired",
      "successTitle": "Your email has been successfully confirmed",
      "successText": "You can now use all the features of our service",
      "login": "Log in"
    },
    "recovery": {
      "title": "Forgot password?",
      "enterEmail": "Enter your email",
      "submit": "Send request",
      "returnTo": "Remembered password?",
      "login": "Log in"
    },
    "recoveryConfirmation": {
      "title": "Check your mail box",
      "text": "Password recovery instructions were sent to ",
      "continue": "Continue"
    },
    "changePassword": {
      "title": "Change password",
      "enterPassword": "Create new password",
      "confirmPassword": "Confirm new password",
      "submit": "Save changes",
      "tip": "New password must be different from the previous one and contain at least 8 characters long",
      "expired": "This confirmation link has expired",
      "problem": "Problem occured",
      "resend": "Resend",
      "success": "Password has been successfully changed",
      "login": "Log in"
    },
    "rules": {
      "confirmpwd": "Please confirm your password",
      "dontMatch": "Passwords do not match",
      "enterEmail": "Enter your email",
      "enterPwd": "Enter your password",
      "enterName": "Enter your name",
      "fieldRequired": "This field is required",
      "emailMin": "Email must be at least ",
      "pwdMin": "Password must be at least ",
      "nameMin": "Name must be at least ",
      "phoneMin": "Phone number must be at least ",
      "phoneMax": "Phone number must be no more than ",
      "phoneInvalid": "Phone number contains invalid characters",
      "valueMin": "Value must be at least ",
      "minPostfix": " characters",
      "pwdUpper": "Password must contain at least one uppercase letter",
      "pwdNumber": "Password must contain at least one digit",
      "wrongPassword": "Incorrect password",
      "incorrectEmail": "Incorrect email address",
      "emailNotRegistered": "Specified email address not registered",
      "getName": "How should we address you?"
    },
    "help": {
    "preAnalysis": {
      "userColor": "Color played by the user",
      "moveRange": "Move range where a critical position occurred",
      "avgMistakeSeverity": "Average mistake severity (in pawns)",
      "openingName": "Opening name (optional)",
      "displayResultsFor": "Display results for:",
      "allCriticalPositions": "All critical positions",
      "favorites": "Favorites",
      "trainingPlan": "Training plan",
      "demonstrationMode": "Demonstration mode of critical positions:",
      "treeView": "In the form of a tree, demonstrating the hierarchy and mutual arrangement of critical positions.",
      "listView": "List view",
      "leftClickOptions": "By left-clicking on a node, you can add a position to «Training Plan», «Favorites», or «Ignored».",
      "planTraining": "Training plan",
      "ignored": "Ignored",
      "gameStart": "Game start",
      "transitionBetweenPositions": "Transition from one critical position to another, may contain any number of moves.",
      "criticalPositions": "Critical positions. Hovering over them displays the position, average evaluation deterioration, number of potential mistakes, and total losses.",
      "fenFormat": "Position in FEN format, you can copy the current board position.",
      "treeViewAlternatives": "View variations in a tree format, automatically suggesting alternatives that can be adjusted.",
      "navigateAnalysis": "Navigate to the analysis page.",
      "directTraining": "Skip review and variation adjustments to directly train on the suggested alternatives.",
      "navigateTraining": "Navigate to the training page.",
      "positionVariants": "Move variations in the current position:",
      "recommendedMove": "Top move - recommended",
      "userMoves": "Below - moves played by the user.",
      "moveDetails": "Displays the move itself, evaluation after the move, number of times it was played, and total losses due to this move in analyzed games.",
      "trainingPlanFavorites": "Auxiliary lists ‘Training Plan’ and ‘Favorites’, where critical positions (shown in a tree or list) are stored. These lists are available on all training pages and help in adjusting the training plan.",
      "addToLists": "The selected critical position can be added to:",
      "filterBlock": {
        "criticalPositions": "Critical Positions Filter Block",
        "chooseOptions": "You can choose:",
        "colorPlayed": "Color played by the user",
        "moveRange": "Move range where a critical position occurred",
        "errorSeverity": "Average mistake severity (in pawns)",
        "openingName": "Opening name (optional)",
        "displayResultsFor": "Display results for:",
        "allCriticalPositions": "All critical positions",
        "favorites": "Favorites",
        "trainingPlan": "Training plan"
      },
      "mainPage": {
        "accountNameTitle": "Account name in the service",
        "accountName": "Created during registration and serves as an identifier.",
        "accountNameExit": "Logout button (used for logging out).",
        "addNewPlayerTitle": "Adding a new analyzed player",
        "addNewPlayer": "To analyze games, you need to create a user to which games from chess platforms or over-the-board games will be uploaded.",
        "addNewPlayerAdditional": "You need to enter a name and (optionally) a description.",
        "analyzedUserTitle": "Analyzed user",
        "analyzedUser": "Includes nicknames from selected chess platforms. Clicking the 'Pencil' icon allows you to make changes.",
        "gameAdditionMenuTitle": "Game addition menu for analysis",
        "gameAdditionMenu": "You need to enter the username, select the chess platform, and click 'OK'.",
        "nicknameActivationTitle": "Nickname activation",
        "nicknameActivation": "If the nickname has already been added, its games can be included or excluded from the current analysis.",
        "analyzedNicknamesListTitle": "List of analyzed nicknames",
        "analyzedNicknamesList": "Contains all nicknames available for analysis.",
        "gameSourceTitle": "Game source",
        "gameSource": "Contains information about the game source.",
        "timeControlsMenuTitle": "Time control menu",
        "timeControlsMenu": "Selection of time controls for analyzed games.",
        "planOptionsTitle": "Plan change options",
        "criticalPositionsOverviewTitle": "Critical positions overview",
        "criticalPositionsOverview": "After selecting analyzed nicknames and time controls, you can proceed to reviewing critical positions.",
        "navigationTitle": "Page navigation",
        "navigation": "Contains links to the following pages:",
        "navigation1": "Data loading and preparation;",
        "navigation2": "Position selection for analysis and correction;",
        "navigation3": "Variant analysis;",
        "navigation4": "Training.",
        "feedbackFormTitle": "Feedback form",
        "feedbackForm": "Offers a chat option or message submission with the option to attach a screenshot."
      },
        "analysisPage": {
          "controlGroupMenuTitle": "Control Group Menu for Comparison",
          "controlGroupMenu": "You can choose:",
          "controlGroupMenu1": "Game source;",
          "controlGroupMenu2": "Player rating group;",
          "controlGroupMenu3": "Time control.",
          "positionSelectionTitle": "Position Selection for Detailed Analysis",
          "positionSelection": "There are 3 lists:",
          "positionSelection1": "All critical positions;",
          "positionSelection2": "Training plan;",
          "positionSelection3": "Favorites.",
          "positionSelection4": "'Evaluation delta' - contains information about the average error value.",
          "positionNodeTitle": "Node Representing a Position",
          "positionNode": "Contains the move that led to the position and its evaluation. May have different colors.",
          "positionNode1": "Gray - one of many possible moves.",
          "positionNode2": "Blue - a position suggested for training where the opponent's turn is next.",
          "positionNode3": "Purple - a position suggested for training where the user's turn is next.",
          "moveEdgeTitle": "Edge - Represents a Move Leading to Another Position",
          "moveEdge": "The color indicates the estimated move strength. The closer the color is to green, the stronger the move; closer to red indicates a weaker move.",
          "moveEdge1": "The number above the edge shows how many times the move was made in the user's analyzed games.",
          "branchCorrectionToolTitle": "'Sandwich'",
          "branchCorrectionTool": "A tool for adjusting branch display after a specific position.",
          "branchCorrectionTool1": "Contains 0 to 3 lines representing the number of hidden moves and the strength of the top three moves.",
          "branchCorrectionTool2": "Clicking opens the display settings menu.",
          "branchCorrectionTool3": "All considered moves for the position are listed.",
          "branchCorrectionTool4": "In the menu, you can choose to show or hide moves regardless of the general settings or follow set filters. After configuration, click the cross or any free area on the screen.",
          "nextMovesToolTitle": "Tool for Expanding Subsequent Moves in the Variation",
          "nextMovesTool": "After clicking, the sign will change to",
          "nextMovesTool1": "and further variations will be shown on the right.",
          "nextMovesTool2": "Before:",
          "nextMovesTool3": "After:",
          "massExpandMovesToolTitle": "Tool for Mass Expanding of Subsequent Moves",
          "massExpandMovesTool": "Used to expand a variation up to 5 moves ahead, showing possible alternatives at each move.",
          "massExpandMovesTool1": "Before:",
          "massExpandMovesTool2": "After:",
          "moveNumberingTitle": "Move Numbering from Game Start",
          "moveNumbering": "The move number is shown for positions in the column:",
          "moveNumbering1": "With a dot - White's move;",
          "moveNumbering2": "With an ellipsis - Black's move.",
          "variationFilterTitle": "Variation Display Filter",
          "variationFilter": "Display variations in the tree using 3 filters that can be added or removed:",
          "variationFilter1": "Proposals for alternative training variations:",
          "variationFilter2": "Only the best continuation or multiple equally strong ones.",
          "variationFilter3": "Moves that occurred in games:",
          "variationFilter4": "Moves made by the analyzed player;",
          "variationFilter5": "Moves made by both the player and their opponents.",
          "variationFilter6": "Alternative moves not included in the suggested training moves:",
          "variationFilter7": "'One Best';",
          "variationFilter8": "'All Good Moves';",
          "variationFilter9": "'Extended List'.",
          "trainingPositionsTitle": "Demonstration of Moves/Positions Suggested for Training",
          "trainingPositions": "Displays a tree of suggested training moves (may take up to 30 seconds to load).",
          "trainingPositions1": "Before:",
          "trainingPositions2": "After:",
          "statisticsBlockTitle": "Statistics Block",
          "statisticsBlock": "The statistics for the selected position include:",
          "statisticsBlock1": "Top block:",
          "statisticsBlock2": "Number of games where the position occurred for the player;",
          "statisticsBlock3": "% of wins/draws/losses;",
          "statisticsBlock4": "% of correct/doubtful decisions.",
          "statisticsBlock5": "Bottom block:",
          "statisticsBlock6": "Number of games in the selected control group where the position occurred;",
          "statisticsBlock7": "% of wins/draws/losses;",
          "statisticsBlock8": "% of correct/doubtful decisions.",
          "positionVariationsTitle": "Variations in the Selected Position",
          "positionVariations": "Frequency of control group moves for the given position.",
          "addRemovePositionTitle": "Add/Remove Position for Training Variations",
          "bookmarkFunctionTitle": "Bookmark Function for Quick Navigation",
          "bookmarkFunction": "You can activate a position by clicking it or selecting the designated icon below the board. This will add a bookmark that allows quick access to the position in the tree via click.",
          "bookmarkFunction1": "Bookmark with position preview.",
          "bookmarkFunction2": "Click to jump to the position.",
          "hideBoardStatsTitle": "Hide Board and Statistics Feature",
          "hideBoardStats": "Used to free space for tree nodes.",
          "criticalPositionsPageTitle": "Critical Positions Selection Page for Analysis",
          "treeScaleTitle": "Tree Scale",
          "treeScale": "Affects only the size of tree elements.",
          "stockfishAnalysisTitle": "Stockfish Analysis",
          "stockfishAnalysis": "For the selected board position, you can enable real-time engine analysis.",
          "elementsDisplaySettingsTitle": "Element Display Settings",
          "elementsDisplaySettings": "Allows changing piece styles, board orientation, and node appearance.",
          "trainingPageTitle": "Training Page Navigation",
          "trainingPage": "You will be prompted to enter the selected training moves with hints. After memorizing the options, you will be offered a test.",
      },
      "exercisePage": {
        "positionNodeExerciseTitle": "Node representing the position reached after the specified move",
        "positionNodeExercise": "My move",
        "positionNodeExercise1": "Opponent's move",
        "positionNodeExercise2": "Test results",
        "positionNodeExercise3": "Success",
        "positionNodeExercise4": "Failure",
        "positionNodeExercise5": "Not completed",
        "moveEdgeExerciseTitle": "Edge representing the connection between positions",
        "moveEdgeExercise": "Indicates how many times the move was made during training out of the planned moves.",
        "trainingMovesCountTitle": "Number of moves included in the training",
        "branchSelectionInfoTitle": "Information and selection of a specific branch for retraining",
        "trainingSettingsTitle": "Training settings",
        "trainingSettings": "Can be customized:",
        "trainingSettings1": "Number of repetitions for all branches;",
        "trainingSettings2": "Speed of automatic playback of already covered variant segments;",
        "trainingSettings3": "Delay in hint demonstration to allow independent guesswork for the best move (enhances learning efficiency through better memorization).",
        "modeSelectionTitle": "Mode selection",
        "modeSelection": "In learning mode, you will be prompted to enter moves using hints;",
        "modeSelection1": "In test mode — enter the chosen continuations without hints, simulating real gameplay.",
        "startTrainingButtonTitle": "Button to start training",
        "currentStateWindowTitle": "Current state window"
      }
    }
  },
    "card2": {
      "heronamePlaceholder": "State the name of the individual",
      "descriptionPlaceholder": "Provide a description of the individual (optional)",
    },
    "cardSummary2": {
      "nameUser": "User Name",
      "nameUserPlaceholder": "Enter your user name",
      "platform": "Platform",
      "platformPlaceholder": "Select a platform from the list",
      "plarformSelectBottom": "To analyze batches from other venues available in .pgn format, please contact the support team",
      "cardTitlePositionTree": "Critical positions tree",
      "cardTitleListTree": "List of critical items"
    },
    "analysisPage2": {
      "hide" : "Hide",
      "show": "Show",
      "removeBookmarks": "Remove from bookmarks",
      "addBookmarks": "Add to bookmarks",
      "addPractice": "Add to practice",
      "removePractice": "Remove from practice"
    }
  },
  "ru": {
    "langid": 4,
    "login": {
      "signin": "Вход",
      "signup": "Регистрация",
      "remember": "Запомнить меня",
      "main": "Войдите, чтобы продолжить",
      "name": "Имя",
      "email": "Адрес электронной почты",
      "password": "Пароль",
      "forgotPassword": "Забыли пароль?",
      "accept": "Войти",
      "payment": "Оформите подписку",
      "confirmPassword": "Подтверждение пароля",
      "phone": "Телефон",
      "longPassword": "Длина пароля должна быть не менее 8 символов",
      "passwordComplexity": "Пароль должен содержать строчные и заглавные буквы, цифры и специальные символы",
      "passwordsMatch": "Пароли должны совпадать",
      "confirmRegister": "Зарегистрироваться"
    },
    "pages": {
      "main": "Личности и источники партий",
      "mistakes": "Обзор проблемных позиций",
      "mistakesGoTo": "К обзору позиций",
      "tree": "Дерево дебютной теории",
      "training": "Тренировка"
    },
    "accountMenu": {
      "settings": "Настройки",
      "preferences": "Установки",
      "logoutall": "Выйти со всех устройств",
      "logout": "Выйти"
    },
    "common": {
      "index": "№",
      "progress": " из ",
      "like": "Нравится",
      "copied": "Скопировано!",
      "feedback": "Обратная связь",
      "aboutCompany": "О компании",
      "footer": "2024 Science64 LLP. All rights reserved.",
      "fieldRequired": "Поле обязательно для заполнения",
      "ok": "OK",
      "cancel": "Отменить",
      "apply": "Применить",
      "reset": "Сбросить",
      "dateFrom": "Начальная дата",
      "dateTo": "Конечная дата",
      "all": "все",
      "allCapital": "Все"
    },
    "card": {
      "addPerson": "Добавить личность",
      "editPerson": "Редактировать личность",
      "removePerson": "Удалить личность",
      "heroname": "Имя личности",
      "description": "Описание"
    },
    "cardSummary": {
      "accounts": "Аккаунты",
      "editAccount": "Редактировать аккаунт",
      "addAccount": "Добавить аккаунт",
      "sources": "Анализируемые партии",
      "editSource": "Редактировать источник партий",
      "addSource": "Добавить источник партий",
      "loadGames": "Загрузить партии",
      "uploadButton": "Загрузить",
      "name": "Имя",
      "gamesLoaded": "Загружено партий",
      "analysisEnabled": "Анализировать",
      "noGames": "Нет данных",
      "totalGames": "Всего партий",
      "analysisQuery": "Анализировать",
      "debutAnalysis": "Анализ дебюта",
      "mittelspielAnalysis": "Анализ миттельшпиля",
      "endspielAnalysis": "Анализ эндшпиля",
      "nicknameNotFound": "Никнейм не найден",
    },
    "playground": {
      "site": "Источник",
      "pg1": "Заочные партии",
      "pg2": "Игра вживую",
      "pg3": "Lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "Классика",
      "rapid": "Быстрые",
      "blitz": "Блиц",
      "bullet": "Пуля"
    },
    "opening1": {
      "colourgauge": "Цветовая шкала",
      "avgWaste": "Ухудшение в среднем",
      "showOnTree": "Показывать на дереве",
      "showHeader": "Показывать",
      "showYes": "Да",
      "showNo": "Нет",
      "showFilterHint": "Согласно настроек фильтров",
      "setByDefault": "Отменить изменения",
      "excluded": "Не подходящие по фильтрам",
      "legend": "Легенда",
      "selectAnother": "Выбрать другую позицию для изучения",
      "toTree": "Разобрать на дереве",
      "toTraining": "Перейти к тренировке",
      "tree": "Дерево",
      "list": "Cписок",
      "openingsOpen": "Открытые дебюты",
      "openingsSemiOpen": "Полуоткрытые дебюты",
      "openingsClosed": "Закрытые дебюты",
      "openingsSemiClosed": "Полузакрытые дебюты",
      "openingsIndian": "Индийские защиты",
      "openingsFlank": "Фланговые дебюты и неправильные начала",
      "headerBeforeHeroname": "Исследование дебюта: ",
      "headerAfterHeroname": "",
      "weak": "ОБЗОР ПРОБЛЕМНЫХ ПОЗИЦИЙ",
      "filters": "Фильтры",
      "searchStart": "Старт",
      "searchStartHint": "Начальная позиция",
      "search": "Показать",
      "searchHint": "Отобразить варианты, начиная с заданной через FEN позиции",
      "searchAfter": "Ошибки в партиях с позицией",
      "searchAfterHint": "Перечень проблемных позиций, возникавших в тех партиях, где встречалась заданная через FEN позиция",
      "searchFieldPrompt": "Вставьте FEN сюда",
      "side": "Сторона",
      "sideWhite": "Белые",
      "sideBlack": "Чёрные",
      "studied": "Изучено",
      "studiedYes": "Да",
      "studiedNo": "Нет",
      "setStudied": "Отметить как изученное",
      "watchlist": "Наблюд.",
      "watchlistListed": "В списке",
      "moveNum": "Диапазон ходов",
      "moveNumFrom": "Мин",
      "moveNumTo": "Макс",
      "opening": "Дебют",
      "sortBy": "Сортировка",
      "sortByTotalShortage": "Сумма потерь в позиции",
      "sortByAverageShortage": "Средние потери в позиции",
      "sortByNumMistakes": "Количество совершённых ошибок",
      "sortByMoveDesc": "Номер хода по убыванию",
      "sortByMoveAsc": "Номер хода по возрастанию",
      "compareWithBeforeHeroname": "Сравнить ",
      "compareWithAfterHeroname": " против: ",
      "mistakesNotFound": "Проблемные позиции не найдены",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "ECO большинства партий, где встречалась эта позиция",
      "mistakesdEval": "Дельта оценки",
      "mistakesdEvalLong": "Ухудшение",
      "mistakesdEvalLong2": "Ухудшение оценки",
      "mistakesdEvalHint": "Средние потери оценки позиции в количестве пешек",
      "mistakesNum": "Всего ошибок",
      "mistakesNumLong": "Количество ошибок",
      "mistakesNumHint": "Количество партий (количество совершённых ошибок)",
      "mistakesTotalShortage": "Общий ущерб",
      "mistakesTotalShortage2": "Суммарные потери",
      "mistakesTotalShortageHint": "Общая сумма потерь из-за совершённых в позиции ошибок",
      "mistakesStudiedStatus": "Статус изучения",
      "mistakesStudiedStatusHint": "Состояние изученности позиции",
      "mistakesNotStudied": "Не изучено",
      "mistakesNotStudiedHint": "",
      "mistakesStudied": "Изучено",
      "mistakesStudiedHint": "",
      "mistakesReFail": "Вновь ошибки",
      "mistakesReFailSingle": "Вновь ошибки",
      "mistakesReFailHint": "Ошибка совершается снова после того, как позиция отмечена изученной",
      "mistakesListFilterLabel": "Список",
      "mistakesListFilterAll": "Все позиции",
      "mistakesListFilterRecommended": "Рекомендованные",
      "mistakesListFilterStudyPlan": "План тренировки",
      "mistakesListFilterFavorites": "Избранное",
      "inProgress": "В процессе",
      "barMode": "Гистограммы",
      "barModeResults": "результаты партий",
      "barModeDecisions": "принятые решения",
      "displayMoves": "Отображаемые варианты",
      "displayMovesBestOnly": "обычный список",
      "displayMovesExtended": "расширенный список",
      "cTO": "Предложения по обучению",
      "tOIgnore": "не отображать",
      "tOBest": "один лучший",
      "tOAll": "все",
      "cPractice": "Встречалось в партиях",
      "practiceIgnore": "не отображать",
      "practiceHero": "при ходе личности",
      "practiceAll": "все",
      "cEmphasis": "Прочие ходы",
      "emphasisIgnore": "не отображать",
      "hintemphasisIgnore": "Отключить отображение дополнительных ходов",
      "emphasis3": "один лучший",
      "hintemphasis3": "Ровно один ход из числа сильнейших",
      "emphasis2": "хорошие",
      "hintemphasis2": "Качественные ходы",
      "emphasis1": "расширенный список",
      "hintemphasis1": "Все теоретические ходы",
      "flipBoard": "Повернуть доску",
      "switchEngine": "Stockfish",
      "addToFavorites": "Добавить в избранное",
      "removeFromFavorites": "Удалить из избранного",
      "addToStudyPlan": "Добавить в план тренировки",
      "removeFromStudyPlan": "Удалить из плана изучения",
      "ignore": "Игнорировать",
      "prehistory": "Предыстория"
    },
    "opening2": {
      "tooSmallTree": "Задание не создано: текущее дерево тренировки слишком мало",
      "speed": "Настройки тренировки",
      "autoplayDelay": "Задержка автоигры (сек.)",
      "arrowDelay": "Задержка стрелки (сек.)",
      "reps": "Число повторений",
      "leading": "Повторение ходов",
      "testing": "Проверка знаний",
      "pageLearn": "Обучение",
      "pageTest": "Тест",
      "buttonStart": "Начать",
      "buttonStartLearn": "Начать обучение",
      "buttonStartTraining": "Начать тестирование",
      "buttonAbort": "Остановить",
      "buttonReset": "Обнулить",
      "passingParams": "Параметры тренировки",
      "toStart": "Начать сначала",
      "nextVariation": "Следующий вариант",
      "labelCorrect": "Правильно",
      "labelWrong": "Неточно",
      "buttonStartTest": "Начать тест",
      "learningMessages": {
        "beginTitle": "Начнём тренировку.",
        "beginDescription": "Пройдите предложенные варианты с подсказками, регулируя количество повторов при необходимости.",
        "firstVariantTitle": "Переходим к первому варианту.",
        "firstVariantDescription": "Сделайте ваш ход, следуя подсказкам.",
        "learnedVariantTitle": "Вариант успешно изучен!",
        "learnedVariantDescription": "Перейдите  к следующему варианту или нажмите «Повторить» для закрепления текущего.",
        "newVariantTitle": "Переходим к новому варианту.",
        "newVariantDescription": "Вы отлично запомнили начальные ходы, поэтому теперь они проигрываются автоматически.",
        "noVariantsTitle": "Вы успешно прошли все предложенные варианты!",
        "noVariantsDescription": "Перейдите в режим Тест для закрепления результата."
      }
    },
    "compare": {
      "separator1": ", ",
      "separator2": ", ",
      "separator3": " ",
      "title": "Параметры сравнения",
      "aveloRange": "Рейтинг",
      "timeControl": "Контроль времени",
      "rivals": "Уровень соперников между собой",
      "pg1": "Заочные партии",
      "pg2": "Игра вживую",
      "pg2Short": "Игра вживую",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "крепкие середняки",
      "avelo2": "мастера",
      "avelo3": "топ",
      "tc2_2": "Классика",
      "tc2_3": "Быстрые",
      "tc2_4": "Блиц",
      "tc3_3": "классика, быстрые",
      "tc3_4": "блиц",
      "ed4": "соперники равны",
      "ed345": "соперники сопоставимы",
      "ed23456": "любые",
      "headered4": " (равные)",
      "headered345": " (сопоставимые)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "Кол-во партий",
      "numberOfGamesShort": "Партий",
      "stat": "Статистика позиции",
      "variations": "Варианты",
      "resultsPerc": "Результаты, %",
      "decisonsPerc": "Решения, %",
      "move": "Ход",
      "eval": "Оценка",
      "moveEval": "Ход (оценка)",
      "frequency": "Доля",
      "numberOfMoveMade": "Кол.",
      "controlGroup": "Контрольная группа",
      "playerStatistics": "Статистика игрока"
    },
    "feedback": {
      "title": "Пожалуйста, опишите проблему по возможности подробно",
      "description": "Описание"
    },
    "settings": {
      "displayedName": "Отображаемое имя"
    },
    "preferences": {
      "piecesDisplayedAs": "Отображение фигур",
      "shortNotation": "Использовать короткую нотацию",
      "flipWhenBlack": "Повернуть доску, когда в исходной позиции ход чёрных",
      "mistakesSensivity": "Чувствительность к ошибкам"
    },
    "moveMarking": {
      "lbhero": "Тренировка",
      "hbydefault": "по умолч.",
      "hchoice": "выбрать",
      "hignore": "игнор.",
      "lbOpp": "Тренировка",
      "obydefault": "по умолч.",
      "ochoice": "учитывать",
      "oignore": "игнор."
    },
    "pieces": {
      "N": "К",
      "B": "С",
      "R": "Л",
      "Q": "Ф",
      "K": "Кр"
    },
    "loginPage": {
      "signin": "Вход",
      "signup": "Регистрация",
      "enterEmail": "Введите свой email",
      "enterPassword": "Введите свой пароль",
      "confirmRegister": "Регистрация",
      "dontHaveAccount": "Нет учётной записи?",
      "logInViaGoogle": "Войти с аккаунтом Google"
    },
    "register": {
      "signup": "Регистрация",
      "signupGoogle": "Регистрация через аккаунт Google",
      "title": "Регистрация",
      "enterEmail": "Адрес электронной почты",
      "enterName": "Имя",
      "enterPhone": "Номер телефона (необязательно)",
      "enterPassword": "Создайте пароль",
      "confirmPassword": "Подтверждение пароля",
      "alreadyHaveAccout": "Already have an account?",
      "tip": "Пароль должен состоять не менее чем из 8 символов: строчные и заглавные буквы, цифры и специальные символы",
      "agreement": "Я даю согласие на обработку моих персональных данных, и соглашаюсь с ",
      "agreementErrorText": "Пожалуйста, примите условия использования, чтобы продолжить.",
      "privacy": "политикой конфиденциальности",
      "and": " и ",
      "terms": "условиями использования.",
      "confirmationTitle": "Почти готово!",
      "confirmation": {
        "title": "Подтвердите Вашу почту",
        "description": "На адрес электронной почты {email} было отправлено письмо с ссылкой для подтверждения",
        "secondDescription": "Проверьте почту и следуйте инструкциям",
        "resendLinkButton": "Отправить ссылку повторно",
        "backButton": "Перейти на Главную страницу"
      },
      "promocode": {
        "title": "Активируйте промо-код",
        "description": "Наш сервис в разработке, но вы уже сделали первый шаг. Введите полученный промокод для получения скидки и укажите данные своих партий, чтобы мы могли приступить к анализу сразу после запуска",
        "profileLabel": "Ваш профиль на шахматной платформе",
        "promocodePlaceholder": "Промо-код",
        "usernamePlaceholder": "Имя пользователя",
        "saveButton": "Сохранить",
        "proceedButton": "Продолжить без промо-кода"
      },
      "successDiscount": {
        "title": "Промо-код активирован!",
        "description": "Отлично, за Вами закреплён бесплатный пробный период",
        "description2": "Мы сообщим Вам на указанную почту, как только сервис будет готов",
        "description3": "Спасибо, что Вы с нами!",
        "closeButton": "Закрыть"
      },
      "successRegister": {
        "title": "Регистрация успешна!",
        "description": "Ваш аккаунт был успешно зарегистрирован"
      },
      "replacement": {
        "title": "Спасибо, что Вы с нами!",
        "description": "Благодарим Вас за интерес к нашему шахматному сервису",
        "description2": "Мы сообщим Вам на указанную почту, как только сервис будет готов к запуску"
      },
      "afterLogin": {
        "title": "Сервис в разработке"
      },
      "confirmationText": "Для завершения процесса регистрации подтвердите указанный Вами адрес электронной почты"
    },
    "registerConfirmation": {
      "title": "Почти готово!",
      "text": "Для завершения процесса регистрации подтвердите указанный Вами адрес электронной почты",
      "incorrectEmail": "Неправильно указан email?",
      "backToSign": "Вернуться к форме регистрации",
      "noMail": "Не получили на email письмо со ссылкой для подтверждения?",
      "resend": "Отправить письмо со ссылкой для подтверждения снова",
      "problem": "Что-то произошло",
      "expired": "Данная ссылка для подтверждения устарела",
      "successTitle": "Ваш адрес электронной почты успешно подтверждён",
      "successText": "Теперь Вы можете пользоваться всеми возможностями нашего сервиса",
      "login": "Log in"
    },
    "recovery": {
      "title": "Забыли пароль?",
      "enterEmail": "Введите свой email",
      "submit": "Отправить запрос",
      "returnTo": "Вспомнили пароль?",
      "login": "Вход"
    },
    "recoveryConfirmation": {
      "title": "Проверьте вашу почту",
      "text": "Инструкции по восстановлению пароля были отправлены на ",
      "continue": "Продолжить"
    },
    "changePassword": {
      "title": "Сменить пароль",
      "enterPassword": "Создайте новый пароль",
      "confirmPassword": "Подтвердите новый пароль",
      "submit": "Сохранить изменения",
      "tip": "Новый пароль должен отличаться от предыдущего и состоять не менее чем из 8 символов: строчные и заглавные буквы, цифры и специальные символы",
      "expired": "Данная ссылка для подтверждения устарела",
      "problem": "Что-то произошло",
      "resend": "Отправить письмо со ссылкой для подтверждения снова",
      "success": "Пароль был успешно изменён",
      "login": "Вход"
    },
    "rules": {
      "confirmpwd": "Пожалуйста, подтвердите пароль",
      "dontMatch": "Пароли не совпадают",
      "enterEmail": "Введите email",
      "enterPwd": "Введите пароль",
      "enterName": "Введите имя",
      "fieldRequired": "Данное поле обязательно к заполнению",
      "emailMin": "Email должен содержать не менее ",
      "pwdMin": "Пароль должен содержать не менее ",
      "nameMin": "Имя должно содержать не менее ",
      "phoneMin": "Номер телефона должен содержать не менее ",
      "phoneMax": "Номер телефона не должен быть длиннее ",
      "phoneInvalid": "Номер телефона содержит недопустимые символы",
      "valueMin": "Значение должно быть не менее ",
      "minPostfix": " символов",
      "pwdUpper": "Пароль должен содержать по меньшей мере одну заглавную латинскую букву",
      "pwdNumber": "Пароль должен содержать по меньшей мере одну цифру",
      "wrongPassword": "Пароль неверен",
      "incorrectEmail": "Адрес электронной почты некорректен",
      "emailNotRegistered": "Адрес электронной почты не зарегистрирован",
      "getName": "Как можно к Вам обращаться?"
    },
    "help": {
      "preAnalysis": {
          "userColor": "Цвет, которым играл пользователь",
          "moveRange": "Диапазон ходов, на котором возникла критическая позиция",
          "avgMistakeSeverity": "Средняя грубость ошибки (в пешках)",
          "openingName": "Название дебюта (опционально)",
          "displayResultsFor": "Вывести результаты для:",
          "allCriticalPositions": "Всех критических позиций",
          "favorites": "Избранного",
          "trainingPlan": "Плана тренировок",
          "demonstrationMode": "Режим демонстрации критических позиций:",
          "treeView": "В форме дерева, с демонстрацией иерархии и взаимного расположения критических позиций.",
          "listView": "Списком:",
          "leftClickOptions": "При клике левой кнопкой мыши на вершину можно добавить позицию в «План тренировки», «Избранное» или «Игнорируемая».",
          "planTraining": "План тренировки",
          "ignored": "Игнорируемая",
          "gameStart": "Начало партии",
          "transitionBetweenPositions": "Отражает переход одной критической позиции в другую, «ребро» может содержать любое количество ходов.",
          "criticalPositions": "Критические позиции. При наведении мышки демонстрируется позиция,",
          "criticalPositions2": "среднее ухудшение оценки, количество потенциальных ошибок и суммарные потери.",
          "fenFormat": "Позиция в формате FEN, можно скопировать позицию, которая сейчас на доске.",
          "treeViewAlternatives": "Посмотреть варианты в виде дерева, будут автоматически предложены альтернативы, которые можно скорректировать.",
          "navigateAnalysis": "Переход на страницу анализа.",
          "directTraining": "Минуя просмотр и возможность корректировки вариантов перейти к отработке предлагаемых сервисом альтернатив.",
          "navigateTraining": "Переход на страницу тренировки.",
          "positionVariants": "Верхний - рекомендуемый, под ним - ходы которые совершал пользователь. Указан сам ход, оценка после хода, сколько раз был сделан ход, суммарные потери из-за хода в анализируемых партиях.",
          "trainingPlanFavorites": "Вспомогательные списки «План тренировки» и «Избранное», куда попадают критические позиции (представленные в дереве или списке). Данные списки доступны на всех страницах тренировки, через их составление удобно корректировать план тренировок.",
          "addToLists": "Выбранную критическую позицию в списке или на дереве можно добавить в План тренировки, Избранное или Игнорируемая.",
          "filterBlock": {
            "filterBlockTitle": "Блок фильтров критических позиций",
            "chooseOptions": "Можно выбрать:",
            "colorPlayed": "Цвет, которым играл пользователь;",
            "moveRange": "Диапазон ходов на котором возникла критическая позиция от 1 до максимального, где встречалась потенциальная ошибка;",
            "errorSeverity": "Средняя грубость ошибки (в пешках);",
            "openingName": "Название дебюта (опционально);",
            "displayResultsFor": "Вывести результаты для:",
            "allCriticalPositions": "Всех критических позиций",
            "favorites": "Избранного",
            "trainingPlan": "Плана тренировок"
          },
          "demonstrationModeTitle": "Демонстрация критических позиций.",
          "gameStartTitle": "Иконка начала партии",
          "transitionBetweenPositionsTitle": "Иерархия связей критических позиций",
          "criticalPositionsTitle": "Критические позиции.",
          "fenFormatTitle": "Позиция в формате FEN",
          "treeViewAlternativesTitle": "Переход на страницу анализа вариантов",
          "directTrainingTitle": "Переход на страницу тренировки.",
          "positionVariantsTitle": "Ходы, совершенные в выбранной позиции",
          "trainingPlanFavoritesTitle": "Списки “План тренировки” и “Избранное”",
          "addToListsTitle": "Меню изменения статуса критической позиции.",
        },
      "mainPage": {
        "accountNameTitle": "Имя аккаунта в сервисе",
        "accountName": "Создаётся в момент регистрации и служит идентификатором.",
        "accountNameExit": "Кнопка выхода из сервиса (применяется для разлогинивания).",
        "addNewPlayerTitle": "Добавление нового анализируемого игрока",
        "addNewPlayer": "Для анализа партий требуется создать пользователя, к которому далее будут подгружены партии с шахматных площадок или очных/заочных партий.",
        "addNewPlayerAdditional": "Требуется ввести имя и (опционально) описание.",
        "analyzedUserTitle": "Анализируемый пользователь",
        "analyzedUser": "Включает никнеймы с выбранных шахматных площадок. При нажатии на иконку «Карандаш» есть возможность внести изменения.",
        "gameAdditionMenuTitle": "Меню добавления партий для анализа",
        "gameAdditionMenu": "Требуется ввести имя пользователя, шахматную площадку нажать «ОК».",
        "nicknameActivationTitle": "Активация никнейма",
        "nicknameActivation": "Если никнейм уже добавлен, его партии можно включить или исключить из текущего анализа.",
        "analyzedNicknamesListTitle": "Список анализируемых никнеймов",
        "analyzedNicknamesList": "Содержит все доступные к анализу никнеймы.",
        "gameSourceTitle": "Источник партий",
        "gameSource": "Содержит информацию о источнике партий.",
        "timeControlsMenuTitle": "Меню выбора контролей времени",
        "timeControlsMenu": "Выбор контролей для анализа обработанных партий.",
        "planOptionsTitle": "Опции смены тарифного плана",
        "criticalPositionsOverviewTitle": "Переход к обзору критических позиций",
        "criticalPositionsOverview": "После выделения анализируемых никнеймов и контролей времени можно перейти к обзору критических позиций.",
        "navigationTitle": "Навигации по страницам",
        "navigation": "Содержит ссылки на страницы:",
        "navigation1": "Загрузки и подготовки данных;",
        "navigation2": "Выбора позиций для анализа и коррекции;",
        "navigation3": "Анализа вариантов;",
        "navigation4": "Тренировки.",
        "feedbackFormTitle": "Форма обратной связи",
        "feedbackForm": "Предлагается вариант чата или отправки сообщения с возможностью приложить скриншот."
      },
      "analysisPage": {
        "controlGroupMenuTitle": "Меню выбора контрольной группы для сравнения",
        "controlGroupMenu": "Можно выбрать:",
        "controlGroupMenu1": "Источник партий;",
        "controlGroupMenu2": "Рейтинговую группу игроков;",
        "controlGroupMenu3": "Контроль времени.",
        "positionSelectionTitle": "Выбор позиции для подробного анализа",
        "positionSelection": "Присутствуют 3 списка:",
        "positionSelection1": "Все критические позиции;",
        "positionSelection2": "План тренировки;",
        "positionSelection3": "Избранное.",        "positionSelection4": "«Дельта оценки» - содержит информацию о средней величине ошибки.",
        "positionNodeTitle": "Вершина, символизирующая позицию",
        "positionNode": "Содержит ход, после которого возникла позиция и оценку позиции. Может иметь различную расцветку.",
        "positionNode1": "Серый - один из множества возможных ходов.",
        "positionNode2": "Голубой  - позиция, предлагаемая для тренировки, в которой очередь хода на стороне оппонента.",
        "positionNode3": "Фиолетовый -  позиция, предлагаемая для тренировки, в которой очередь хода на стороне пользователя.",
        "moveEdgeTitle": "Ребро - символизирует ход, обеспечивающий переход из одной позиции в другую",
        "moveEdge": "Цвет указывает предполагаемую  силу хода. Чем цвет ближе к зеленому, тем ход сильнее, к красному - слабее.",
        "moveEdge1": "Число над ребром указывает, сколько раз ход был сделан в анализируемых партиях пользователя.",
        "branchCorrectionToolTitle": "«Сэндвич»",
        "branchCorrectionTool": "Инструмент для корректировки отображения разветвлений после конкретной позиции.",
        "branchCorrectionTool1": "Внутри содержатся от 0 до 3 линий, символизирующих количество скрытых ходов и силу лучших трех из них. Например, если 2 линии зеленые и одна красная, значит вскрыты 2 сильных хода хода и один слабый.",
        "branchCorrectionTool2": "При клике открывается меню выбора отображения.",
        "branchCorrectionTool3": "Указаны все рассматриваемые в позиции ходы.",
        "branchCorrectionTool4": "В меню можно выбрать, показывать или нет ход независимо от общих настроек либо в соответствии с выставленными фильтрами.После настройки можно кликнуть на крестик или в любой свободной точке экрана.",
        "nextMovesToolTitle": "Инструмент для раскрытия последующих ходов в варианте",
        "nextMovesTool": "После клика знак изменится на",
        "nextMovesTool1": "и справа будут показаны дальнейшие варианты развития партии.",
        "nextMovesTool2": "До:",
        "nextMovesTool3": "После:",
        "massExpandMovesToolTitle": "Инструмент для массового раскрытия последующих ходов варианта",
        "massExpandMovesTool": "Используется для раскрытия варианта сразу на 5 ходов вперед, при этом на каждом ходе будут указаны возможные альтернативы.",
        "massExpandMovesTool1": "До:",
        "massExpandMovesTool2": "После:",
        "moveNumberingTitle": "Нумерация ходов от начала партии",
        "moveNumbering": "Указан номер хода для позиций в столбце:",
        "moveNumbering1": "С точкой - ход белых;",
        "moveNumbering2": "С многоточием - ход черных.",
        "variationFilterTitle": "Фильтр отображения вариантов дерева",
        "variationFilter": "Отображение вариантов в дереве можно через 3 добавляемых или отменяемых фильтра:",
        "variationFilter1": "Предложения альтернативных вариантов для тренировки:",
        "variationFilter2": "Только самое лучшее продолжение или несколько, если они практически равноценны.",
        "variationFilter3": "Отображение ходов, которые встречались в партиях:",
        "variationFilter4": "Ходы, которые делал анализируемый игрок;",
        "variationFilter5": "Ходы и игрока и его оппонентов.",
        "variationFilter6": "Альтернативные ходы, не входящие в предлагаемые к обучению:",
        "variationFilter7": "«Один лучший»;",
        "variationFilter8": "«Все хорошие ходы»;",
        "variationFilter9": "«Расширенный список».",
        "trainingPositionsTitle": "Демонстрация ходов/позиций, предлагаемых к тренировке в текущем состоянии",
        "trainingPositions": "Демонстрирует дерево ходов, предлагаемых к тренировке (возможно ожидание до 30 секунд).",
        "trainingPositions1": "До:",
        "trainingPositions2": "После:",
        "statisticsBlockTitle": "Блок статистики",
        "statisticsBlock": "В статистике для выбранной позиции представлено:",
        "statisticsBlock1": "Верхний блок:",
        "statisticsBlock2": "Количество партий игрока, в которых встретилась позиция;",
        "statisticsBlock3": "% Результатов выигрыш/ничья/поражение;",
        "statisticsBlock4": "% Верных/сомнительных решений.",
        "statisticsBlock5": "Нижний блок:",
        "statisticsBlock6": "Количество партий в выбранной контрольной группе, в которых встретилась позиция;",
        "statisticsBlock7": "% Результатов выигрыш/ничья/поражение;",
        "statisticsBlock8": "% Верных/сомнительных решений.",
        "positionVariationsTitle": "Варианты в выбранной позиции",
        "positionVariations": "Частота ходов контрольной группы для данной позиции.",
        "addRemovePositionTitle": "Добавить/убрать позицию к вариантам для тренировки",
        "bookmarkFunctionTitle": "Функция закладки для быстрого перехода к указанной вершине",
        "bookmarkFunction": "Можно активировать позицию, щелкнуть левой кнопкой мыши или под доской выбрать указанный значок, после чего добавится закладка, при наведении на которую через клик можно сразу перейти в выбранную вершину на дереве.",
        "bookmarkFunction1": "Закладка с превью позиции.",
        "bookmarkFunction2": "Переход после клика.",
        "hideBoardStatsTitle": "Функция скрытия доски и статистики",
        "hideBoardStats": "Для освобождения пространства под вершины дерева.",
        "criticalPositionsPageTitle": "Переход на страницу выбора критических позиций для анализа",
        "treeScaleTitle": "Масштаб дерева",
        "treeScale": "Влияет только на размер элементов дерева.",
        "stockfishAnalysisTitle": "Анализ через Stockfish",
        "stockfishAnalysis": "Для выбранной позиции на доске можно включить анализ движком в реальном времени.",
        "elementsDisplaySettingsTitle": "Настройка отображения элементов",
        "elementsDisplaySettings": "Доступно изменение вида фигур, ориентации доски и вида вершин.",
        "trainingPageTitle": "Переход на страницу тренировки",
        "trainingPage": "Будет предложено ввести выбранные для тренировки ходы с помощью подсказок. После запоминания вариантов будет предложено пройти тестирование.",
      },
      "exercisePage": {
        "positionNodeExerciseTitle": "Вершина, обозначающая позицию к которой пришли после указанного хода",
        "positionNodeExercise": "Мой ход",
        "positionNodeExercise1": "Ход соперника",
        "positionNodeExercise2": "Результаты теста",
        "positionNodeExercise3": "Успешно",
        "positionNodeExercise4": "Неудача",
        "positionNodeExercise5": "Не выполнен",
        "moveEdgeExerciseTitle": "Ребро, обозначающее связь между позициям",
        "moveEdgeExercise": "Указывается сколько раз в рамках тренировки был сделан ход из заложенного в тренировку количества.",
        "trainingMovesCountTitle": "Количество ходов, включенных в тренировку",
        "branchSelectionInfoTitle": "Информация и выбор конкретной ветки для повторной тренировки",
        "trainingSettingsTitle": "Настройка тренировки",
        "trainingSettings": "Можно настроить:",
        "trainingSettings1": "Количество повторений для всех веток;",
        "trainingSettings2": "Скорость автоматического воспроизведения уже пройденных сегментов вариантов;",
        "trainingSettings3": "Задержку демонстрации подсказки для возможности самостоятельно предположить лучший ход (увеличивается эффективность тренировки через лучшее запоминание).",
        "modeSelectionTitle": "Выбор режима",
        "modeSelection": "В режиме обучения  будет предложено пройти ввести варианты, пользуясь подсказками;",
        "modeSelection1": "В режиме теста - ввести выбранные продолжения, но без подсказок, в режиме реальной игры.",
        "startTrainingButtonTitle": "Кнопка для начала обучения",
        "currentStateWindowTitle": "Окно текущего состояния",
      }
    },
    "card2": {
      "heronamePlaceholder": "Укажите имя личности",
      "descriptionPlaceholder": "Укажите описание личности (не обязательно)",
    },
    "cardSummary2": {
      "nameUser": "Имя пользователя",
      "nameUserPlaceholder": "Укажите ваше имя пользователя",
      "platform": "Платформа",
      "platformPlaceholder": "Выберите платформу  из списка",
      "plarformSelectBottom": "Для анализа партий с других площадок, доступных в формате .pgn, пожалуйста, обратитесь в службу поддержки",
      "cardTitlePositionTree": "Дерево критических позиций",
      "cardTitleListTree": "Список критических позиций"
    },
    "analysisPage2": {
      "hide" : "Скрыть",
      "show": "Показать",
      "removeBookmarks": "Удалить из закладок",
      "addBookmarks": "Добавить в закладки",
      "addPractice": "Добавить в тренировку",
      "removePractice": "Убрать из тренировок"
    }
  },
};

export const i18n = createI18n({
  legacy: false,
  locale: 'en', // set locale
  fallbackLocale: 'ru', // set fallback locale
  messages: L10n, // set locale messages
});
