import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, unref as _unref, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

import { type HelpPointKey, HelpPointParams, useHelp } from '@/shared/composables/useHelp';
import { computed, CSSProperties, ref } from 'vue';
import HelpCard from './HelpCard.vue';
import { HELP_COLOR } from '@/shared/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpElement',
  props: {
    id: {},
    width: { default: '100%' },
    height: { default: 'auto' },
    top: {},
    left: {},
    handleClick: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "3f1a5a62": (_unref(HELP_COLOR))
}))

const props = __props;

const isTooltipShown = ref<boolean>(false);

const { helpPointsMap, toggleHelp } = useHelp();

const params = computed<HelpPointParams>(() => helpPointsMap.value[props.id]);

const elementStyle = computed<CSSProperties>(() => ({
  width: params.value?.customWidth ? params.value?.customWidth + 'px' : props.width + 'px',
  height: params.value?.customHeight? params.value?.customHeight + 'px' : props.height + 'px',
  top: props.top + 'px',
  left: props.left + 'px',
  zIndex: isTooltipShown.value ? 10000 : 9000,
}));

const toggleTooltip = (): void => {
  isTooltipShown.value =!isTooltipShown.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "help-element",
    style: _normalizeStyle(elementStyle.value),
    onMouseenter: toggleTooltip,
    onMouseleave: toggleTooltip,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(toggleHelp) && _unref(toggleHelp)(...args)))
  }, [
    (isTooltipShown.value)
      ? (_openBlock(), _createBlock(HelpCard, _normalizeProps(_mergeProps({ key: 0 }, params.value)), null, 16))
      : _createCommentVNode("", true)
  ], 36))
}
}

})