<template>
  <div class="sc-buttongroup d-flex" :class="{'opacity-70 cursor-default':disabled}">
    <button
      v-for="item in itemsGroup"
      :key="item.id"
      class="sc-buttongroup__item d-flex flex-1-1-0 align-center justify-center text-center cursor-pointer ga-2"
      :class="[
        size === 'medium' ? 'semibold-14 pa-2' : 'semibold-12 pa-1',
        { active: model === item.id },
        {'disabled': disabled}
      ]"
      :disabled="disabled"
      @click="selectItem(item.id)"
    >
      <ScIcon
        v-if="item.prependIcon"
        :id="item.prependIcon"
        color="currentColor"
      />
      {{item.title}}
      <ScIcon
        v-if="item.appendIcon"
        :id="item.appendIcon"
        color="currentColor"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import {ScButtonGroupProps} from './ScButtonsGroup.types'
import ScIcon from '@/components/Common/ScIcon.vue';

const model = defineModel<number>();

const props = withDefaults(defineProps<ScButtonGroupProps>(), {
  size: 'medium',
});

const selectItem = (id: number): void => {
  model.value = id;
};

onMounted(()=>{
  if(!model.value && props.itemsGroup.length){
    model.value = props.itemsGroup[0].id
  }
})
</script>

<style scoped>
.sc-buttongroup {
  border-radius: 40px;
  background-color: var(--button-secondary);
}

.sc-buttongroup__item {
  color: var(--text-secondary);
}

.sc-buttongroup__item:first-child{
  border-radius: 40px 0 0 40px;
}

.sc-buttongroup__item:last-child{
  border-radius: 0 40px 40px 0;
}

.sc-buttongroup > .sc-buttongroup__item.active {
  background-color: var(--button-loading);
  color: var(--text-primary);
}

.disabled{
  cursor: default !important;
}
</style>
