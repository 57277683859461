<template>
  <div v-html="svgContent" :style="variableStyle" class="icon" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { COLORS } from '@/shared/constants';
import { useUIStore } from '@/shared/composables/useUIStore';

const props = withDefaults(
  defineProps<{
    id: string;
    width?: string | number
    height?: string | number;
    color?: string;
  }>(),
  {
    width: 24,
    height: 24,
    color: COLORS.GREY,
  },
);

const { uiState } = useUIStore();

const svgContent = ref<string>('');

const variableStyle = computed<string>(() => {
  const height = props.height === 'auto' ? 'auto' : `${props.height}px`;
  const width =  props.width === 'auto' ? 'auto' : `${props.width}px`;
  const color = props.color;

  return `--height: ${height}; --width: ${width}; --color: ${color};`;
});

const loadSVG = async () => {
  if (!props.id) {
    return;
  }
  const iconId = props.id;
  const svgCache = uiState.value.svgCache;
  const pendingRequests = uiState.value.pendingRequests;

  if (svgCache.has(iconId)) {
    svgContent.value = svgCache.get(iconId);
    return;
  }

  if (pendingRequests.has(iconId)) {
    svgContent.value = await pendingRequests.get(iconId);
    return;
  }

  try {
    const fetchPromise = fetch(`/icons/${props.id}.svg`)
      .then(response => response.text())
      .then(svg => {
        svgCache.set(iconId, svg);
        pendingRequests.delete(iconId);
        return svg;
      });

    pendingRequests.set(iconId, fetchPromise);
    svgContent.value = await fetchPromise;
  } catch (error) {
    console.error(`Failed to load ${iconId}.svg`, error);
    pendingRequests.delete(iconId);
  }
};

watch(() => props.id, loadSVG, { immediate: true });
</script>

<style scoped>
.icon {
  display: flex;
  width: var(--width);
  height: var(--height);
  color: var(--color);
}

:deep(svg) {
  width: var(--width);
  height: var(--height);
  color: var(--color);
}
</style>
