export default {
    userSettings: null,
    userPreferences: null,
    sizes: {
        vh: 0,
        vw:0
    },
    viewTypeId:1,
    svgCache: new Map(),
    pendingRequests: new Map(),
};
