import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, createBlock as _createBlock, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sc-select__label semibold-18"
}
const _hoisted_2 = { class: "sc-field__inner" }
const _hoisted_3 = {
  key: 0,
  class: "sc-select__options"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "sc-select__options-item--content" }
const _hoisted_6 = ["src", "alt", "width", "height"]
const _hoisted_7 = {
  key: 1,
  class: "sc-select_bottom-text"
}
const _hoisted_8 = {
  key: 1,
  class: "error-text"
}

import type {
  ScSelectProps,
  SelectModel,
  SelectOption,
} from './ScSelect.types';
import { computed, ref } from 'vue';
import ScIcon from '@/components/Common/ScIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScSelect',
  props: /*@__PURE__*/_mergeModels({
    options: {},
    bottomText: {},
    bottomIcon: {},
    errorText: {},
    selectedIcon: { default: 'var(--primary)' },
    valueKey: {},
    labelKey: {},
    label: {},
    placeholder: { default: '' },
    isError: { type: Boolean },
    isValid: { type: Boolean, default: true },
    size: { default: 'medium' },
    prependIcon: {},
    appendIcon: {},
    disabled: { type: Boolean },
    width: {},
    fullWidth: { type: Boolean }
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const isOpened = ref(false);

const modelValue = _useModel<SelectModel>(__props, 'modelValue');

const iconStyle = computed<number>(() => {
  return props.size === 'small' ? 20 : 24;
});

const selectedItem = computed<string | undefined>(() => {
  if (!props.options) return undefined;

  if (!props.valueKey && !props.labelKey) {
    const selectedOption = props.options.find(
      (option) => option === modelValue.value,
    );
    return selectedOption ? String(selectedOption) : undefined;
  }

  const selectedOption = props.options.find((option) => {
    if (typeof option === 'object' && option !== null) {
      return option[props.valueKey!] === modelValue.value;
    }
    return false;
  });

  return selectedOption && typeof selectedOption === 'object'
    ? String(selectedOption[props.labelKey!])
    : undefined;
});

const isSelected = (item: SelectOption): boolean => {
  if (!props.valueKey && !props.labelKey) {
    return modelValue.value === item;
  }
  if (props.valueKey && typeof item === 'object' && item !== null) {
    return item[props.valueKey!] === modelValue.value;
  }
  return false;
};

const openSelect = (): void => {
  if (!props.disabled) {
    isOpened.value = !isOpened.value;
  }
};

const closeSelect = (): void => {
  if (isOpened.value) {
    isOpened.value = false;
  }
};

const onSelect = (item: SelectOption): void => {
  if (props.valueKey && typeof item === 'object' && item !== null) {
    modelValue.value = item[props.valueKey] as string | number;
    closeSelect();
    return;
  }
  modelValue.value = item;
  closeSelect();
};

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sc-select-block", { 'sc-select-disabled': _ctx.disabled }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["sc-select sc-field", [
        _ctx.size === 'small' ? 'sc-field_small' : 'sc-field_medium',
        { 'active-border': isOpened.value },
        { 'error-border': !_ctx.isValid && !isOpened.value },
      ]]),
      onClick: openSelect
    }, [
      _createElementVNode("div", _hoisted_2, [
        (selectedItem.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (selectedItem.value)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["sc-select__description", 
              _ctx.size === 'small'
                ? 'sc-select__text--small'
                : 'sc-select__text--medium'
            ])
                  }, _toDisplayString(selectedItem.value), 3))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["sc-select__placeholder", 
            _ctx.size === 'small'
              ? 'sc-select__text--small'
              : 'sc-select__text--medium'
          ])
            }, _toDisplayString(_ctx.placeholder), 3))
      ]),
      _createVNode(ScIcon, {
        id: "expandCard",
        width: 20,
        height: 20,
        color: "var(--button-loading)",
        class: _normalizeClass(["select-icon", { 'select-icon--active': isOpened.value }])
      }, null, 8, ["class"])
    ], 2),
    _createVNode(_Transition, { name: "options" }, {
      default: _withCtx(() => [
        (isOpened.value)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.options)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["sc-select__options-item", 
              _ctx.size === 'small'
                ? 'sc-field_small sc-select__text--small'
                : 'sc-field_medium sc-select__text--medium'
            ]),
                      onClick: ($event: any) => (onSelect(item))
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        (typeof item === 'object' && item.icon && !item.img)
                          ? (_openBlock(), _createBlock(ScIcon, {
                              key: 0,
                              id: item.icon,
                              width: iconStyle.value,
                              height: iconStyle.value
                            }, null, 8, ["id", "width", "height"]))
                          : (typeof item === 'object' && item.img)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                src: `/images/${item.img}`,
                                alt: item.img,
                                width: iconStyle.value,
                                height: iconStyle.value
                              }, null, 8, _hoisted_6))
                            : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(typeof item === 'object' && _ctx.labelKey ? item[_ctx.labelKey] : item), 1)
                      ]),
                      (isSelected(item))
                        ? (_openBlock(), _createBlock(ScIcon, {
                            key: 0,
                            id: "valid",
                            color: _ctx.selectedIcon,
                            width: iconStyle.value,
                            height: iconStyle.value
                          }, null, 8, ["color", "width", "height"]))
                        : _createCommentVNode("", true)
                    ], 10, _hoisted_4))
                  }), 128))
                : _createCommentVNode("", true),
              (_ctx.bottomText || _ctx.$slots.bottomText)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                    _renderSlot(_ctx.$slots, "bottomText"),
                    (_ctx.bottomIcon)
                      ? (_openBlock(), _createBlock(ScIcon, {
                          key: 0,
                          id: _ctx.bottomIcon,
                          width: iconStyle.value,
                          height: iconStyle.value
                        }, null, 8, ["id", "width", "height"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", {
                      class: _normalizeClass([
                {
                  'sc-select__bottom--no-icon--medium':
                    !_ctx.bottomIcon && _ctx.size === 'medium',
                },
                {
                  'sc-select__bottom--no-icon--small':
                    !_ctx.bottomIcon && _ctx.size === 'small',
                },
              ])
                    }, _toDisplayString(_ctx.bottomText), 3)
                  ]))
                : _createCommentVNode("", true)
            ])), [
              [_directive_click_outside, closeSelect]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    (!_ctx.isValid && _ctx.errorText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.errorText), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})