<template>
  <v-switch
    :class="[{
      'custom-switch': modelValue,
      'custom-switch_off': !modelValue,
    },
    {'opacity-70 cursor-default': disabled}
    ]"
    v-model="modelValue"
    :label="label"
    :disabled="disabled"
    :value="value"
    hide-details
  />
</template>

<script setup lang="ts">
import { ScSwitchProps } from '@/components/Common/ScSwitch/ScSwitch.types';

const props = withDefaults(defineProps<ScSwitchProps>(), {});

const modelValue = defineModel('modelValue', {
  required: true,
});
</script>

<style scoped>
.v-switch {
  width: max-content;
}

:deep(.v-selection-control) {
  min-height: auto;
}

:deep(.v-selection-control__wrapper) {
  height: auto;
}

:deep(.v-label) {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Source Sans 3';
  color: var(--text-primary);
}

:deep(.v-input__details) {
  display: none;
}

:deep(.v-switch__track) {
  height: 22px;
  width: 40px;
  background-color: var(--primary);
  opacity: 1;
  padding: 0;
}

.custom-switch_off:deep(.v-switch__track) {
  background-color: var(--button-secondary);
}

.custom-switch_off:deep(.v-switch__thumb) {
  background-color: var(--button-loading);
  box-shadow: 0 1px 2px 0 #0000000f, 0 1px 3px 0 #0000001a;
  width: 18px !important;
  height: 18px !important;
}

.custom-switch:deep(.v-switch__thumb) {
  box-shadow: 0 1px 2px 0 #0000000f, 0 1px 3px 0 #0000001a;
  background-color: var(--color-white);
  width: 18px !important;
  height: 18px !important;
}

.custom-switch:deep(.v-selection-control__input) {
  width: auto;
  height: auto;
  transform: translateX(9px) !important;
}

.custom-switch_off:deep(.v-selection-control__input) {
  width: auto;
  height: auto;
  transform: translateX(-9px);
}
</style>
