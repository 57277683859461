import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled", "onClick"]

import { onMounted } from 'vue';
import {ScButtonGroupProps} from './ScButtonsGroup.types'
import ScIcon from '@/components/Common/ScIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScButtonsGroup',
  props: /*@__PURE__*/_mergeModels({
    itemsGroup: {},
    placeholder: {},
    isError: { type: Boolean },
    isValid: { type: Boolean },
    size: { default: 'medium' },
    prependIcon: {},
    appendIcon: {},
    disabled: { type: Boolean },
    width: {},
    fullWidth: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const model = _useModel<number>(__props, "modelValue");

const props = __props;

const selectItem = (id: number): void => {
  model.value = id;
};

onMounted(()=>{
  if(!model.value && props.itemsGroup.length){
    model.value = props.itemsGroup[0].id
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sc-buttongroup d-flex", {'opacity-70 cursor-default':_ctx.disabled}])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsGroup, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item.id,
        class: _normalizeClass(["sc-buttongroup__item d-flex flex-1-1-0 align-center justify-center text-center cursor-pointer ga-2", [
        _ctx.size === 'medium' ? 'semibold-14 pa-2' : 'semibold-12 pa-1',
        { active: model.value === item.id },
        {'disabled': _ctx.disabled}
      ]]),
        disabled: _ctx.disabled,
        onClick: ($event: any) => (selectItem(item.id))
      }, [
        (item.prependIcon)
          ? (_openBlock(), _createBlock(ScIcon, {
              key: 0,
              id: item.prependIcon,
              color: "currentColor"
            }, null, 8, ["id"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(item.title) + " ", 1),
        (item.appendIcon)
          ? (_openBlock(), _createBlock(ScIcon, {
              key: 1,
              id: item.appendIcon,
              color: "currentColor"
            }, null, 8, ["id"]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}
}

})