<template>
  <ScCard
    background-color="input-field"
    class="collapsible-card"
    :style="{
      width: isCollapsed ? maxWidth : expandedMaxWidth,
      maxHeight: isCollapsed ? maxHeight : expandedMaxHeight,
      cursor: isCollapsed ? 'pointer' : 'default', 
    }"
  >
    <template #title>
      <div class="semibold-14 d-flex align-center justify-between" @click="openCard">
        <p class="collapsible-card__title">
          {{ title }}
        </p>
        <ScIcon
          @click.stop="isCollapsed ? openCard() : closeCard()"
          class="icon append-icon collapsible-card__icon"
          :id="isCollapsed ? collapsedIconId : expandedIconId"
        />
      </div>
    </template>
    <v-expand-transition>
      <div v-if="!isCollapsed">
        <v-divider class="my-4"/>
        <slot></slot>
      </div>
    </v-expand-transition>
  </ScCard>
</template>

<script>
import ScIcon from '@/components/Common/ScIcon.vue';

export default {
  name: 'CollapsibleCard',
  components: { ScIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '240px', // Default width when collapsed
    },
    expandedMaxWidth: {
      type: String,
      default: '100%', // Default width when expanded
    },
    maxHeight: {
      type: String,
      default: '300px', // Default height when collapsed
    },
    expandedMaxHeight: {
      type: String,
      default: '100%', // Default height when expanded
    },
    collapsedIconId: {
      type: String,
      default: 'expandCard',
    },
    expandedIconId: {
      type: String,
      default: 'collapseCard',
    },
    cardId: {
      type: Number,
    },
    openedCardId: {},
    changeOpenedCardId: {},
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    openCard() {
      this.changeOpenedCardId(this.cardId);
    },
    closeCard() {
      this.changeOpenedCardId(0);
    },
  },
  watch: {
    openedCardId: {
      immediate: true,
      handler: function (val) {
        const cardId = this.cardId;

        if (cardId === val) {
          if (this.isCollapsed) {
            this.isCollapsed = false;
          }
        } else {
          this.isCollapsed = true;
        }
      },
    },
  },
};
</script>

<style scoped>
.collapsible-card__title {
  white-space: wrap;
  width: calc(100% - 30px);
}
.collapsible-card__icon {
  min-width: auto;
  padding: 0;
  cursor: pointer;
}
</style>
