import { ScButton } from '@/components/Common/ScButton';
import { ScCheckbox } from '@/components/Common/ScCheckbox';
import { ScInput } from '@/components/Common/ScInput';
import { ScRadio } from '@/components/Common/ScRadio';
import { ScSelect } from '@/components/Common/ScSelect';
import { ScSwitch } from '@/components/Common/ScSwitch';
import { ScRange } from '@/components/Common/ScRange';
import { ScTextarea } from '@/components/Common/ScTextarea';
import { ScButtonsGroup } from '@/components/Common/ScButtonsGroup';
import { ScCard } from '@/components/Common/ScCard';
import ScIcon from './ScIcon.vue';

import { ScNumberInput } from '@/components/Common/ScNumberInput';
import { ScTable } from '@/components/Common/ScTable';

import ButtonSelector from '@/components/Common/ButtonSelector.vue';
import CollapsibleCard from '@/components/Common/CollapsibleCard.vue';

export default [
  { name: 'ScIcon', component: ScIcon },
  { name: 'ScButton', component: ScButton },
  { name: 'ScCheckbox', component: ScCheckbox },
  { name: 'ScInput', component: ScInput },
  { name: 'ScRadio', component: ScRadio },
  { name: 'ScSelect', component: ScSelect },
  { name: 'ScSwitch', component: ScSwitch },
  { name: 'ScRange', component: ScRange },
  { name: 'ScTextarea', component: ScTextarea },
  { name: 'button-selector', component: ButtonSelector },
  { name: 'collapsible-card', component: CollapsibleCard },
  { name: 'sc-table', component: ScTable },
  { name: 'sc-number-input', component: ScNumberInput },
  { name: 'ScButtonsGroup', component: ScButtonsGroup },
  { name: 'ScCard', component: ScCard },
  { name: 'ScIcon', component: ScIcon },
];
