export const FOOTER_HEIGHT = 36;
export const COLORS = {
  GREEN: '#80ff80',
  RED: '#ff8080',
  GREY: '#9A9A9A',
  BLUE: '#9EDDF8',
  PINK: '#BB9BEE',
  WHITE: '#FFFFFF',
  TEXT_PRIMARY: '#F4F4F4',
  LIGHT_GRAY_GRAPHS: '#DEE3E6',
  GRAY_GRAPHS: '#ADADAD',
  RED_GRAPHS: '#FF8080',
  GREEN_GRAPHS: '#74B89D',
};

export const HELP_COLOR = 'yellow';

export const INITIAL_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
