<template>
  <div class="sc-number">
    <div v-if="label" class="semibold-14 mb-2">
      {{ label }}
    </div>
    <div class="d-flex align-center ga-1">
      <ScIcon
        id="line"
        :width="28"
        :height="28"
        class="sc-input-button cursor-pointer"
        :class="{'opacity-70 cursor-default':disabled}"
        @click="handleButtonClick(Direction.DECR)"
      />
      <div style="width: 32px">
        <input
          type="number"
          class="regular-14 rounded-lg sc-input"
          :step="step"
          :disabled="disabled"
          v-model="modelValue"
        />
      </div>
      <ScIcon
        id="plus"
        :width="28"
        :height="28"
         class="sc-input-button cursor-pointer"
        @click="handleButtonClick(Direction.INCR)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import ScIcon from '@/components/Common/ScIcon.vue';
enum Direction {
  INCR = +1,
  DECR = -1,
}

interface InputProps {
  modelValue: number;
  label?: string;
  step?: number;
  positiveOnly?: boolean;
  max?: number;
  disabled?: boolean
}

const props = withDefaults(defineProps<InputProps>(),
{
  step: 1,
  positiveOnly: true,
});

const modelValue = defineModel<number>({ required: true});

const handleButtonClick = (direction: number): void => {
  const tempValue = modelValue.value + (props.step * direction);
  if (props.positiveOnly && tempValue < 0) {
    modelValue.value = 0;
    return;
  }
  if (props.max && tempValue > props.max) {
    modelValue.value = props.max;
    return;
  }
  modelValue.value = tempValue;
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.sc-input-button {
  user-select: none;
}

.sc-input {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background: var( --bg-dark);
  border: 1px solid var(--form-stroke);
  text-align: center;
}

.sc-number-label{
  color: var(--text-secondary);
}
</style>
