<template>
  <div class="sc-select-block" :class="{ 'sc-select-disabled': disabled }">
    <label v-if="label" class="sc-select__label semibold-18">
      {{ label }}
    </label>
    <div
      class="sc-select sc-field"
      :class="[
        size === 'small' ? 'sc-field_small' : 'sc-field_medium',
        { 'active-border': isOpened },
        { 'error-border': !isValid && !isOpened },
      ]"
      @click="openSelect"
    >
      <div class="sc-field__inner">
        <template v-if="selectedItem">
          <span
            class="sc-select__description"
            :class="
              size === 'small'
                ? 'sc-select__text--small'
                : 'sc-select__text--medium'
            "
            v-if="selectedItem"
          >
            {{ selectedItem }}
          </span>
        </template>
        <span
          v-else
          class="sc-select__placeholder"
          :class="
            size === 'small'
              ? 'sc-select__text--small'
              : 'sc-select__text--medium'
          "
        >
          {{ placeholder }}
        </span>
      </div>

      <ScIcon
        id="expandCard"
        :width="20"
        :height="20"
        color="var(--button-loading)"
        class="select-icon"
        :class="{ 'select-icon--active': isOpened }"
      />
    </div>

    <transition name="options">
      <div
        v-if="isOpened"
        class="sc-select__options"
        v-click-outside="closeSelect"
      >
        <template v-if="options">
          <div
            v-for="(item, index) in options"
            :key="index"
            class="sc-select__options-item"
            :class="
              size === 'small'
                ? 'sc-field_small sc-select__text--small'
                : 'sc-field_medium sc-select__text--medium'
            "
            @click="onSelect(item)"
          >
            <div class="sc-select__options-item--content">
              <ScIcon
                v-if="typeof item === 'object' && item.icon && !item.img"
                :id="item.icon"
                :width="iconStyle"
                :height="iconStyle"
              />
              <img
                v-else-if="typeof item === 'object' && item.img"
                :src="`/images/${item.img}`"
                :alt="item.img"
                :width="iconStyle"
                :height="iconStyle"
              />
              <span>
                {{
                  typeof item === 'object' && labelKey ? item[labelKey] : item
                }}
              </span>
            </div>

            <ScIcon
              v-if="isSelected(item)"
              id="valid"
              :color="selectedIcon"
              :width="iconStyle"
              :height="iconStyle"
            />
          </div>
        </template>

        <template v-if="bottomText || $slots.bottomText">
          <p class="sc-select_bottom-text">
            <slot name="bottomText" />

            <ScIcon
              v-if="bottomIcon"
              :id="bottomIcon"
              :width="iconStyle"
              :height="iconStyle"
            />
            <span
              :class="[
                {
                  'sc-select__bottom--no-icon--medium':
                    !bottomIcon && size === 'medium',
                },
                {
                  'sc-select__bottom--no-icon--small':
                    !bottomIcon && size === 'small',
                },
              ]"
            >
              {{ bottomText }}
            </span>
          </p>
        </template>
      </div>
    </transition>
    <span v-if="!isValid && errorText" class="error-text">{{ errorText }}</span>
  </div>
</template>

<script setup lang="ts">
import type {
  ScSelectProps,
  SelectModel,
  SelectOption,
} from './ScSelect.types';
import { computed, ref } from 'vue';
import ScIcon from '@/components/Common/ScIcon.vue';

const props = withDefaults(defineProps<ScSelectProps>(), {
  placeholder: '',
  size: 'medium',
  isValid: true,
  selectedIcon: 'var(--primary)',
});

const isOpened = ref(false);

const modelValue = defineModel<SelectModel>('modelValue', { required: true });

const iconStyle = computed<number>(() => {
  return props.size === 'small' ? 20 : 24;
});

const selectedItem = computed<string | undefined>(() => {
  if (!props.options) return undefined;

  if (!props.valueKey && !props.labelKey) {
    const selectedOption = props.options.find(
      (option) => option === modelValue.value,
    );
    return selectedOption ? String(selectedOption) : undefined;
  }

  const selectedOption = props.options.find((option) => {
    if (typeof option === 'object' && option !== null) {
      return option[props.valueKey!] === modelValue.value;
    }
    return false;
  });

  return selectedOption && typeof selectedOption === 'object'
    ? String(selectedOption[props.labelKey!])
    : undefined;
});

const isSelected = (item: SelectOption): boolean => {
  if (!props.valueKey && !props.labelKey) {
    return modelValue.value === item;
  }
  if (props.valueKey && typeof item === 'object' && item !== null) {
    return item[props.valueKey!] === modelValue.value;
  }
  return false;
};

const openSelect = (): void => {
  if (!props.disabled) {
    isOpened.value = !isOpened.value;
  }
};

const closeSelect = (): void => {
  if (isOpened.value) {
    isOpened.value = false;
  }
};

const onSelect = (item: SelectOption): void => {
  if (props.valueKey && typeof item === 'object' && item !== null) {
    modelValue.value = item[props.valueKey] as string | number;
    closeSelect();
    return;
  }
  modelValue.value = item;
  closeSelect();
};
</script>

<style scoped>
.sc-field option {
  background-color: inherit;
}

.sc-select__placeholder {
  color: var(--button-loading);
}

.sc-select__description {
  color: var(--text-secondary);
}

.sc-field__inner {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  height: 24px;
}

.sc-select-block {
  position: relative;
  cursor: pointer;
  width: 330px;
}

.sc-select-disabled {
  cursor: default;
  opacity: 0.7;
}

.sc-select__label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-primary);
}

.sc-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: 0.2s ease-in;
  border-radius: 8px;
  border: 1px solid var(--form-stroke);
  background: var(--input-field);
}

.sc-field_medium {
  padding: 11px 16px;
}

.sc-field_small {
  padding: 3px 12px;
}

.sc-select__text--medium {
  font-size: 16px;
  line-height: 24px;
}

.sc-select__text--small {
  font-size: 14px;
  line-height: 20px;
}

.sc-select:hover {
  background: var(--tree-field);
  border-color: var(--form-stroke);
  color: var(--button-loading);
}

.select-icon {
  transition: 0.2s ease-in;
}

.select-icon--active {
  transform: rotate(180deg);
}

.active-border {
  border-color: var(--primary);
}

.error-border {
  border-color: var(--error-color);
}

.selected-text {
  outline: none;
  color: var(--color-white);
  font-family: 'Source Sans 3';
  font-weight: 400;
}

.error-text {
  position: absolute;
  top: 100%;
  font-family: 'Source Sans 3';
  color: var(--error-color);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.sc-select__options {
  z-index: 5;
  display: block;
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  background-color: var(--input-field);
  box-shadow: 0 2px 6px 2px #00000026, 0 1px 2px 0 #0000004d;
  border-radius: 8px;
  padding: 8px 0;
}

.sc-select__options-item {
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.sc-select__options-item--content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.sc-select__options-item:hover {
  background: var(--tree-field);
}

.sc-select_bottom-text {
  font-family: 'Source Sans 3';
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-secondary);
  padding: 8px 12px;
  border-top: 1px solid var(--button-loading);
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.sc-select__bottom--no-icon {
  padding-left: 32px;
}

.sc-select__bottom--no-icon--medium {
  padding-left: 36px;
}

.options-enter-active,
.options-leave-active {
  transition: opacity 0.3s ease;
}

.options-enter-from,
.options-leave-to {
  opacity: 0;
}
</style>
