<template>
  <div class="sc-input-block">
    <label
      for="sc-input-label"
      v-if="label"
      class="sc-input__label semibold-18"
    >
      {{ label }}
    </label>
    <div
      class="sc-input"
      :class="[
        { 'active-border': isActive },
        { 'error-border': !isValid && !isActive && modelValue },
        {'opacity-70 cursor-default':disabled}
      ]"
    >
      <ScIcon
        v-if="prependIcon"
        :id="prependIcon"
        :width="20"
        :height="20"
        color="var(--button-loading)"
        class="sc-input__prepend-icon"
        :class="
          size === 'medium'
            ? 'sc-input__prepend-icon--medium'
            : 'sc-input__prepend-icon--small'
        "
      />

      <input
        v-model="modelValue"
        :type="computedType"
        :placeholder="placeholder"
        :disabled="disabled"
        class="text-input"
        :class="[
          size === 'small' ? 'text-input_small' : 'text-input_medium',
          { 'text-input-prepend-icon__small': size === 'small' && prependIcon },
          {
            'text-input-prepend-icon__medium': size === 'medium' && prependIcon,
          }
        ]"
        id="sc-input-label"
        @blur="onBlur"
        @focus="onFocus"
      />

      <ScIcon
        v-if="type === 'password'"
        :id="isVisible ? 'eye-opened' : 'eye-closed'"
        :width="20"
        :height="20"
        color="var(--button-loading)"
        class="sc-input-icon"
        :class="
          size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        "
        @click="switchVisibility"
      />

      <ScIcon
        v-else-if="type === 'text' && modelValue && clearable"
        id="cancelCard"
        :width="20"
        :height="20"
        color="var(--button-loading)"
        class="sc-input-icon"
        :class="
          size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        "
        @click="clearInput"
      />

      <ScIcon
        v-else-if="type === 'text' && modelValue && isValid && !rightIcon"
        id="valid"
        :width="20"
        :height="20"
        color="var(--color-green)"
        class="sc-input-valid"
        :class="
          size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        "
      />

      <ScIcon
        v-else-if="type === 'text' && rightIcon"
        :id="rightIcon"
        :width="20"
        :height="20"
        :color="rightIconColor"
        class="sc-input-icon"
        :class="
          size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        "
      />
    </div>

    <span v-if="!isValid && errorText" class="error-text">{{ errorText }}</span>
  </div>
</template>

<script setup lang="ts">
import ScIcon from '@/components/Common/ScIcon.vue';
import { ScInputProps } from './ScInput.types';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<ScInputProps>(), {
  type: 'text',
  placeholder: '',
  size: 'medium',
  isValid: true,
  width: '200px',
  rightIconColor: 'var(--text secondary)',
});

const emit = defineEmits(['validate']);

const modelValue = defineModel('modelValue', { required: true });

const isActive = ref(false);
const isVisible = ref(false);

const computedType = computed<string>(() => {
  if (props.type === 'password' && !isVisible.value) {
    return 'password';
  }
  return 'text';
});

const onFocus = (): void => {
  isActive.value = true;
};

const onBlur = (): void => {
  isActive.value = false;
  emit('validate');
};

const switchVisibility = (): void => {
  isVisible.value = !isVisible.value;
};

const clearInput = (): void => {
  modelValue.value = '';
};
</script>

<style scoped>
.sc-input-block {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.sc-input__label {
  margin-bottom: 8px;
  color: var(--text-primary);
}

.sc-input {
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in;
  border-radius: 8px;
  border: 1px solid var(--form-stroke);
  background: var(--input-field);
}

.sc-input__prepend-icon {
  position: absolute;
}

.sc-input__prepend-icon--small {
  left: 12px;
}

.sc-input__prepend-icon--medium {
  left: 16px;
}

.sc-input:hover {
  background: var(--tree-field);
  border-color: var(--form-stroke);
  color: var(--button-loading);
}

.active-border {
  border-color: var(--primary);
}

.error-border {
  border-color: var(--error-color);
}

.text-input {
  width: 100%;
  outline: none;
  color: var(--color-white);
  padding-right: 4px;
}

.text-input_medium {
  padding: 11px 40px 11px 16px;

  font-size: 16px;
  line-height: 24px;
}

.text-input_small {
  padding: 5px 35px 5px 12px;

  font-size: 14px;
  line-height: 20px;
}

.text-input-prepend-icon__small {
  padding-left: 40px;
}

.text-input-prepend-icon__medium {
  padding-left: 44px;
}

.text-input::placeholder {
  color: var(--button-loading);
}

.error-text {
  font-family: 'Source Sans 3';
  color: var(--error-color);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 2px;
}

.input-container input {
  display: block;
  width: 100%;
}

.sc-input-icon {
  position: absolute;
  cursor: pointer;
}

.sc-input-valid {
  position: absolute;
}

.sc-input__append-icon--medium {
  right: 16px;
}

.sc-input__append-icon--small {
  right: 12px;
}

input[data-com-onepassword-filled] {
  border-radius: 8px;
}

input:-webkit-autofill {
  border-radius: 8px;
}
</style>
