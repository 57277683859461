import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "sc-input-block" }
const _hoisted_2 = {
  key: 0,
  for: "sc-input-label",
  class: "sc-input__label semibold-18"
}
const _hoisted_3 = ["type", "placeholder", "disabled"]
const _hoisted_4 = {
  key: 1,
  class: "error-text"
}

import ScIcon from '@/components/Common/ScIcon.vue';
import { ScInputProps } from './ScInput.types';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScInput',
  props: /*@__PURE__*/_mergeModels({
    type: { default: 'text' },
    modelValue: {},
    errorText: {},
    clearable: { type: Boolean },
    rightIcon: {},
    rightIconColor: { default: 'var(--text secondary)' },
    label: {},
    placeholder: { default: '' },
    isError: { type: Boolean },
    isValid: { type: Boolean, default: true },
    size: { default: 'medium' },
    prependIcon: {},
    appendIcon: {},
    disabled: { type: Boolean },
    width: { default: '200px' },
    fullWidth: { type: Boolean }
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['validate'], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const modelValue = _useModel(__props, 'modelValue');

const isActive = ref(false);
const isVisible = ref(false);

const computedType = computed<string>(() => {
  if (props.type === 'password' && !isVisible.value) {
    return 'password';
  }
  return 'text';
});

const onFocus = (): void => {
  isActive.value = true;
};

const onBlur = (): void => {
  isActive.value = false;
  emit('validate');
};

const switchVisibility = (): void => {
  isVisible.value = !isVisible.value;
};

const clearInput = (): void => {
  modelValue.value = '';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["sc-input", [
        { 'active-border': isActive.value },
        { 'error-border': !_ctx.isValid && !isActive.value && modelValue.value },
        {'opacity-70 cursor-default':_ctx.disabled}
      ]])
    }, [
      (_ctx.prependIcon)
        ? (_openBlock(), _createBlock(ScIcon, {
            key: 0,
            id: _ctx.prependIcon,
            width: 20,
            height: 20,
            color: "var(--button-loading)",
            class: _normalizeClass(["sc-input__prepend-icon", 
          _ctx.size === 'medium'
            ? 'sc-input__prepend-icon--medium'
            : 'sc-input__prepend-icon--small'
        ])
          }, null, 8, ["id", "class"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modelValue).value = $event)),
        type: computedType.value,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        class: _normalizeClass(["text-input", [
          _ctx.size === 'small' ? 'text-input_small' : 'text-input_medium',
          { 'text-input-prepend-icon__small': _ctx.size === 'small' && _ctx.prependIcon },
          {
            'text-input-prepend-icon__medium': _ctx.size === 'medium' && _ctx.prependIcon,
          }
        ]]),
        id: "sc-input-label",
        onBlur: onBlur,
        onFocus: onFocus
      }, null, 42, _hoisted_3), [
        [_vModelDynamic, modelValue.value]
      ]),
      (_ctx.type === 'password')
        ? (_openBlock(), _createBlock(ScIcon, {
            key: 1,
            id: isVisible.value ? 'eye-opened' : 'eye-closed',
            width: 20,
            height: 20,
            color: "var(--button-loading)",
            class: _normalizeClass(["sc-input-icon", 
          _ctx.size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        ]),
            onClick: switchVisibility
          }, null, 8, ["id", "class"]))
        : (_ctx.type === 'text' && modelValue.value && _ctx.clearable)
          ? (_openBlock(), _createBlock(ScIcon, {
              key: 2,
              id: "cancelCard",
              width: 20,
              height: 20,
              color: "var(--button-loading)",
              class: _normalizeClass(["sc-input-icon", 
          _ctx.size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        ]),
              onClick: clearInput
            }, null, 8, ["class"]))
          : (_ctx.type === 'text' && modelValue.value && _ctx.isValid && !_ctx.rightIcon)
            ? (_openBlock(), _createBlock(ScIcon, {
                key: 3,
                id: "valid",
                width: 20,
                height: 20,
                color: "var(--color-green)",
                class: _normalizeClass(["sc-input-valid", 
          _ctx.size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        ])
              }, null, 8, ["class"]))
            : (_ctx.type === 'text' && _ctx.rightIcon)
              ? (_openBlock(), _createBlock(ScIcon, {
                  key: 4,
                  id: _ctx.rightIcon,
                  width: 20,
                  height: 20,
                  color: _ctx.rightIconColor,
                  class: _normalizeClass(["sc-input-icon", 
          _ctx.size === 'medium'
            ? 'sc-input__append-icon--medium'
            : 'sc-input__append-icon--small'
        ])
                }, null, 8, ["id", "color", "class"]))
              : _createCommentVNode("", true)
    ], 2),
    (!_ctx.isValid && _ctx.errorText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.errorText), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})