import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ScSwitchProps } from '@/components/Common/ScSwitch/ScSwitch.types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScSwitch',
  props: /*@__PURE__*/_mergeModels({
    label: {},
    value: {},
    disabled: { type: Boolean }
  }, {
    "modelValue": {
  required: true,
},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const modelValue = _useModel(__props, 'modelValue');

return (_ctx: any,_cache: any) => {
  const _component_v_switch = _resolveComponent("v-switch")!

  return (_openBlock(), _createBlock(_component_v_switch, {
    class: _normalizeClass([{
      'custom-switch': modelValue.value,
      'custom-switch_off': !modelValue.value,
    },
    {'opacity-70 cursor-default': _ctx.disabled}
    ]),
    modelValue: modelValue.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modelValue).value = $event)),
    label: _ctx.label,
    disabled: _ctx.disabled,
    value: _ctx.value,
    "hide-details": ""
  }, null, 8, ["class", "modelValue", "label", "disabled", "value"]))
}
}

})