<template>
  <div class="checkbox-wrapper">
    <input
      v-model="modelValue"
      type="checkbox"
      :id="checkboxId"
      :value="value"
      :disabled="disabled"
      class="checkbox"
      @change="change"
    />
    <label
      :for="checkboxId"
      class="sc-checkbox"
      :class="[
        { 'sc-checkbox__error': isError },
        { 'disabled': disabled }
      ]"
    >
      <svg
        class="checkmark"
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.0609 0.422381C11.518 0.732293 11.6374 1.35411 11.3275 1.81124L6.24291 9.31124C6.07686 9.55618 5.81086 9.71499 5.51647 9.74496C5.22208 9.77493 4.92953 9.67297 4.71753 9.46652L0.802092 5.65362C0.406422 5.26831 0.398022 4.6352 0.783331 4.23953C1.16864 3.84386 1.80175 3.83546 2.19742 4.22077L5.25747 7.20068L9.67204 0.688955C9.98195 0.231819 10.6038 0.11247 11.0609 0.422381Z"
          fill="white"
        />
      </svg>
    </label>
    <label
      v-if="label || slot"
      :for="checkboxId"
      class="sc-checkbox-label regular-14 ml-2"
      :class="labelClass"
    >
      <template v-if="label">
        {{ label }}
      </template>
      <template v-else>
        <slot />
      </template>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { ScCheckboxEmits, ScCheckboxProps } from './ScCheckbox.types';

const props = withDefaults(defineProps<ScCheckboxProps>(), {
  disabled: false,
  value: 'value',
  isError: false,
  color: 'primary'
});

const slot = useSlots();

const emit = defineEmits<ScCheckboxEmits>();

const modelValue = defineModel<boolean>('modelValue', { required: true });

const checkboxId = computed<string>(() => `checkbox-${props.value}`);

const change = (): void => {
  emit('change', modelValue.value)
}

const checkboxColor = computed(() => {
  return `var(--text-${props.color})`
})
</script>

<style scoped>
.checkbox-wrapper {
  display: flex;
  align-items: start;
  position: relative;
  width: fit-content;
}

.checkbox {
  display: none;
}

.disabled{
  cursor: default;
  opacity: 0.7;
}

.sc-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid var(--button-secondary-hover);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.checkbox:checked + .sc-checkbox {
  background-color: var(--primary, #1d85dd);
  border-color: var(--primary, #1d85dd);
}

.checkmark {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox:checked + .sc-checkbox .checkmark {
  opacity: 1;
}

.sc-checkbox__error {
  border-color: var(--error-color);
}

.checkbox:disabled + .sc-checkbox {
  background-color: transparent;
  border-color: var(--button-secondary-hover);
  cursor: default;
}

.sc-checkbox-label {
  color: v-bind(checkboxColor)
}
</style>
