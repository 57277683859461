import { useModel as _useModel, useCssVars as _useCssVars, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "checkbox-wrapper" }
const _hoisted_2 = ["id", "value", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["for"]

import { computed, useSlots } from 'vue';
import { ScCheckboxEmits, ScCheckboxProps } from './ScCheckbox.types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScCheckbox',
  props: /*@__PURE__*/_mergeModels({
    label: {},
    disabled: { type: Boolean, default: false },
    value: { type: [Boolean, String, Number], default: 'value' },
    isError: { type: Boolean, default: false },
    labelClass: {},
    color: { default: 'primary' }
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["change"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "63cc719b": (checkboxColor.value)
}))

const props = __props;

const slot = useSlots();

const emit = __emit;

const modelValue = _useModel<boolean>(__props, 'modelValue');

const checkboxId = computed<string>(() => `checkbox-${props.value}`);

const change = (): void => {
  emit('change', modelValue.value)
}

const checkboxColor = computed(() => {
  return `var(--text-${props.color})`
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modelValue).value = $event)),
      type: "checkbox",
      id: checkboxId.value,
      value: _ctx.value,
      disabled: _ctx.disabled,
      class: "checkbox",
      onChange: change
    }, null, 40, _hoisted_2), [
      [_vModelCheckbox, modelValue.value]
    ]),
    _createElementVNode("label", {
      for: checkboxId.value,
      class: _normalizeClass(["sc-checkbox", [
        { 'sc-checkbox__error': _ctx.isError },
        { 'disabled': _ctx.disabled }
      ]])
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("svg", {
        class: "checkmark",
        width: "12",
        height: "10",
        viewBox: "0 0 12 10",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.0609 0.422381C11.518 0.732293 11.6374 1.35411 11.3275 1.81124L6.24291 9.31124C6.07686 9.55618 5.81086 9.71499 5.51647 9.74496C5.22208 9.77493 4.92953 9.67297 4.71753 9.46652L0.802092 5.65362C0.406422 5.26831 0.398022 4.6352 0.783331 4.23953C1.16864 3.84386 1.80175 3.83546 2.19742 4.22077L5.25747 7.20068L9.67204 0.688955C9.98195 0.231819 10.6038 0.11247 11.0609 0.422381Z",
          fill: "white"
        })
      ], -1)
    ]), 10, _hoisted_3),
    (_ctx.label || _unref(slot))
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: checkboxId.value,
          class: _normalizeClass(["sc-checkbox-label regular-14 ml-2", _ctx.labelClass])
        }, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ], 64))
            : _renderSlot(_ctx.$slots, "default", { key: 1 })
        ], 10, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

})