import type {
  AxiosInstance,
  AxiosResponse,
  GenericAbortSignal,
  RawAxiosRequestHeaders,
} from 'axios';
import axios from 'axios';
import router from '@/router/router';
import orch from './requestOrchestrator';
import store from '@/store';
import { RouteNames } from '@/router/routeNames';
import { Methods } from '@/store/enums';

export interface RequestData<T> extends Record<string, unknown> {
  mtd: Methods;
  data?: T;
}

const isProdMode = process.env.VUE_APP_PROD_MODE;
const AUTH_URL = process.env.VUE_APP_AUTH_API;
const API_URL = process.env.VUE_APP_DEV_API;

class AxiosWrapper {

  private BASE_URL: string;
  private url: string;
  private api: AxiosInstance;

  constructor() {
    this.BASE_URL = isProdMode ? AUTH_URL : API_URL;
    this.url = `${this.BASE_URL}/api/`;

    this.api = axios.create();
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        store.commit('data/SET_DATA_BY_KEY', { key: 'autoLoading', value: false });
        if (error?.config?.url?.includes('api.chess.com')) {
          if (error?.response?.status === 400) {
            return { errorMessage: error?.response?.data?.message };
          }
          if (error?.code === 'ERR_NETWORK') {
            return { errorMessage: 'User not found' };
          }
        }

        if (error?.config?.url?.includes('lichess.org')) {
          if (error?.response?.status === 404) {
            return { errorMessage: 'User not found' };
          }
        }
        const status = error.response?.status;
        if (status === 425 || status === 403) {
          return error.response;
        }
        if (status === 415) {
          router.push({ name: RouteNames.CARDS });
          return;
        }
        if (status === 401) {
          orch.killAll();
          localStorage.removeItem('token');
          store.commit("SET_DATA_BY_KEY", { key: 'isAuthenticated', value: false });
          router.push({ name: RouteNames.LOGIN });
          return error.response;
        }
        console.log(error)
      }
    )
  }

  getIp(): string {
    return localStorage.getItem('ip-from') ?? '';
  }

  getToken(): string {
    return localStorage.getItem('token') ?? '';
  }

  getHeaders(): RawAxiosRequestHeaders {
    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'x-forwarded-for': this.getIp(),
    };
    const token = this.getToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return headers;
  }

  async request<R, T>(data: RequestData<R>, signal?: GenericAbortSignal): Promise<AxiosResponse<T, any>['data']> {
    const method = 'POST';
    const result = await this.api({
      headers: this.getHeaders(),
      url: this.url,
      method,
      data,
      signal,
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log(data.mtd, result?.data)
    }
    return result?.data;
  }

  async fetchExternal(url: string, config?: {
    headers?: RawAxiosRequestHeaders;
  }): Promise<AxiosResponse<any, any> | { errorMessage: string }> {
    const method = 'GET';
    const result = await this.api({
      method,
      url,
      headers: config?.headers,
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log(result);
    }
    return result;
  }
};

export default new AxiosWrapper;
